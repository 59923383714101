/**
 * Created by matejk on 2.10.2014.
 */

app.directive("sidenavRight", ["$window", "Session", "$state", 'mobile','hotkeys',
    "$rootScope", '$mdDialog',
    function ($window, Session, $state, mobile,hotkeys,
            $rootScope, $mdDialog) {
        return {
            restrict: "A",
            replace: true,
            templateUrl: 'app/directives/sidenav-right/sidenav-right.html',
            scope: {
                main: '=sidenavRight'
            },
            link: function (scope, element, attrs) {
                //console.log(scope.main);

                /**
                 * WEB
                 */
                scope.seeWebResults = function () {
                    var mustWords = [];
                    var shouldWords = [];
                    var notWords = [];
                    for (var i = 0; i < scope.main.keywords.data.length; i++) {
                        var word = scope.main.keywords.data[i];
                        if (word.type.short === "m") {
                            mustWords.push(word.word);
                        } else if (word.type.short === "s") {
                            shouldWords.push(word.word);
                        } else if (word.type.short === "n") {
                            notWords.push(word.word);
                        }
                    }
                    var webQuery = "";
                    for (var i = 0; i < mustWords.length; i++) {
                        if (i === 0) {
                            webQuery += "\"";
                        }
                        if (i > 0) {
                            webQuery += " ";
                        }
                        webQuery += mustWords[i];
                        if (i === mustWords.length - 1) {
                            webQuery += "\"";
                        }
                    }
                    if (mustWords.length > 0) {
                        webQuery += " ";
                    }
                    for (var i = 0; i < shouldWords.length; i++) {
                        if (i > 0) {
                            webQuery += " ";
                        }
                        webQuery += shouldWords[i];
                    }
                    if (shouldWords.length > 0) {
                        webQuery += " ";
                    }
                    for (var i = 0; i < notWords.length; i++) {
                        if (i > 0) {
                            webQuery += " ";
                        }
                        webQuery += "-";
                        webQuery += notWords[i];
                    }

                    var win = window.open('http://google.com/search?q=' + webQuery);
                    win.focus();
                };

                /**
                 * VIEWS
                 */
                scope.changeLayout = function (layout, $event) {
                    if (scope.main.layouts.selected !== layout) {
                        scope.main.layouts.selected = layout;
                        scope.main.redirect();
                    }
                };
                
                scope.helpClick = hotkeys.toggleCheatSheet;
                
                
                scope.editLayout = function(ev,layoutIdx){
                    var current = false;
                    var m = scope.main.layouts.mobile;
                    if(layoutIdx!== null &&
                            scope.main.layouts.all[layoutIdx] === scope.main.layouts.selected){
                        current = true;
                    }
                    $mdDialog.show({
                        controller: 'layoutCtrl',
                        templateUrl: 'app/module/dialogs/layout/layout.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        locals: {
                            layoutIdx: layoutIdx
                        }
                    }).then(function (isRemove) {
                        if(current){
                            if(m !== mobile()){
                                scope.main.redirect();
                            }else if(isRemove){
                                scope.main.layouts.selected = scope.main.layouts.all[0];
                                scope.main.redirect();
                            }else{
                                scope.main.layouts.selected = scope.main.layouts.all[layoutIdx];
                                scope.main.redirect();
                            }
                        }
                    });
                };

                hotkeys.bindTo(scope)
                    .add({
                        combo: 'ctrl+k',
                        description: 'Previous layout',
                        callback: function () {
                            var l = scope.main.layouts;
                            var idx = _.findIndex(l.all,{name: l.selected.name});
                            if(idx !== -1){
                                l.selected = l.all[(l.all.length + idx-1)%l.all.length];
                                scope.main.redirect();
                            }
                        }
                    })
                    .add({
                        combo: 'ctrl+l',
                        description: 'Next layout',
                        callback: function () {
                            var l = scope.main.layouts;
                            var idx = _.findIndex(l.all,{name: l.selected.name});
                            if(idx !== -1){
                                l.selected = l.all[(idx+1)%l.all.length];
                                scope.main.redirect();
                            }
                        }
                    });
            }
        }
    }]);