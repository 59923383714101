/* global app, angular */

/**
 * Created by matejk on 2.10.2014.
 */

app.directive("infinitelist", ['$timeout', '$state', '$mdDialog','ExportService','DashboardService',
    '$window', 'config', 'ThriftHelper', 'HitModel','defaultsettings', '$rootScope','Session',
    function ($timeout, $state, $mdDialog, ExportService, DashboardService,
              $window, config, ThriftHelper, HitModel, defaultsettings, $rootScope,Session) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/directives/infinite-list/infinite-list.html',
            scope: {
                options: '='
            },
            link: function (scope, element, attrs) {
                scope.service = DashboardService;
                scope.id = "infinite-list-" + UUID.generate();
                if(scope.options===undefined){
                    scope.options = {};
                }
                scope.settings = config.infiniteList;
                var scroller = element.find(".md-virtual-repeat-scroller");

                var getCall = function () {
                    return config.calls.WS_INFINITE_LIST + "-" + scope.id;
                };

                scope.openExport = function(ev){
                    var settings = {
                        selected: getSelected(),
                        dto: angular.copy(toDto(scope.infiniteItems.page*scope.infiniteItems.PAGE_SIZE, scope.infiniteItems.BATCH_SIZE)),
                        description: {
                            type:  scope.type.selected.textplural,
                            sortBy: scope.sortBy.selected.text,
                            case: Session.get().currentCase.name,
                            keywords: scope.service.keywords.data,
                        }
                    };
                    ExportService.exportDialog("infinitlist", settings, ev);
                };

                var getSelected = function() {
                    var selectedIds = [];
                    // scope.infiniteItems.loadedBatches.forEach(function(batch) {
                    for (var batch in scope.infiniteItems.loadedBatches) {
                        if (batch) scope.infiniteItems.loadedBatches[batch].forEach(function(item) {
                            if (item.selected === true) selectedIds.push(item.id);
                        });
                    }
                    return selectedIds;
                };

                scope.$on(getCall(), function (event, args) {
                    var data = args.data;
                    if(args.reqId < scope.infiniteItems.minReqId){
                        return;
                    }
                    if(data instanceof ErrorMsg){
                        console.log("ERROR in infinitelist");
                        console.log(data);
                        scope.infiniteItems.busy = false;
                        scope.$apply();
                        return;
                    }
                    var batchNumber = Math.floor(data.start / scope.infiniteItems.BATCH_SIZE);
                    console.log("recived:"+batchNumber);
                    scope.infiniteItems.loadedBatches[batchNumber] = [];
                    for (var i = 0; i < data.documents2.length; i++) {
                        scope.infiniteItems.loadedBatches[batchNumber].push(new HitModel(data.documents2[i]));
                    }

                    if(data.start === 0){
                        scope.infiniteItems.total = data.total;
                        var pn = Math.ceil( Math.min(data.total,scope.infiniteItems.MAX_SIZE)/scope.infiniteItems.PAGE_SIZE);
                        scope.infiniteItems.pages = [];
                        for(var i = 0; i<pn; i++){
                            scope.infiniteItems.pages.push((i*scope.infiniteItems.PAGE_SIZE +1) + " - " +
                            Math.min((i+1)*scope.infiniteItems.PAGE_SIZE, data.total, scope.infiniteItems.MAX_SIZE));
                        }
                        scope.infiniteItems.busy = false;
                    }
                    scope.$apply();
                });

                scope.infiniteItems = {
                    topIndex: 0,
                    page: 0,
                    pages: [],
                    busy: true,
                    total: 0,
                    loadedBatches: {},
                    PAGE_SIZE: 1000,
                    BATCH_SIZE: 50,
                    MAX_SIZE: 10000,
                    minReqId: 0,
                    isLoading: function(){
                        if(this.busy){
                            return true;
                        }
                        var batchNumber = this.getBatchNumber(this.topIndex);
                        var batch = this.loadedBatches[batchNumber];
                        if(batch){
                            return false;
                        }
                        return true;
                    },
                    getBatchNumber: function(index){
                        return Math.floor((this.page*this.PAGE_SIZE + index) / this.BATCH_SIZE);
                    },
                    getRealIndex: function(index){
                        return index + this.page*this.PAGE_SIZE;
                    },
                    getItemAtIndex: function (index) {
                        var batchNumber = this.getBatchNumber(index);
                        var batch = this.loadedBatches[batchNumber];

                        if (batch) {
                            return batch[index % this.BATCH_SIZE];
                        } else if (batch !== null) {
                            this.fetchBatch_(batchNumber);
                        }
                    },
                    getLength: function () {
                        if (this.busy === true)
                            return 1;

                        return this.getPageSize();
                    },
                    getPageSize: function () {
                        return Math.max(0,Math.min(this.PAGE_SIZE, Math.min(this.total, this.MAX_SIZE) -this.page*this.PAGE_SIZE));
                    },
                    prevPage: function () {
                        if(this.page > 0) {
                            this.page--;
                            this.topIndex = 0;
                        }
                    },
                    nextPage: function () {
                        if(this.total>(this.page+1)*this.PAGE_SIZE){
                            this.page++;
                            this.topIndex = 0;
                        }
                    },
                    pageText: function () {
                        if(angular.isUndefined(this.pages) || this.pages.length === 0){
                            return "";
                        }
                        return this.pages[this.page];
                    },
                    fetchBatch_: _.debounce(function (batchNumber) {
                        console.log("send:"+batchNumber);
                        this.loadedBatches[batchNumber] = null;
                        var dto = toDto(batchNumber*this.BATCH_SIZE,this.BATCH_SIZE);
                        //console.log(dto);
                        ThriftHelper.sendRequest(new SearchEsReq(dto), MsgType.SEARCH_ES_REQ, getCall());
                    }, 300)
                };

                var toDto = function(start,size){
                    var dto = scope.service.keywords.toDto(start, size, {
                        type: scope.type.selected.type,
                        sortBy: scope.sortBy.selected.id
                    });

                    if (scope.locationOnly) {
                        var range = new GeohashRange({
                            tl: Geohash.encode(90, -180),
                            br: Geohash.encode(-90, 180)
                        });

                        dto.geohashRange = range;
                    }

                    return dto;
                };
                
                scope.type =
                {   selected: defaultsettings.articleTypes[1],
                    all: _.filter(defaultsettings.articleTypes, function (item) {
                        return item.id >= 0 && item.id <= 9;
                    })
                };
                if (scope.options.docType) {
                    var s = _.findWhere(scope.type.all, {id: scope.options.docType});
                    if (s) {
                        scope.type.selected = s;
                    }
                }
                scope.sortBy =
                        {selected: defaultsettings.sortBy[1],
                            all: defaultsettings.sortBy};
                if (scope.options.sortBy) {
                    var s = _.findWhere(scope.sortBy.all, {id: scope.options.sortBy});
                    if (s) {
                        scope.sortBy.selected = s;
                    }
                }
                scope.locationOnly = false;
                if (scope.options.locationOnly) {
                    scope.locationOnly = scope.options.locationOnly;
                }
                var fixForWiki = function () {
                    var u = false;
                    if (scope.type.selected.type === 'wiki') {
                        if (scope.sortBy.selected.id !== 'relevance') {
                            scope.sortBy.selected = _.findWhere(scope.sortBy.all, {id: 'relevance'});
                            u = true;
                        }
                    }
                };

                //start
                var init_main = function () {
                    fixForWiki();
                    scroller.scrollTop(0);
                    if (scope.infiniteItems) {
                        scope.infiniteItems.topIndex = 0;
                        scope.infiniteItems.page = 0;
                        scope.infiniteItems.minReqId = $rootScope.reqId;
                    }
                    $timeout(function () {
                        scope.infiniteItems.minReqId = $rootScope.reqId;
                        scope.infiniteItems.loadedBatches = {};
                        scope.infiniteItems.busy = true;
                        scope.infiniteItems.topIndex = 0;
                        scope.infiniteItems.total = 0;
                        scope.infiniteItems.page = 0;
                    });
                };
                var init = _.debounce(init_main, 100); 
                init();
                

                scope.$watch("service.keywords", function (nval, oval) {
                    if (nval && nval.data && oval && oval.data) {
                        if (oval.data.length !== nval.data.length) {
                            init();
                        } else {
                            for (var i = 0; i < nval.data.length; i++) {
                                if (oval.data[i].toString() !== nval.data[i].toString()) {
                                    init();
                                    return;
                                }
                            }
                        }
                    }
                }, true);

                scope.$watch("[type.selected,sortBy.selected, locationOnly]", function (nval, oval) {
                    for(var i in nval){
                        if (nval[i] !== oval[i]) {
                            init();
                        }
                    }
                }, true);

                element.find(".headroom").headroom({
                    offset: 0,
                    tolerance: 1,
                    scroller: scroller[0]
                });

                scroller.on('wheel', function (e) {
                    if (scope.infiniteItems &&
                            scope.infiniteItems.numLoaded_ < scope.infiniteItems.total) {
                        var delta = e.originalEvent.wheelDelta;
                        if (delta < 0) {
                            var scrollTop = scroller.scrollTop();
                            var scrollHeight = this.scrollHeight;
                            var height = scroller.height();
                            var bottom = scrollHeight - height - 300;
                            if (scrollTop + (-delta) > bottom) {
                                //console.log("prevent scroll");
                                e.preventDefault();
                                return false;
                            }
                        }else{
                        }

                    }
                });

                scope.$on("$destroy", function () {
                    element.off("resize");
                    scroller.off('wheel');
                    element.find(".headroom").headroom("destroy");
                    element.remove();
                });

                scope.onDocumentTitleClick = function (item) {
                    var eventId = 'onItemSelected_' + scope.options.detailsId;
                    $rootScope.$broadcast(eventId, item);
                };

            }
        };
    }]);
