/* global app */

/**
 * Created by matejk on 17.10.2014.
 * search2Ctr site has directives: headermenu, wordcloud, foot
 * 
 */

app.controller('newsSearchCtrl', ['$scope', '$state', '$stateParams', 'config', 'Session',
    'defaultsettings', '$timeout', 'QueryModel','HeaderOptions',
    function ($scope, $state, $stateParams, config, Session,
            defaultsettings, $timeout, QueryModel,HeaderOptions) {

        $scope.keywords = new QueryModel($stateParams.q,"news");
        


        $scope.redirect = function () {
            var params = {
                q: undefined
            };
            if (!$scope.keywords.isEmpty()) {
                params.q = $scope.keywords.toString();
            }
            $state.go("app.news", params, {
                reload: false,
                inherit: true,
                notify: false
            });
        };
        
        $scope.mainToolsOptions = {
            keywords: $scope.keywords,
            redirect: $scope.redirect
        };

        $scope.listControl = {
            keywords: $scope.keywords,
            options: {
                type: _.findWhere(defaultsettings.articleTypes, {id: 10}).type,
                sortBy: 'timeline'
            }
        };

        // --------------
        // --- SET HEADER OPTIONS ---
        // --------------
        var org = Session.get().organization;
        if(Session.isAnonymousOrTrial() || org.maxNoOfCustomNewsSources===null || org.maxNoOfCustomNewsSources <= 0){
            HeaderOptions.set([]);
        }else {
            HeaderOptions.set([
                HeaderOptions.defaultOptions.selectCustomSource($scope.redirect,$scope.keywords)
            ]);
        }

    }
]);
