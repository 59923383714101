
app.controller('adminOrganizationSourcesCtrl', ['$scope', 'adminService', '$q', 'config', '$mdDialog','Session',
    'ThriftHelper',
    function ($scope, adminService, $q, config, $mdDialog, Session,
              ThriftHelper) {

        $scope.adminService = adminService;
        $scope.sources = Session.get().customSources;
        $scope.sourceType = "News";
        $scope.sourcesStats = {};


        var getStats = function(){
            $scope.sources.forEach(function (item) {
                item.stat = item.id;
            });
            var dto = {
                type: "news",
                ids: _.pluck($scope.sources, 'id')
            };
            ThriftHelper.sendRequest(new GetSourceStatReq(dto), MsgType.GET_SOURCE_STAT_REQ, config.calls.WS_ADMIN_SOURCE_STAT);
        };
        getStats();

        $scope.$on(config.calls.WS_ADMIN_SOURCE_STAT, function (event, args) {
            if(args.data.stats.length > 0){
                arrangeStatsData(args.data.stats);
                $scope.$apply();
                console.log()
            }
        });
        function arrangeStatsData(stats) {
            $scope.sourcesStats = {};
            stats.forEach(function (stat) {
                var obj = {};
                obj.row = _.find($scope.sources, function(item){
                    return item.id === stat.id;
                });
                obj.stats = stat.days;
                $scope.sourcesStats[stat.id] = obj;
            });
        }


        $scope.editSource = function (row, ev) {
            if(row){
                row = _.find($scope.sources, function(item){
                    return item.id === row;
                })
            }
            $mdDialog.show({
                controller: 'editSourceCtrl',
                templateUrl: 'app/module/admin/sources/editSource.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {
                    currentSource: angular.copy(row),
                    addToOrganization: true,
                    fromAdmin: false
                }
            })
            .then(function (answer) {
                $scope.sources = Session.get().customSources;
            });
        };

        /*$scope.deleteSource = function (row, ev) {
            if(row){
                row = _.find($scope.sources, function(item){
                    return item.id === row;
                })
            }
            var confirm = $mdDialog.confirm()
                .title('Would you like to delete news source "' + row.name + '"?')
                .ariaLabel('Delete news source')
                .targetEvent(ev)
                .ok('Delete')
                .clickOutsideToClose(true)
                .cancel('Cancel');
            $mdDialog.show(confirm).then(function () {
                var user = new DeleteSource({id: row.id});
                ThriftHelper.sendRequest(user, MsgType.DELETE_SOURCES, config.calls.WS_DELETE_USER);
            });

            $mdDialog.show({
                controller: 'editSourceCtrl',
                templateUrl: 'app/module/admin/sources/editSource.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {
                    currentSource: angular.copy(row),
                    addToOrganization: true,
                    fromAdmin: false
                }
            })
                .then(function (answer) {
                    $scope.sources = Session.get().customSources;
                });
        };*/

    }]);
