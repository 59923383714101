
app.controller('editTwitterChannelsCtrl', ['$scope', 'addToOrganization', 'currentTwitterChannel', 'Toast','$mdDialog',
    'config', 'ThriftHelper','$rootScope','Session',
    function ($scope, addToOrganization, currentTwitterChannel, Toast,$mdDialog,
              config, ThriftHelper,$rootScope,Session) {
        
        $scope.form = {};
        $scope.isAdd = currentTwitterChannel === null;
        $scope.title = $scope.isAdd ? "Add Twitter channel" : "Edit Twitter channel";

        $scope.canActivate = true;
        if(!$scope.isAdd && !currentTwitterChannel.active){
            var numActive = _.where($rootScope.user.twitterChannels,{active:true}).length;
            if(numActive >= $rootScope.user.organization.maxNoOfTwitterChannels){
                $scope.canActivate = false;
            }
        }

        
        if($scope.isAdd){
            $scope.channel = {
                tags:[],
                active: true
            };
        }else{
            $scope.channel = angular.copy(currentTwitterChannel);
            if($scope.channel.tags===null){
                $scope.channel.tags=[];
            }
            if($scope.channel.active===null){
                $scope.channel.active=true;
            }
        }


        
        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        
        $scope.save = function () {
            if($scope.form.channel.$valid && $scope.channel.tags.length > 0){
                ThriftHelper.sendRequest(new UpdateTwitterChannelReq({channel: new TwitterChannel($scope.channel)}),
                MsgType.UPDATE_TWITTER_CHANNEL_REQ, config.calls.WS_EDIT_TWITTER_CHANNEL);
            }
        };

        $scope.remove = function () {
            var tc = currentTwitterChannel;
            tc.active = false;
            console.log("jej",tc);
            ThriftHelper.sendRequest(new UpdateTwitterChannelReq({channel: new TwitterChannel(tc)}),
                    MsgType.UPDATE_TWITTER_CHANNEL_REQ, config.calls.WS_REMOVE_TWITTER_CHANNEL_1);
        };

        var tempChannel = null;
        var tempOrganization = null;
        $scope.$on(config.calls.WS_EDIT_TWITTER_CHANNEL, function (event, args) {
            if(args.err===false){
                tempChannel = args.data.channel;
                if($scope.isAdd){
                    if(addToOrganization){
                        var o = angular.copy($rootScope.user.organization);
                        if(!angular.isArray(o.twitterChannels)){
                            o.twitterChannels = [];
                        }
                        o.twitterChannels.push(tempChannel.id);
                        ThriftHelper.sendRequest(new UpdateOrganization({organization: o}),
                            MsgType.UPDATE_ORGANIZATION, config.calls.WS_EDIT_ORG_FROM_CHANNEL);
                    }else{
                        Toast.success("Twitter channel was successfully added.");
                        $scope.$apply();
                        $mdDialog.hide(new TwitterChannel($scope.channel));
                    }
                }else{
                    var i = _.findIndex($rootScope.user.twitterChannels,function(i){ return i.id===tempChannel.id;});
                    if(i>-1){
                        $rootScope.user.twitterChannels[i] = tempChannel;
                    }

                    Toast.success("Twitter channel was successfully updated.");
                    $scope.$apply();
                    $mdDialog.hide(new TwitterChannel($scope.channel));
                }
            }else{
                Toast.error(args.err);
            }
        });

        $scope.$on(config.calls.WS_EDIT_ORG_FROM_CHANNEL, function (event, args) {
            if(args.err===false) {
                if(!angular.isArray($rootScope.user.organization.twitterChannels)){
                    $rootScope.user.organization.twitterChannels = [];
                }
                $rootScope.user.organization.twitterChannels.push(tempChannel.id);
                if(!angular.isArray($rootScope.user.twitterChannels)){
                    $rootScope.user.twitterChannels = [];
                }
                $rootScope.user.twitterChannels.push(tempChannel);
                if($rootScope.user.twitterChannels.length === 1){
                    $rootScope.user.currentTwitterChannel = tempChannel;
                }
                Toast.success("Twitter channel was successfully added.");
                $scope.$apply();
                $mdDialog.hide(new TwitterChannel(tempChannel));
            }else{
                Toast.error(args.err);
            }
        });

        $scope.$on(config.calls.WS_REMOVE_TWITTER_CHANNEL_1, function (event, args) {
            if (args.data instanceof UpdateTwitterChannelResp) {
                tempChannel = args.data.channel;
                tempOrganization = $rootScope.user.organization;
                _.remove(tempOrganization.twitterChannels, function(id) { return id === tempChannel.id;});
                ThriftHelper.sendRequest(new UpdateOrganization({organization: tempOrganization}),
                    MsgType.UPDATE_ORGANIZATION, config.calls.WS_REMOVE_TWITTER_CHANNEL_2);
                $scope.$apply();
            }
        });

        $scope.$on(config.calls.WS_REMOVE_TWITTER_CHANNEL_2, function (event, args) {
            if (args.data === null) {
                Toast.success("Twitter channel was successfully deleted.");
                _.remove($rootScope.user.twitterChannels, function(item) { return item.id === tempChannel.id;});
                var c = $rootScope.user.cases;
                Session.set($rootScope.user.simpleUser, tempOrganization,c,$rootScope.user.twitterChannels,$rootScope.user.customSources,
                    $rootScope.user.uiTemplate);
                console.log($rootScope.user);
                $mdDialog.hide("remove");
                $scope.$apply();
            }
        });
        
        
    }]);
