/**
 * Created by matejk on 2.10.2014.
 */

app.directive("maintoolsmenu", ["$window", "Session", "KeywordModel", 'ThriftHelper','DashboardService',
    "config", '$timeout', '$q', 'defaultsettings', 'hotkeys', '$mdSidenav','Toast', '$mdDialog', '$rootScope',
    function ($window, Session, KeywordModel, ThriftHelper,DashboardService,
              config, $timeout, $q, defaultsettings, hotkeys, $mdSidenav,Toast, $mdDialog, $rootScope) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/directives/main-tools-menu/main-tools-menu.html',
            link: function ($scope, $elm, $attrs) {
                $scope.service = DashboardService;
                $scope.settings = config.mainToolsMenu;
                $scope.toggleSidenav = function () {
                    $mdSidenav('right').toggle();
                };
                $scope.sources = KeywordModel.getSources($scope.service.keywords.type);
                $scope.shortSources = _.pluck($scope.sources,"short");

                /**
                 * SEARCH
                 */
                $scope.search = {
                    transformChip: function ($chip) {
                        var type = "m";

                        if (typeof $chip === 'string') {
                            return new KeywordModel($chip + "_" + type);
                        }else if($chip.help === true){
                            return new KeywordModel("h:"+$chip.short);
                        } else {
                            var source = $chip.source.short;
                            var name = $chip.name;
                            if(source === "s"){
                                name = $chip.id+"|"+name;
                            }
                            return new KeywordModel(source + ":" + name + "_" + type);
                        }
                    },
                    onAdd: function ($chip) {
                        if($chip.source.short === 'h'){
                            this.searchText = $chip.word+":";
                        }
                        $scope.service.keywords.setValid();
                        $scope.service.redirect();
                        scrollDown();
                        return false;
                    },
                    onRemove: function ($chip) {
                        $scope.service.keywords.setValid();
                        $scope.service.redirect();
                        return false;
                    },
                    onSelect: function ($chip) {
                        $scope.service.keywords.nextType($chip);
                        $scope.service.redirect();
                        console.log(JSON.stringify($chip));
                        return false;
                    },
                    selectedItem: undefined,
                    searchText: undefined,
                    deferred: undefined,
                    getSearch: function (word) {
                        if(word === ""){
                            return _.each($scope.sources,function(item){ item.help = true; });
                        } else if(word.startsWith("y:") || word.startsWith("d:") || word.startsWith("t:")){
                            if(word.trim().length>2 || !_.contains($scope.shortSources,word[0])){
                                return [];
                            }
                            switch(word){
                                case "y:": return defaultsettings.rangeYears;
                                case "d:": return defaultsettings.rangeDates;
                                case "t:": return defaultsettings.rangeTimes;
                                default: return [];
                            }
                        } else {
                            var model = new KeywordModel(word);
                            if(!_.contains($scope.shortSources,model.source.short)){
                                return [];
                            } else {
                                this.deferred = $q.defer();

                                if(model.source.short === "s"){
                                    getSources(word.substring(2).toLowerCase());
                                } else {
                                    var dto = {
                                        keyword: angular.isDefined(model.word) ? model.word.toLowerCase() : undefined,
                                        top: $scope.settings.searchSize,
                                        organization: Session.get().currentCase.name,
                                        type: source2type(model.source.short, model.soureProvidedInWord)
                                    };
                                    //console.log(dto);
                                    ThriftHelper.sendRequest(new SearchVocabulary2Req(dto),
                                        MsgType.SEARCH_VOCABULARY2_REQ, config.calls.WS_WORD_SEARCH);
                                }
                                return this.deferred.promise;
                            }

                        }
                        return [];
                    }

                };
                $scope.$on(config.calls.WS_WORD_SEARCH, function (event, args) {
                    if(args.data instanceof ErrorMsg) {
                        $scope.search.deferred.resolve([]);
                        return;
                    }

                    var results = args.data.keywords;
                    _.each(results, function (element) {
                        _.extend(element,{
                            source: new KeywordModel(type2source(element.type) + ':' + element.name).source
                        });
                    });

                    $scope.search.deferred.resolve(results);
                });






                var mdChipsElm = $elm.find('md-chips');
                var mdChipsCtrl = mdChipsElm.controller('mdChips');
                var mdChipsInput = [];

                $scope.help = false;
                $scope.helpClick = function ($event) {
                    if(mdChipsInput.length === 0){
                        mdChipsInput = mdChipsElm.find('input');
                        mdChipsInput.blur(function (e) {
                            $scope.help = false;
                        });
                    }
                    $scope.help = true;
                    $scope.search.searchText = "";
                    $timeout(function () {
                        mdChipsInput.focus();
                    });
                };

                $scope.getMinLength = function(){
                    return $scope.help? 0:1;
                };

                //fix for not focusing full line
                var mdChipsWrapElm = mdChipsElm.find('md-chips-wrap');
                mdChipsWrapElm.click(function (e) {
                    if (e.target !== this)
                        return;

                    var mdChipsInput = mdChipsElm.find('input');
                    $timeout(function () {
                        mdChipsInput.focus();
                    });
                });

                //hotkeys
                hotkeys.bindTo($scope)
                    .add({
                        combo: 'ctrl+s',
                        description: 'Search',
                        callback: function () {
                            mdChipsElm.focus();
                        }
                    })
                    .add({
                        combo: 'up',
                        description: 'Next type of selected keyword',
                        callback: function () {
                            if (mdChipsCtrl.selectedChip !== -1) {
                                $scope.service.keywords.nextType($scope.service.keywords.data[mdChipsCtrl.selectedChip]);
                            }
                        }
                    })
                    .add({
                        combo: 'down',
                        description: 'Previous type of selected keyword',
                        callback: function () {
                            if (mdChipsCtrl.selectedChip !== -1) {
                                $scope.service.keywords.nextType($scope.service.keywords.data[mdChipsCtrl.selectedChip], true);
                            }
                        }
                    });


                mdChipsElm.on('focus', function () {
                    scrollDown();
                });

                var scrollDown = function(){
                    $timeout(function(){
                        //$elm.scrollTop($elm[0].scrollHeight);
                    });
                };
                scrollDown();

                /**
                 * Converts KeywordModel source into Thrift's KeywordType.
                 *
                 * @param source The source
                 * @param soureProvidedInWord The flag that indicates if sourece was provided in keyword
                 * @returns {number} the Keyword Type
                 */
                function source2type(source, soureProvidedInWord) {
                    if (!soureProvidedInWord) {
                        // if source was not provided in word meaning we search for ALL
                        return KeywordType.ALL;
                    }
                    switch (source) {
                        case 'w':
                            return KeywordType.WORD;
                        case 'a':
                            return KeywordType.AUTHOR;
                        case 'o':
                            return KeywordType.ORG;
                        case 's':
                            return KeywordType.ASSIGNEE;
                        case 'f':
                            return KeywordType.FOS;
                        default:
                            return KeywordType.ALL;
                    }
                }

                /**
                 * Converts Keyword Type into KeywordModel
                 * @param type The type
                 * @returns {*}
                 */
                function type2source(type) {
                    switch (type) {
                        case KeywordType.WORD:
                            return 'w';
                        case KeywordType.AUTHOR:
                            return 'a';
                        case KeywordType.ORG:
                            return 'o';
                        case KeywordType.FOS:
                            return 'f';
                        default:
                            return 'w';
                    }
                }

                /**
                 * Sources
                 */
                var getSources = function (filter, pageSize, type) {
                    var query = '';
                    if(filter === ""){
                        var cs = angular.copy(Session.get().customSources);
                        if(angular.isArray(cs)){
                            _.each(cs, function (element) {
                                _.extend(element,{
                                    source: new KeywordModel('s:' + element.name).source,
                                    word: element.name
                                });
                            });
                            $scope.search.deferred.resolve(cs);
                        }else{
                            $scope.search.deferred.resolve([]);
                        }
                        return;
                    }else{
                        query = '{"query":{"simple_query_string":{ "fields":["name"], "query":"' + filter + '"}}}';
                    }
                    if(type===undefined) type ="news";
                    if(pageSize===undefined) pageSize = 10;
                    var dto = {
                        type: type,
                        start: 0,
                        size: pageSize,
                        query: query
                    };
                    ThriftHelper.sendRequest(new GetSourceReq(dto), MsgType.GET_SOURCE_REQ, config.calls.WS_MTM_GET_SOURCES);
                };

                $scope.$on(config.calls.WS_MTM_GET_SOURCES, function (event, args) {
                    if(args.data instanceof ErrorMsg) {
                        $scope.search.deferred.resolve([]);
                        return;
                    }

                    var results = args.data.sources;
                    _.each(results, function (element) {
                        _.extend(element,{
                            source: new KeywordModel('s:' + element.name).source,
                            word: element.name
                        });
                    });

                    $scope.search.deferred.resolve(results);
                });


                if($rootScope.showPopoverInfo === true){
                    $scope.showPopoverInfo = true;
                }

                $scope.closePopoverInfo = function($event){
                    $rootScope.showPopoverInfo = false;
                    $scope.showPopoverInfo = false;
                };


            }
        };
    }]);
