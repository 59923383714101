
app.controller('adminOrganizationUiTemplatesEditCtrl', ['$scope', 'Session', 'currentUiTemplate',
    'Toast', '$mdDialog', 'ThriftHelper', 'config','fromOrganization',
    function ($scope, Session, currentUiTemplate,
    Toast, $mdDialog, ThriftHelper, config,fromOrganization) {

        $scope.form = {};

        $scope.isAdd = currentUiTemplate === null;
        $scope.title = $scope.isAdd ? "Add UI Template" : "Edit UI Template";
        $scope.fromOrganization = fromOrganization===true;

        if ($scope.isAdd) {
            $scope.template = {
                name: "",
                dashboards: []
            };
        } else {
            $scope.template = currentUiTemplate;
        }
        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.save = function () {
            if ($scope.form.template.$valid) {
                var dto = {
                    template: $scope.template,
                    organizationId: Session.get().organizationId
                };
                console.log(dto);
                ThriftHelper.sendRequest(new UpdateUiTemplateReq(dto),
                        MsgType.UPDATE_UI_TEMPLATE_REQ, config.calls.WS_EDIT_UI_TEMPLATE);
            }
        };

        $scope.$on(config.calls.WS_EDIT_UI_TEMPLATE, function (event, args) {
            console.log(args);
            if (args.data instanceof UpdateUiTemplateResp) {
                if ($scope.isAdd) {
                    Toast.success("UI Template was successfully added.");
                } else {
                    Toast.success("UI Template was successfully updated.");
                    if(Session.get().uiTemplateId === args.data.template.id){
                        location.reload();
                    }
                }
                $mdDialog.hide();
            }
        });

        $scope.editDashboard = function (index, dashboard,ev) {
            console.log(index);

            $mdDialog.show({
                controller: 'AoUitDashboardCtrl',
                templateUrl: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/ao-uit-dashboard.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                multiple:true,
                locals: {
                    currentDashboard: dashboard
                }
            }).then(function (d) {
                if(index === null){
                    $scope.template.dashboards.push(d);
                }else{
                    $scope.template.dashboards[index] = d;
                }
            });
        };

        $scope.delete = function (index, dashboard,ev) {
            $scope.template.dashboards.splice(index, 1);
        };
        $scope.moveUp = function (index, dashboard,ev) {
            if(index === 0) return;
            var temp = $scope.template.dashboards[index];
            $scope.template.dashboards[index] = $scope.template.dashboards[index-1];
            $scope.template.dashboards[index-1] = temp;
        };
        $scope.moveDown = function (index, dashboard,ev) {
            if(index === $scope.template.dashboards.length-1) return;
            var temp = $scope.template.dashboards[index];
            $scope.template.dashboards[index] = $scope.template.dashboards[index+1];
            $scope.template.dashboards[index+1] = temp;
        };
    }]);
