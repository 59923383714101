
/* global angular, app */

app.factory('CloudModel', ['config',
    function(config) {
        
        /**
         * Create new User.
         * @param object represents response data from logedin rest
         * @constructor
         */
        var cloudSize = config.wordCloud.cloudSize;
        var normalizeFreq = 1;
        var normalizeCorr = config.wordCloud.maxCorrelation;
        
        function CloudModel(object) {
            this.all = object;
            setLocalRanks(this.all);
            setGlobalRanks(this.all);
            this.current = [];
            normalizeFreq = this.all.length;
        }
        
        CloudModel.prototype.setCurrentCloud = function(freqParam,corrParam) {
            var arr = angular.copy(this.all);
            for(var i in arr){
                arr[i].score = getScore(arr[i],freqParam,corrParam);
            }
            arr.sort(function(a, b){ return b.score-a.score;});
            this.current = arr.slice(0,Math.min(cloudSize,this.all.length));
            return this.current;
        };
        
        CloudModel.prototype.clone = function() {
            var cloned = angular.copy(this);
            
            // Remove as it's angular specific.
            delete cloned.$$hashKey;

            return cloned;
        };
        
        var getScore = function(item,freqParam,corrParam) {
            if(corrParam===-1){
                return item.freq;
            }
            return getFreqScore(item,freqParam)/normalizeFreq + getCorrScore(item,corrParam)/normalizeCorr;
        };
        
        var getFreqScore = function(item,freqParam) {
            return freqParam*item.gRank + (1-freqParam)*item.lRank;
        };
        
        var getCorrScore = function(item,corrParam) {
            return corrParam*Math.min(item.corr,normalizeCorr);
        };
        
        var setGlobalRanks = function(data) {
            data.sort(function(a, b){ return a.freqGlobal-b.freqGlobal;});
            for(var i in data){
                data[i].gRank = data.length - parseInt(i);
            }
        };
        var setLocalRanks = function(data) {
            data.sort(function(a, b){ return b.freq-a.freq;});
            for(var i in data){
                data[i].lRank = data.length - parseInt(i);
            }
        };

        // Return constructor
        return CloudModel;
    }
]);