
app.controller('adminOrganizationUsersCtrl', ['$scope', 'config', 'Toast', 'ThriftHelper', '$mdDialog', 'adminService',
    'Session',
    function ($scope, config, Toast, ThriftHelper, $mdDialog, adminService,
              Session) {




        var filterUsers = function (users) {
            var orgId = Session.get().organizationId;
            return _.where(users, { 'organizationId': orgId});
        };

        $scope.users = filterUsers(adminService.data.users);

        $scope.adminService = adminService;

        adminService.getUsers();
        adminService.getUiTemplates();

        $scope.$watch(function(){ return adminService.data.users;}, function(newVal){
            newVal.forEach(function (user) {
                user.email2 = user.email; //duplicate email, because of md-table issue
            });
            $scope.users = filterUsers(newVal);
        });

        $scope.isNotMe = function(email){
            var user = Session.get();
            return !(angular.isObject(user) && user.email === email);
        }


    }]);
