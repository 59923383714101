
app.controller('adminHistoryCtrl', ['$scope', 'adminService',
    function ($scope,adminService) {

        $scope.history = adminService.data.history;
        $scope.getter = adminService.getter;
        $scope.today = new Date();
        $scope.historyDate = new Date();
        $scope.init = adminService.init;
        adminService.getHistory();

        $scope.getHistory = function () {
            adminService.getHistory($scope.historyDate);
        };

        $scope.$watch(function(){ return adminService.data.history;}, function(newVal){
            $scope.history = newVal;
        });
    }]);
