app.service('documentDetailIdGenerator', [
    function () {
        var zeroItem = 'None';

        var thiz = this;
        thiz.viewWidgets = {};
        thiz.viewWidgets[WidgetType.DOCUMENT_DETAILS] = {type: WidgetType.DOCUMENT_DETAILS, prefix: 'Document view',
            name: 'Document details view',
            caption: 'To use Document details view link it using Document list or Document geo view settings.'
        };
        thiz.viewWidgets[WidgetType.NEWS_DETAILS] = {type: WidgetType.NEWS_DETAILS, prefix: 'News view',
            name: 'News details view',
            caption: 'To use News details view link it using News list or News geo view settings.'};
        thiz.viewWidgets[WidgetType.SOCIAL_DETAILS] = {type: WidgetType.SOCIAL_DETAILS, prefix: 'Social view',
            name: 'Social details view',
            caption: 'To use Social details view link it using Social list or Social geo view settings.'};

        var noItemsText = 'None (No view added to layout)';

        /**
         * Generates ID for all Document Details View widgets in specified dashboard.
         *
         * @param layout
         */
        thiz.generateId = function (dashboard) {
            var counters = {};
            for (var i in thiz.viewWidgets){
                counters[i] = 0;
            };

            if(angular.isArray(dashboard.columns)) {
                // automatically assign sequential number as ID to each Document Details View
                dashboard.columns.forEach(function (col) {
                    col.widgets.forEach(function (widget) {
                        if (thiz.viewWidgets[widget.type]) {
                            counters[widget.type]++;
                            widget.detailsId = counters[widget.type];
                        } else if (widget.type === WidgetType.MULTI_WIDGET) {
                            // in case of multiwidget we would like to handle also "sub-widgets"
                            widget.widgets.forEach(function (item) {
                                if (thiz.viewWidgets[item.type]) {
                                    counters[item.type]++;
                                    item.detailsId = counters[item.type];
                                }
                            });
                        }
                    });
                });
            }
        };

        /**
         * Searches for Document Details View components in dashboard and prepares array of items {id, text} that can be
         * used in drop down components (e.g. md-select and md-option).
         *
         * @param dashboard
         */
        thiz.dashboard2items = function (dashboard,type) {
            var items = [{
                id: 0,
                text: zeroItem
            }];

            if(angular.isArray(dashboard.columns)) {
                // search for all IDs to build up options that can be used for drop down
                dashboard.columns.forEach(function (col) {
                    col.widgets.forEach(function (widget) {
                        if (widget.type === type) {
                            items.push({
                                id: widget.detailsId,
                                text: thiz.viewWidgets[type].prefix + widget.detailsId
                            });
                        } else if (widget.type === WidgetType.MULTI_WIDGET) {
                            // in case of multiwidget we would like to handle also "sub-widgets"
                            widget.widgets.forEach(function (item) {
                                if (item.type === type) {
                                    items.push({
                                        id: item.detailsId,
                                        text: thiz.viewWidgets[type].prefix + item.detailsId
                                    });
                                }
                            });
                        }
                    });
                });
            }

            if (items.length === 1) {
                // there is no any document details view in Layouts
                items[0].text = noItemsText;
            }

            return items;
        };

        /**
         * Gets the selection based on selected item ID and array of items.
         * <p>
         *     In case if item id doesn't fit into specified items array, item on zero index will be selected.
         * </p>
         * @param selectedItemId
         * @param items
         * @return {number}
         */
        thiz.getSelection = function (selectedItemId, items) {
            // we would like to select "None" at index 0 by default
            var selection = selectedItemId ? selectedItemId : 0;
            if (selection >= items.length) {
                selection = 0;
            }
            return selection;
        };

        return thiz;
    }
]);