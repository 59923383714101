/**
 * Created by Matej on 31.7.2014.
 */

/**
 *  controller for home page
 **/

app.controller('checkoutCtrl', ['$scope', 'Toast', '$stateParams', 'Session', '$rootScope','$state','config','ThriftHelper',
    function ($scope, Toast, $stateParams, Session, $rootScope, $state, config, ThriftHelper) {


        $scope.text = "Checking...";

        var init = function () {
            if($stateParams.id && $stateParams.status){
                var status = getStatus($stateParams.status);
                if(status) {
                    ThriftHelper.sendRequest(new SetCheckoutStatusReq({
                            session: $stateParams.id,
                            status: status
                        }),
                        MsgType.SET_CHECKOUT_STATUS_REQ, config.calls.WS_SET_CHECKOUT_STATUS);
                } else {
                    $state.go('app.dashboard');
                }
            }else{
                $state.go('app.dashboard');
            }
        };

        $scope.$on(config.calls.WS_SET_CHECKOUT_STATUS, function (event, args) {
            if (args && args.data instanceof SetCheckoutStatusResp) {
                var status = args.data.status;
                switch (status) {
                    case CheckoutStatus.COMPLETE: $scope.text = "Complete status!"; break;
                    case CheckoutStatus.PENDING: $scope.text = "Pending status!"; break;
                    case CheckoutStatus.CANCEL: $scope.text = "Cancel status!"; break;
                    case CheckoutStatus.INVALID: $scope.text = "Invalid status!"; break;
                    default: $scope.text = "Unknown status!";
                }
            }
        });

        var getStatus = function (status) {
            switch (status) {
                case '0': return CheckoutStatus.CANCEL;
                case '1': return CheckoutStatus.COMPLETE;
            }
            return undefined;
        };


        init();

    }]);
