/**
 * Created by timkos on 19/01/17.
 */
angular.module('percipioApp').factory(
    "ExportService", function ($rootScope, Toast, Session, $mdDialog, $state, FileSaver, $q) {

        var thiz = this;

        thiz.exportDialog = function (type, settings, ev) {
            if (Session.isAnonymousOrTrial()) {
                var alert = $mdDialog.alert()
                    .title('Export is not available for try out or trial!')
                    .textContent('This functionality is only available in licensed version.')
                    .clickOutsideToClose(true)
                    .targetEvent(ev)
                    .ok('Ok');

                $mdDialog.show(alert).then(function () {
                }, function () {
                });
            } else {
                $mdDialog.show({
                    controller: 'exportCtrl',
                    templateUrl: 'app/module/dialogs/export/export-dialog.html',
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: true,
                    locals: {
                        type: type,
                        settings: settings
                    }
                });
            }
        };


        thiz.exportPng = function (type, svg, name, description) {
            if (description) {
                var nsvg = svg.clone();
                var h = parseInt(nsvg.attr("height")) + 10;
                nsvg.append('<text y="' + h + '" x="10" font-size="12">' + description + '</text>');
                nsvg.attr("height", h + 4);
                svg = nsvg;
            }
            saveSvgAsPng(svg[0], name + ".png");
        };

        thiz.exportSvg = function (type, svg, name, description) {
            if (description) {
                var nsvg = svg.clone();
                var h = parseInt(nsvg.attr("height")) + 10;
                nsvg.append('<text y="' + h + '" x="10" font-size="12">' + description + '</text>');
                nsvg.attr("height", h + 4);
                svg = nsvg;
            }
            saveSvg(svg[0], name + ".svg");
        };

        thiz.exportBib = function (type, docs, name) {
            var text = "";
            for (var i = 0; i < docs.length; i++) {
                text += docs[i].exportBibTex() + "\n";
            }

            var blob = new Blob([text], {type: 'text/plain;charset=utf-8'});
            FileSaver.saveAs(blob, name + '.bib');
        };

        thiz.exportRis = function (type, docs, name) {
            var text = "";
            for (var i = 0; i < docs.length; i++) {
                text += docs[i].exportRIS();
            }

            var blob = new Blob([text], {type: 'text/plain;charset=utf-8'});
            FileSaver.saveAs(blob, name + '.ris');
        };

        thiz.exportCsv = function (type, docs, name, delimiter) {
            var header = ["title", "authors", "type", "url", "doi", "year", "date", "source", "journal",
                "volume", "issue", "keywords", "abstract", "assignees", "patent/publication", "priority date",
                "application date", "publishing date"];
            if (type === "timeline" || type === "news-details") {
                header = ["date", "journal", "title", "url", "abstract"];
            } else if (type === "social-list" || type === "social-details") {
                header = ["time", "language", "location", "favorite count", "quote count", 'reply count', 'url', 'text'];
            }
            var text = header.join(delimiter) + "\n";
            for (var i = 0; i < docs.length; i++) {
                text += docs[i].exportCSV(delimiter) + "\n";
            }

            var blob = new Blob([text], {type: 'text/plain;charset=utf-8'});
            FileSaver.saveAs(blob, name + '.csv');
        };

        thiz.exportMap = function (type, map, name) {
            map.printMap('CurrentSize', name);
        };

        thiz.exportPdf = function (type, docs, name, description, cbSuccess, cbError) {
            // first we wait for promises using $q.when (if there are any), then we call internal function for export
            var promises = [];
            for (var i = 0; i < docs.length; i++) {
                promises.push($q.when(docs[i].exportPDF()));
            }

            $q.all(promises)
                .then(function (docsPDFs) {
                    var docsPDF = [];
                    for (var i = 0; i < docsPDFs.length; i++) {
                        docsPDF.push({
                            stack: docsPDFs[i],
                            style: 'item',
                            margin: [0, 20, 0, 0]
                        });
                    }
                    thiz._exportPdf(type, docsPDF, name, description, cbSuccess, cbError);
                }, function (reason) {
                    cbError(reason);
                });
        };

        thiz._exportPdf = function (type, docsPDF, name, description, cbSuccess, cbError) {
            var docDefinition = {
                content: [
                    {
                        stack: docsPDF,
                        style: 'items'
                    }
                ],
                footer: function (page, pages) {
                    return {text: page, alignment: "center", fontSize: 11, color: "#555555"};
                },
                styles: {
                    header: {
                        fontSize: 12
                    },
                    items: {
                        fontSize: 9
                    },
                    title: {
                        color: "#d43300",
                        fontSize: 11,
                        lineHeight: 1.1
                    },
                    author: {
                        lineHeight: 1.3
                    },
                    details: {
                        color: "#555555",
                        lineHeight: 1.3,
                        italics: true
                    }

                }
            };

            if (angular.isObject(description)) {
                var keywordsPDF = [];
                if (description.keywords.length === 0) {
                    keywordsPDF = "(empty)";
                } else {
                    for (var i = 0; i < description.keywords.length; i++) {
                        var k = description.keywords[i];
                        keywordsPDF.push({
                            text: k.source.id === 0 ? k.word : (k.source.short + ":" + k.word)
                        });
                        keywordsPDF.push({
                            text: " (" + k.type.text + ")",
                            fontSize: 10,
                            italics: true
                        });
                        if (i !== description.keywords.length - 1) {
                            keywordsPDF.push(", ")
                        }
                    }
                }

                var headPDF = {};
                if (type === "infinitlist") {
                    headPDF = {
                        widths: ['*', '*', '*'],
                        body: [
                            [{text: description.type, style: 'th', alignment: 'left'},
                                {text: description.sortBy, style: 'th', alignment: 'center'},
                                {text: "Case: " + description.case, style: 'th', alignment: 'right'}],
                            // [{text: 'Query', colSpan: 4, style: 'th', alignment: 'center'},{},{},{}],
                            [{text: [{text: 'Query: ', bold: true}, keywordsPDF], colSpan: 3, style: 'tb'}, {}, {}]
                        ]
                    };
                } else if (type === "timeline") {
                    headPDF = {
                        widths: ['*', '*', '*', '*'],
                        body: [
                            [{text: "Case: " + description.case, style: 'th', colSpan: 4}, {}, {}, {}],
                            [{text: [{text: 'Query: ', bold: true}, keywordsPDF], colSpan: 4, style: 'tb'}, {}, {}, {}]
                        ]
                    }
                } else if (type === "social-list") {
                    headPDF = {
                        widths: ['*', '*', '*'],
                        body: [
                            [{text: description.tags, style: 'th', alignment: 'left'},
                                {text: description.sortBy, style: 'th', alignment: 'center'},
                                {text: "Case: " + description.case, style: 'th', alignment: 'right'}],
                            // [{text: 'Query', colSpan: 4, style: 'th', alignment: 'center'},{},{},{}],
                            [{text: [{text: 'Query: ', bold: true}, keywordsPDF], colSpan: 3, style: 'tb'}, {}, {}]
                        ]
                    }
                }

                docDefinition.content.unshift({
                    table: headPDF,
                    style: 'header',
                    layout: {
                        hLineWidth: function (i, node) {
                            return 1;
                        },
                        vLineWidth: function (i, node) {
                            if (i === 0 || i === node.table.widths.length) {
                                return 1;
                            }
                            return 0;
                        },
                        hLineColor: function (i) {
                            return '#aaa';
                        },
                        vLineColor: function (i) {
                            return '#aaa';
                        },
                        paddingTop: function (i) {
                            return 5;
                        },
                        paddingBottom: function (i, node) {
                            return 5;
                        },
                        paddingLeft: function (i, node) {
                            return 5;
                        },
                        paddingRight: function (i, node) {
                            return 5;
                        }
                    }
                });
            }

            $.when(
                cachedScript("./js/pdfmake/pdfmake.min.js"),
                cachedScript("./js/pdfmake/vfs_fonts.js")
            ).done(function () {
                pdfMake.createPdf(docDefinition).download(name + ".pdf", function () {
                    cbSuccess();
                });
            }).fail(function () {
                cbError();
            });
        }

        var cachedScript = function (url) {
            return jQuery.ajax({
                dataType: "script",
                cache: true,
                url: url,
                type: 'GET',
                data: null,
                async: false
            });
        };

        return thiz;
    });
