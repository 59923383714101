/* global app, _ */

/**
 * Created by matejk on 17.10.2014.
 * search2Ctr site has directives: headermenu, wordcloud, foot
 * 
 */

app.controller('dashboardCtrl', ['$scope', '$stateParams', 'config', 'mobile', '$state',
    'defaultsettings', 'Session', 'QueryModel','HeaderOptions', 'DashboardService',
    function ($scope, $stateParams, config, mobile, $state,
            defaultsettings, Session, QueryModel,HeaderOptions, DashboardService) {

        $scope.service = DashboardService;

        $scope.mainToolsOptions = {
            keywords: $scope.service.keywords,
            redirect: $scope.service.redirect
        };


        // --------------
        // --- SET HEADER OPTIONS ---
        // --------------
        if(Session.get().role === UserRole.ANONYMOUS){
            HeaderOptions.set([]);
        }else {
            if(angular.isObject(Session.get().currentTwitterChannel)) {
                HeaderOptions.set([
                    HeaderOptions.defaultOptions.switchCase($scope.redirect),
                    HeaderOptions.defaultOptions.switchTwitterChannel($scope.redirect)
                ]);
            }else{
                HeaderOptions.set([
                    HeaderOptions.defaultOptions.switchCase($scope.redirect)
                ]);
            }
        }

    }
]);
