
app.controller('layoutCtrl', ['$scope', 'layoutIdx', 'Toast','$mdDialog',
    'config','ThriftHelper','mobile','Session','$state','defaultsettings',
    function ($scope, layoutIdx, Toast,$mdDialog,
    config,ThriftHelper,mobile,Session,$state, defaultsettings) {
        
        $scope.form = {};
        $scope.isAdd = layoutIdx === null;
        $scope.disableDefault = false;
        var type = mobile()? 'mobile':'desktop';
        if(type==='desktop'){
            $scope.matrix = [2,4];
        }else{
            $scope.matrix = [1,1];
        }
        var site = $state.current.name.split(".")[1];

        $scope.title = $scope.isAdd ? "Add layout" : "Edit layout";

        $scope.widgets = defaultsettings.widgets;
        $scope.simpleWidgets = _.filter($scope.widgets, { simple: true });

        var user = angular.copy(Session.get());
        var layouts = user.layoutSettings[site][type];

        $scope.canRemove = !$scope.isAdd && layouts.length>1;
        
        if($scope.isAdd){
            $scope.layout = {
                name: "",
                cols:[
                    {
                    rows: [{}]
                    }
                ]
            };
        }else{
            $scope.layout = angular.copy(layouts[layoutIdx]);
            if($scope.layout.default === true){
                $scope.disableDefault = true;
            }

            var cols = $scope.layout.cols;
            for(var i = 0; i<cols.length; i++){
                for(var j=0; j<cols[i].rows.length;j++){
                    var w = cols[i].rows[j];
                    switch(w.id){
                        case "details":
                            if(!angular.isNumber(w.options.fromYear)){
                                w.options.fromYear = 1970;
                            }
                            break;
                    }
                }
            }
        }
        
        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        
        var isRemove = false;
        $scope.remove = function (ev) {
            layouts.splice(layoutIdx,1);
            var simple = angular.copy(user.simpleUser);
            simple.layoutSettings = JSON.stringify(user.layoutSettings);
            isRemove = true;
            ThriftHelper.sendRequest(new UpdateUser({user: simple}),
                    MsgType.UPDATE_USER, config.calls.WS_EDIT_USER);
        };
        
        $scope.save = function () {
            console.log($scope.layout);
            if($scope.form.layout.$valid && $scope.layout.cols.length>0){
                if($scope.layout.default===true){
                    _.each(layouts, function(item){ if(item.default===true){ delete(item.default); }});
                }
                if($scope.isAdd){
                    layouts.push($scope.layout);
                }else{
                    layouts[layoutIdx] = $scope.layout;
                }
                var simple = angular.copy(user.simpleUser);
                simple.layoutSettings = JSON.stringify(user.layoutSettings);
                ThriftHelper.sendRequest(new UpdateUser({user: simple}),
                        MsgType.UPDATE_USER, config.calls.WS_EDIT_USER);
            }
        };
        
        $scope.addRow = function (arr) {
            arr.push({});
            onLayoutChanged();
        };
        $scope.addCol = function (arr) {
            arr.push({ rows: [{}]});
            onLayoutChanged();
        };
        $scope.removeWidget = function (parent_idx,idx) {
            if($scope.layout.cols[parent_idx].rows.length>1){
                $scope.layout.cols[parent_idx].rows.splice(idx,1);
            }else{
                $scope.layout.cols.splice(parent_idx,1);
            }
            onLayoutChanged();
        };
        $scope.addSubWidget = function (widget) {
            widget.options.items.push({});
            onLayoutChanged();
        };
        $scope.removeSubWidget = function (parent_widget,idx) {
            parent_widget.options.items.splice(idx,1);
            onLayoutChanged();
        };
        
        $scope.widgetChange = function(widget, isSub){
            switch(widget.id){
                case 'cloud': 
                    widget.options = {
                        ft: 0,
                        corr: 100
                    };
                    break;
                case 'details': 
                    widget.options = {
                        showFreq: true,
                        fromYear: 1970
                    };
                    break;
                case 'list': 
                    widget.options = {
                        type: 0,
                        sortBy: 'relevance',
                        range: 0
                    };
                    break;
                case 'complexity':
                    widget.options = {
                        type: 0,
                        sortBy: 'relevance',
                        range: 0
                    };
                    break;
                case 'multiwidget':
                    widget.options = {
                       items: [{}]
                    };
                    break;
                case 'documentgeo':
                    widget.options = {
                        spreadDensity: "0", // None by default
                        documentGeoMaxLoad: "5000",
                        type: 0
                    };
                    break;
                case 'documentdetails':
                    widget.options = {
                    };
                    break;
            }
            if(isSub){
                widget.name = _.find($scope.widgets,{id: widget.id}).name;
            }

            onLayoutChanged();
        };
        
        $scope.typeChange = function(options){
            if(options.type === 3){ //WIKI
                options.sortBy = 'relevance';
                options.range = 0;
            }
        };
        
        $scope.$on(config.calls.WS_EDIT_USER, function (event, args) {
            if(args.data===null){
                var realUser = Session.get();
                if($scope.isAdd){
                    Toast.success("Layout was successfully added.");
                }else{
                    if(isRemove){
                        Toast.success("Layout was successfully removed.");
                    }else{
                        Toast.success("Layout was successfully updated.");
                    }
                }
                realUser.layoutSettings[site][type] = layouts;
                realUser.simpleUser.layoutSettings = JSON.stringify(realUser.layoutSettings);
                $scope.$apply();
                $mdDialog.hide(isRemove);
            }
        });
        
        $scope.arr = [];
        for(var i = 0;i<=100;i+=5){
            $scope.arr.push(i);
        }
        
        $scope.type = _.filter(defaultsettings.articleTypes, function (item) {
                        return item.id >= 0 && item.id <= 4;
                    });
        $scope.sortBy = defaultsettings.sortBy;
        $scope.range = defaultsettings.articleYears;
        
        $scope.spreadDensity = defaultsettings.spreadDensity;
        $scope.documentGeoMaxLoad = defaultsettings.documentGeoMaxLoad;

        $scope.lastYear = (new Date).getFullYear() - 5;

        function onLayoutChanged() {
            $scope.$broadcast("onLayoutChanged", $scope.layout);
        }
    }]);
