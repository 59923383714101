/**
 * Created by matejk on 2.10.2014.
 */

app.directive("mobile", ['ngIfDirective', 'mobile', function(ngIfDirective, mobile) {
    var ngIf = ngIfDirective[0];

    return {
        transclude: ngIf.transclude,
        priority: ngIf.priority - 1,
        terminal: ngIf.terminal,
        restrict: ngIf.restrict,
        link: function(scope, element, attributes) {
            // find the initial ng-if attribute
            var initialNgIf = attributes.ngIf, ifEvaluator;
            var initialMobile = attributes.mobile;
            // if it exists, evaluates ngIf && ifAuthenticated
            if (initialNgIf) {
                ifEvaluator = function () {
                    return scope.$eval(initialNgIf) && (mobile()===scope.$eval(initialMobile));
                };
            } else { // if there's no ng-if, process normally
                ifEvaluator = function () {
                    return (mobile()===scope.$eval(initialMobile));
                };
            }
            attributes.ngIf = ifEvaluator;
            ngIf.link.apply(ngIf, arguments);
        }
    };
}]);