/**
 * app url provider for url control
 **/

app.config(function ($stateProvider, $urlRouterProvider, $httpProvider) {

//    $httpProvider.interceptors.push([
//        '$injector',
//        function ($injector) {
//            return $injector.get('AuthInterceptor');
//        }
//    ]);

    //$urlRouterProvider.otherwise('app.search');


    $stateProvider
        .state('trial', {
            url: '/trial?caseId',
            templateUrl: 'app/module/trial/trial.html',
            auth: function (session) {
                return !session.isLogged() || session.get().role === UserRole.ANONYMOUS;
            }
        })
        .state('app.checkout', {
            url: '^/checkout?status&id',
            templateUrl: 'app/module/checkout/checkout.html',
            auth: function (session) {
                return session.isLogged();
            }
        })
        .state('signup', {
            url: '/signup?order',
            templateUrl: 'app/module/signup/signup.html',
            auth: function (session) {
                return !session.isLogged() || session.get().role === UserRole.ANONYMOUS;
            }
        })
        .state('login', {
            url: '/login',
            templateUrl: 'app/module/login/login.html',
            auth: function (session) {
                return !session.isLogged() || session.get().role === UserRole.ANONYMOUS;
            }
        })
        .state('app', {
            url: '/app',
            templateUrl: 'app/module/master/master.html',
            abstract: true
        })
        // .state('app.scientific', {
        //     url: '^/scientific?q',
        //     templateUrl: 'app/module/scientific/scientific.html'
        // })
        .state('app.dashboard', {
            url: '^/dashboard?q',
            templateUrl: 'app/module/dashboard/dashboard.html',
            redirectTo: "app.dashboard.item",
            redirectToParams: {id: "0"}
        })
        .state('app.dashboard.item', {
            url: '/:id',
            templateUrl: 'app/module/dashboard/dashboard-item.html',
            auth: function (session, toParams) {
                var user = session.get();
                var idx = parseInt(toParams.id);
                return session.isLogged() && user.uiTemplate !== null && user.uiTemplate !== undefined
                    && angular.isNumber(idx) && idx >= 0 && idx < user.uiTemplate.dashboards.length;
            }
        })
        // .state('app.search', {
        //     url: '^/search?q&layout',
        //     templateUrl: 'app/module/search/search.html',
        //     data: {},
        //     auth: function(session) {
        //         return session.isLogged();
        //     }
        // })
        // .state('app.news', {
        //     url: '^/news?q&view&type&days&sortBy',
        //     templateUrl: 'app/module/news-search/news-search.html',
        //     data: {},
        //     auth: function(session) {
        //         return session.isLogged();
        //     }
        // })
        // .state('app.social', {
        //     url: '^/social?q',
        //     templateUrl: 'app/module/social/social.html',
        //     data: {},
        //     auth: function(session) {
        //         return session.isLogged();
        //     }
        // })
        // .state('app.search-detail', {
        //     url: '/search2/:search/:search2',
        //     templateUrl: 'app/module/search-detail/search-detail.html',
        //     data: {}
        // })
        // .state('app.geodata', {
        //     url: '^/social/:company',
        //     templateUrl: 'app/module/social/social.html',
        //     data: {}
        // })
        // .state('app.twitterAnalytics', {
        //     url: '/social-analytics/:q',
        //     templateUrl: 'app/module/social-search/twitterAnalytics.html',
        //     data: {}
        // })
        .state('app.admin', {
            abstract: true,
            url: "^/admin",
            templateUrl: 'app/module/admin/admin.html'
        })
        .state('app.admin.users', {
            url: '/users',
            templateUrl: 'app/module/admin/users/users.html',
            data: {},
            auth: function (session) {
                return session.isLogged() && session.get().role === UserRole.ADMIN;
            }
        })
        .state('app.admin.organizations', {
            url: '/organizations',
            templateUrl: 'app/module/admin/organizations/organizations.html',
            data: {},
            auth: function (session) {
                return session.isLogged() && session.get().role === UserRole.ADMIN;
            }
        })
        .state('app.admin.current', {
            url: '/current',
            templateUrl: 'app/module/admin/current/current.html',
            data: {},
            auth: function (session) {
                return session.isLogged() && session.get().role === UserRole.ADMIN;
            }
        })
        .state('app.admin.cases', {
            url: '/cases',
            templateUrl: 'app/module/admin/cases/cases.html',
            data: {},
            auth: function (session) {
                return session.isLogged() && session.get().role === UserRole.ADMIN;
            }
        })
        .state('app.admin.channels', {
            url: '/channels',
            templateUrl: 'app/module/admin/twitter-channels/twitter-channels.html',
            data: {},
            auth: function (session) {
                return session.isLogged() && session.get().role === UserRole.ADMIN;
            }
        })
        .state('app.admin.history', {
            url: '/history',
            templateUrl: 'app/module/admin/history/history.html',
            data: {},
            auth: function (session) {
                return session.isLogged() && session.get().role === UserRole.ADMIN;
            }
        })
        .state('app.admin.sources', {
            url: '/sources',
            templateUrl: 'app/module/admin/sources/sources.html',
            data: {},
            auth: function (session) {
                return session.isLogged() && session.get().role === UserRole.ADMIN;
            }
        })
        .state('app.admin-organization', {
            abstract: true,
            url: "^/admin-organization",
            templateUrl: 'app/module/admin-organization/admin-organization.html'
        })
        .state('app.admin-organization.users', {
            url: '/users',
            templateUrl: 'app/module/admin-organization/admin-organization-users/admin-organization-users.html',
            data: {},
            auth: function (session) {
                var role = session.get().role;
                return session.isLogged() &&
                    (role === UserRole.ADMIN || role === UserRole.ORGANIZATION);
            }
        })
        .state('app.admin-organization.ui-templates', {
            url: '/ui-templates',
            templateUrl: 'app/module/admin-organization/admin-organization-ui-templates/admin-organization-ui-templates.html',
            data: {},
            auth: function (session) {
                var role = session.get().role;
                return session.isLogged() &&
                    (role === UserRole.ADMIN || role === UserRole.ORGANIZATION);
            }
        })
        .state('app.admin-organization.sources', {
            url: '/sources',
            templateUrl: 'app/module/admin-organization/admin-organization-sources/admin-organization-sources.html',
            data: {},
            auth: function (session) {
                var role = session.get().role;
                return session.isLogged() &&
                    (role === UserRole.ADMIN || role === UserRole.ORGANIZATION);
            }
        });
    // .state('app.graph', {
    //     url: '^/graph',
    //     templateUrl: 'app/module/graph/graph.html',
    //     data: {}
    // })
    // .state('app.graph/:company', {
    //     url: '/graph',
    //     templateUrl: 'app/module/graph/graph.html',
    //     data: {}
    // })
    // .state('app.socialanalyze', {
    //     url: '^/socialanalyze?q&chart',
    //     templateUrl: 'app/module/social-analyze/social-analyze.html',
    //     data: {}
    // })
    // .state('app.scopes-management', {
    //     url: '^/scopes-management',
    //     templateUrl: 'app/module/scope/scopes-management.html',
    //     data: {},
    //     auth: function(session) {
    //         return session.isLogged() && session.get().role <= UserRole.CONSUMER;
    //     }
    // });
});
