
app.controller('adminTwitterChannelsCtrl', ['$scope', 'adminService', '$mdDialog',
    function ($scope, adminService, $mdDialog) {

        $scope.twitterChannels = adminService.data.twitterChannels;
        $scope.getter = adminService.getter;
        $scope.init = adminService.init;
        adminService.getTwitterChannels();

        $scope.$watch(function(){ return adminService.data.twitterChannels;}, function(newVal){
            $scope.twitterChannels = newVal;
        });

        $scope.editChannel = function (row, ev) {
            if(row){
                row = _.find($scope.twitterChannels, function(item){
                    return item.id === row;
                })
            }
            $mdDialog.show({
                controller: 'editTwitterChannelsCtrl',
                templateUrl: 'app/module/admin/twitter-channels/twitter-channels-edit.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {
                    currentTwitterChannel: angular.copy(row),
                    addToOrganization: false
                }
            })
                .then(function (answer) {
                    adminService.getTwitterChannels();
                });
        };
    }]);
