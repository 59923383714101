
app.controller('adminOrganizationUiTemplatesCtrl', ['$scope', 'config', 'Toast', 'ThriftHelper', '$mdDialog', 'adminService',
    'Session',
    function ($scope, config, Toast, ThriftHelper, $mdDialog, adminService,
              Session) {

        $scope.adminService = adminService;
        $scope.uiTemplates = adminService.data.uiTemplates;

        adminService.getUiTemplates();

        $scope.$watch("adminService.data.uiTemplates", function(newVal){
            $scope.uiTemplates = newVal;
        });


    }]);
