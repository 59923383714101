/* global d3, app */

/**
 * Created by matejk on 8.10.2014.
 */

app.directive("wordhistory", ["Session", "$state", 'ThriftHelper', '$timeout','DashboardService',
    'config', '$filter','ExportService',
    function (Session, $state, ThriftHelper, $timeout,DashboardService,
            config, $filter,ExportService) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/directives/word-history/word-history.html',
            scope: {
                options: "="
            },
            link: function (scope, element, attrs) {
                // ------------------
                // --- SCOPE VARS ---
                // ------------------
                scope.service = DashboardService;
                scope.loading = true;
                scope.id = "word-history-"  + UUID.generate();
                scope.graph = undefined;

                scope.user = Session.get();

                scope.history = [];
                scope.showFreq = true;
                scope.showPercentage = false;
                scope.showRelNorm = false;
                scope.yearRange = [1970, (new Date).getFullYear()];
                if(scope.options){
                    scope.showFreq = scope.options.showFreq;
                    if(scope.options.fromYear) scope.yearRange[0] = scope.options.fromYear;
                    if(scope.options.showPercentage) scope.showPercentage = scope.options.showPercentage;
                    if(scope.options.showRelNorm) scope.showRelNorm = scope.options.showRelNorm;
                }

                var getCall = function () {
                    return config.calls.WS_WORD_HISTORY + "-" + scope.id;
                };

                scope.getVocabularyHistory = function () {
                    d3.select(element[0]).select(".word-history .svg-container svg").remove();
                    scope.graph = undefined;
                    if (!angular.isArray(scope.keywords) || scope.keywords.length === 0) {
                        scope.loading = false;
                        return;
                    }
                    scope.loading = true;
                    var dto = {
                        organization: Session.get().currentCase.name,
                        keywords: scope.keywords,
                        fromYear: scope.yearRange[0]
                    };
                    ThriftHelper.sendRequest(new SearchVocabularyHistoryReq(dto),
                            MsgType.SEARCH_VOCABULARY_HISTORY_REQ, getCall());


                };

                scope.$on(getCall(), function (event, args) {
                    if (args.err === false) {
                        var data = args.data.keywords;
                        //console.log(data);
                        var hasTotal = args.data.totals !== null;
                        var totals = [];
                        if(hasTotal) {
                            for (var j = scope.yearRange[0]; j <= scope.yearRange[1]; j++) {
                                // set initial data to -1 to avoid division by zero
                                totals.push({"year": j, "files": -1, "freq": -1})
                            }
                            for(var j = 0; j < args.data.totals.length; j++){
                                var y = parseInt(args.data.totals[j].year);
                                if(y >= scope.yearRange[0] && y <= scope.yearRange[1]) {
                                    var idx = y - scope.yearRange[0];
                                    totals[idx].freq = (args.data.totals[j].freq > 0) ? args.data.totals[j].freq : -1;
                                    totals[idx].files = (args.data.totals[j].files > 0) ? args.data.totals[j].files : -1;
                                }
                            }
                        }
                        scope.history = [];

                        for (var i=0; i<data.length; i++) {
                            scope.history[i] = {
                                bar: true,
                                key: data[i].keyword,
                                values: []
                            };

                            for(var j = scope.yearRange[0]; j <= scope.yearRange[1]; j++){
                                scope.history[i].values.push({"year":j, "files":0, "freq":0})
                            }

                            for(var j = 0; j < data[i].data.length; j++){
                                var y = parseInt(data[i].data[j].year);
                                if(y >= scope.yearRange[0] && y <= scope.yearRange[1]) {
                                    var idx = y - scope.yearRange[0];
                                    scope.history[i].values[idx].freq = data[i].data[j].freq;
                                    scope.history[i].values[idx].files = data[i].data[j].files;
                                    if(hasTotal){
                                        if (totals[idx].freq > 0) {
                                            scope.history[i].values[idx].freq /= totals[idx].freq;
                                            scope.history[i].values[idx].files /= totals[idx].files;
                                        } else {
                                            // reset to 0 as total is invalid
                                            scope.history[i].values[idx].freq = 0;
                                            scope.history[i].values[idx].files = 0;
                                        }
                                    }
                                }
                            }

                            if(scope.history[i].values.length > 0) {
                                var maxFreq = _.max(scope.history[i].values, 'freq').freq;
                                var maxFiles = _.max(scope.history[i].values, 'files').files;

                                for (var j = 0; j < scope.history[i].values.length; j++) {
                                    scope.history[i].values[j].freqRelNorm = scope.history[i].values[j].freq / maxFreq;
                                    scope.history[i].values[j].filesRelNorm = scope.history[i].values[j].files / maxFiles;
                                }
                            }

                        }

                        scope.drawHistory();
                    }
                });


                function generateTooltipContent(d) {
                    var html = '<div style="max-width: 150px; color: rgb(50, 131, 180); display: inline-block; overflow: hidden; ' +
                        'text-overflow: ellipsis; word-wrap: break-word;font-size: 16px; line-height: 20px;' +
                        'padding: 3px 6px;">'+ d.data.key +' (' + d.data.year + ')</div>';
                    html += "<div class='d3plus_tooltip_data_container'>";
                    html += '<div class="t_item">' +
                        '<span class="t_child_name">Files</span>' +
                        '<span class="t_child_score">' + $filter('number')(d.data.files,8) + '</span>' +
                        '</div>';
                    html += '<div class="t_item">' +
                        '<span class="t_child_name">Frequency</span>' +
                        '<span class="t_child_score">' + $filter('number')(d.data.freq,8) + '</span>' +
                        '</div>';
                    html += "</div>";
                    return html;
                }

                var setSize = function () {
                    var svgContainer = element.find('.svg-container');
                    var s = [svgContainer.width(), svgContainer.height()];
                    for (var i = 0; i < 2; i++) {
                        s[i] = Math.max(s[i], 140);
                    }

                    return s;
                };

                var yValue = function(d) {
                    var v = scope.showFreq ? d.freq : d.files;
                    if(scope.showRelNorm){
                        v = scope.showFreq ? d.freqRelNorm : d.filesRelNorm;
                    }
                    return v;
                };



                var setYAxis = function(graph){
                    if(scope.showPercentage){
                        graph.y1Axis
                            .tickFormat(d3.format(".2p"));
                    }else{
                        graph.y1Axis
                            .tickFormat(d3.format(',.2n'));
                    }
                };

                scope.drawHistory = function () {
                    scope.loading = true;


                    nv.addGraph(function() {
                        scope.size = setSize();

                        var chart = nv.models.barChartWithFocus()
                            .margin({top: 10, right: 10, bottom: 35, left: 65});

                        chart.xAxis.tickFormat(function(d) {
                            return d;
                        });
                        chart.tooltip.contentGenerator(function(key) {
                            return  generateTooltipContent(key);
                        });

                        chart.x2Axis.tickFormat(function(d) {
                            return d;
                        });

                        chart.y(yValue);
                        chart.x(function(d) {
                            return d.year;
                        });
                        chart.y1Axis
                            .tickFormat(d3.format(',.2n'));

                        chart.bars.forceY([0]);
                        d3.select(element[0]).select(".word-history .svg-container svg").remove();
                        var svg = d3.select(element[0]).select(".word-history .svg-container").append("svg");
                        svg
                            .attr("class", "word-history main-svg")
                            .attr("width", scope.size[0])
                            .attr("height", scope.size[1])//mogoče napaka
                            .datum(scope.history)
                            .transition()
                            .duration(500)
                            .call(chart);

                        scope.loading = false;
                        scope.$apply();
                        scope.graph = chart;
                        setYAxis(scope.graph);
                        scope.graph.update();

                        // chart.services.dispatch.on('legendClick', function(d,i) {
                        //     if (!d.disabled) return;
                        //
                        //     switch (d.key) {
                        //         case 'Files':
                        //             scope.showFreq = false;
                        //             chart.isFreq.isEnabled = false;
                        //             chart.y(function(dx) {
                        //                 return dx.files;
                        //             });
                        //             break;
                        //         case 'Frequency':
                        //             scope.showFreq = true;
                        //             chart.isFreq.isEnabled = true;
                        //             chart.y(function(dx) {
                        //                 return dx.freq;
                        //             });
                        //             break;
                        //     }
                        //     chart.update();
                        // });
                        return chart;
                    });
                };

                $timeout(function () {
                    loadAndfilterKeywords2Words();
                }, 200);

                scope.$watch("service.keywords", function (nval, oval) {
                    if (scope.keywords && nval && nval.data) {
                        loadAndfilterKeywords2Words();
                    }
                }, true);

                scope.$watch("showFreq", function (nval, oval) {
                    if(scope.graph){
                        scope.graph.update();
                    }
                }, true);

                scope.$watch("showPercentage", function (nval, oval) {
                    if(scope.graph){
                        setYAxis(scope.graph);
                        scope.graph.update();
                    }
                }, true);

                scope.$watch("showRelNorm", function (nval, oval) {
                    if(scope.graph){
                        scope.graph.update();
                    }
                }, true);

                var yearTimer;
                scope.$watch("yearRange[0]", function (nval, oval) {
                    $timeout.cancel(yearTimer);
                    if(nval && nval !== oval){
                        yearTimer = $timeout(scope.getVocabularyHistory, 600);
                    }
                });

                function loadAndfilterKeywords2Words() {
                    var keywordValues = _.filter(scope.service.keywords.data, function(item){
                        return item.source.short === 'w'; // only words
                    });

                    var kw = _.map(keywordValues, function (k) {
                        return k.word;
                    });

                    var _kw = scope.keywords;
                    scope.keywords = kw;

                    if (_kw && _kw.length !== kw.length) {
                        // load vocabulary only on words change
                        scope.getVocabularyHistory();
                    }

                    if(!_kw) {
                        // load vocabulary are no keywords
                        scope.getVocabularyHistory();
                    }

                }


                // --------------
                // --- RESIZE ---
                // --------------
                var resizeOff = scope.$on("resize", function (event, args) {
                    if(scope.graph !== undefined){
                        scope.graph.update();
                    }
                });

                scope.$on("$destroy", function () {
                    resizeOff();
                    element.remove();
                });

                scope.export = function ($event) {
                    var desc = (scope.showFreq? "type: frequency":"type: files")+
                        ", percentage: "+scope.showPercentage+
                        ", rel. norm: "+scope.showRelNorm;
                    var settings = {
                        svg: $("#" + scope.id + " svg.word-history"),
                        description: desc
                    };
                    ExportService.exportDialog("word-details",settings,$event);
                };

            }
        };
    }]);