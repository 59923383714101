
app.controller('editOrganizationCtrl', ['$scope', 'currentOrganization', 'cases', 'channels', 'Toast', '$mdDialog',
    'config', 'ThriftHelper', '$rootScope', 'Session', '$q',
    function ($scope, currentOrganization, cases, channels, Toast, $mdDialog,
            config, ThriftHelper, $rootScope, Session, $q) {

        $scope.form = {};
        $scope.isAdd = currentOrganization === null;
        $scope.title = $scope.isAdd ? "Add organization" : "Edit organization";
        $scope.cases = {
            all: _.sortBy(cases, 'name'),
            selectedItem: null,
            selectedCases: [],
            searchText: null
        };
        $scope.cases.all.map(function (item) {
            item._lowername = item.name.toLowerCase();
            item._lowerkeyword = item.keyword.toLowerCase();
            return item;
        });
        //console.log($scope.cases.all);
        function createFilterForCases(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(item) {
                return (item._lowername.indexOf(lowercaseQuery) === 0) ||
                        (item._lowerkeyword.indexOf(lowercaseQuery) === 0);
            };
        }

        $scope.cases.querySearch = function (query) {
            var results = query ? $scope.cases.all.filter(createFilterForCases(query)) : $scope.cases.all;
            //var deferred = $q.defer();
            //deferred.resolve(results);
            //return deferred.promise;
            return results;
        };

        $scope.channels = {
            all: _.sortBy(channels, 'name'),
            selectedItem: null,
            selectedChannels: [],
            searchText: null
        };
        $scope.channels.all.map(function (item) {
            item._lowername = item.name.toLowerCase();
            return item;
        });

        function createFilterForChannels(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(item) {
                return (item._lowername.indexOf(lowercaseQuery) === 0);
            };
        }

        $scope.channels.querySearch = function (query) {
            var results = query ? $scope.channels.all.filter(createFilterForChannels(query)) : $scope.channels.all;
            return results;
        };

        if ($scope.isAdd) {
            $scope.organization = {
                cases: [],
                twitterChannels: []
            };
        } else {
            $scope.organization = currentOrganization;
            if ($scope.organization.cases === null)
                $scope.organization.cases = [];
            if ($scope.organization.twitterChannels === null)
                $scope.organization.twitterChannels = [];
        }
        $scope.cases.selectedCases = _.filter($scope.cases.all, function (item) {
            return _.contains($scope.organization.cases, item.id);
        });
        $scope.channels.selectedChannels = _.filter($scope.channels.all, function (item) {
            return _.contains($scope.organization.twitterChannels, item.id);
        });

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.save = function () {
            if ($scope.form.organization.$valid && $scope.cases.selectedCases.length > 0) {
                $scope.organization.cases = _.pluck($scope.cases.selectedCases, 'id');
                $scope.organization.twitterChannels = _.pluck($scope.channels.selectedChannels, 'id');

                ThriftHelper.sendRequest(new UpdateOrganization({organization: $scope.organization}),
                        MsgType.UPDATE_ORGANIZATION, config.calls.WS_EDIT_ORGANIZATION);
            }
        };

        $scope.$on(config.calls.WS_EDIT_ORGANIZATION, function (event, args) {
            if (args.data === null) {
                if ($scope.isAdd) {
                    Toast.success("Organization was successfully added.");
                } else {
                    Toast.success("Organization was successfully updated.");
                }

                if (!$scope.isAdd && $rootScope.user.organization.id === $scope.organization.id) {
                    Session.set($rootScope.user.simpleUser, $scope.organization,
                        $scope.cases.selectedCases,$scope.channels.selectedChannels,$rootScope.user.customSources,
                        $rootScope.user.uiTemplate);
                }
                $scope.$apply();
                $mdDialog.hide();
            }
        });


    }]);
