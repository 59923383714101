

app.service('scopeService', [ 'ThriftHelper', 'config', '$filter', '$rootScope',
    function (ThriftHelper, config, $filter, $rootScope) {

        var data = {
            scopes: []
        };

        var getScopes = function (orgId) {
            var dto = {
                organizationId: orgId
            };
            ThriftHelper.sendRequest(new GetScopeReq(dto), MsgType.GET_SCOPE_REQ, config.calls.WS_SCOPES);
        };

        var getter = {};

        getter.getTypes = function (types) {
            if(angular.isArray(types)){
                return types.join(', ');
            }
            return '';
        };

        getter.getSources = function (sourceIds, sources) {
            if(angular.isArray(sourceIds)){
                var sArr = [];
                sourceIds.forEach(function (element) {
                    sArr.push(sources[element]);
                });
                return sArr.join(', ');
            }
            return '';
        };

        getter.getTime = function (time) {
            var d = new Date(time);
            return $filter('date')(d, config.format.scopeDate);
        };

        getter.getRange = function (range) {
            var rStr = "";
            if(range.from.type === 'date'){
                rStr = rStr + getter.getTime(new Date(range.from.value));
            }else{
                rStr = rStr + range.from.value;
            }
            rStr = rStr + " - ";
            if(range.to.type === 'date'){
                rStr = rStr + getter.getTime(new Date(range.to.value));
            }else{
                rStr = rStr + range.to.value;
            }
            return rStr;
        };

        $rootScope.$on(config.calls.WS_SCOPES, function (event, args) {
            if (angular.isDefined(args.data.scopes)) {
                data.scopes = args.data.scopes;
            }
            $rootScope.$apply();
        });

        var init = function(){
            if($(".column-header-content:first").length > 0){
                setTimeout(function () {
                    $(".column-header-content:first").click();
                },100);
            }
        };

        return {
            data: data,
            getter: getter,
            getScopes: getScopes,
            init: init
        };
    }]);