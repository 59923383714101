/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


angular.module('percipioApp').filter('cut', [
    function () {
        return function (value, max, width, wordwise, tail) {
            if (!value)
                return '';

            if (!max)
                return value;
            
            if (angular.isNumber(width) && width>0) {
                if (width < 400) {
                    max *= 0.4;
                } else if (width < 600) {
                    max *= 0.6;
                } else if (width < 800) {
                    max *= 0.8;
                }
            }
            max = Math.max(15,max);

            if (value.length <= max)
                return value;

            value = value.substr(0, max);
            if (wordwise!==false) {
                var lastspace = value.lastIndexOf(' ');
                if (lastspace != -1) {
                    value = value.substr(0, lastspace);
                }
            }

            return value + (tail || ' …');
        };
    }]);
