

app.controller('adminOrganizationCtrl', ['$scope', '$state',
    function ($scope, $state) {

        console.log($state.current.name);

        $scope.tabs = [
            {id: 'users', name: 'Users', link: 'app.admin-organization.users'},
            {id: 'ui-templates', name: 'UI Templates', link: 'app.admin-organization.ui-templates'},
            {id: 'sources', name: 'News sources', link: 'app.admin-organization.sources'}
        ];
        $scope.goTo = function(item){
            $state.go(item.link);
        };

        $scope.selectedIndex = _.findIndex($scope.tabs,{link: $state.current.name});

    }]);
