/**
 * Created by matej on 22/01/2017.
 */
app.directive('mdtTriggerRequestFirstPage', function() {
    return {
        require: 'mdtTable',
        restrict: 'A',
        link: function($scope, $element, $attributes, aController) {
            this.getFirstPage = function () {
                aController.mdtPaginationHelper.getFirstPage();
            };
            this.fetchPageAgain = function () {
                aController.mdtPaginationHelper.fetchPage(aController.mdtPaginationHelper.page);
            };
            $scope.getLoadResultsCallbackFirstPage = _.debounce(this.getFirstPage.bind(this), 500);
            $scope.getLoadResultsCallbackFetchPageAgain = _.debounce(this.fetchPageAgain.bind(this), 200);
        }
    }
});