app.controller('listOptionsCtrl', [
    '$scope', 'documentDetailId',
    function ($scope, documentDetailId) {
        $scope.$on('onDocumentDetailsIdsChanged', function (event) {
            // called when Document Details has changed on Layout panel
            onDocumentDetailsIdsChanged();
        });

        // load right away
        onDocumentDetailsIdsChanged();

        function onDocumentDetailsIdsChanged() {
            // first converts layout to drop down items
            $scope.detailsViews = documentDetailId.layout2items($scope.$parent.layout);

            // gets proper selection
            $scope.widget.options.detailsViewId = documentDetailId.getSelection($scope.widget.options.detailsViewId, $scope.detailsViews);
        }
    }
]);