/**
 * Created by matej on 13/01/2017.
 */

app.directive("minigraph", ['$window','config',
    function ($window, config) {
        return {
            restrict: "E",
            replace: false,
            scope: {
                id: '=sourceId',
                type: '=sourceType',
                stats: '=data'
            },
            link: function (scope, element, attrs) {
                scope.row = {};
                scope.isDrawn = false;

                var chartEl = d3.select(element[0]);
                var chart = nv.models.lineChart()
                        .margin({
                            left: 20,
                            top:10,
                            bottom:10
                        })
                        .useInteractiveGuideline(true)
                        .showLegend(false)
                        .showYAxis(true)
                        .showXAxis(false)
                        .options({
                            duration: 700
                        });
                chart.xAxis
                    .tickFormat(function(d) {
                        return d3.time.format(config.format.d3Date)(new Date(d))
                    });

                chart.yAxis
                    .tickFormat(d3.format(',.0f'));

                scope.drawGraph = function () {
                    nv.addGraph(function() {
                        chartEl.append('svg')
                            .datum(scope.data)
                            .call(chart);

                        angular.element($window).bind('resize', function(){
                            chart.update();
                            scope.$digest();
                        });

                        return chart;
                    });
                };

                scope.$watch(function(){
                    if(scope.stats === undefined)
                        return undefined;
                    return scope.stats[scope.id];
                }, function(newVal){
                    if(newVal !== undefined && newVal.stats.length > 0 && !scope.isDrawn){
                        scope.isDrawn = true;
                        scope.row = newVal.row;
                        scope.data = arrangeData(newVal.stats);
                        scope.drawGraph();
                    }
                });

                function arrangeData(days) {
                    var values = [];
                    var start = days.length > 100?  days.length-100: 0;
                    for (var i = start; i < days.length; i++) {
                        values.push({x: new Date(days[i].day), y: days[i].docs });
                    }
                    return [{
                            values: values,
                            key: '',
                            color: 'rgb(255,87,34)'
                        }];
                }
            }
        };
    }]);