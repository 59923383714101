app.service('detailIdGenerator', [
    function () {
        var zeroItem = 'None';

        var thiz = this;
        var noItemsText = 'None (No view added to layout)';
        var prefix = "Details view ";

        /**
         * Generates ID for all Details View widgets in specified dashboard.
         *
         * @param layout
         */
        thiz.generateId = function (dashboard) {
            var counter = 0;

            if(angular.isArray(dashboard.columns)) {
                // automatically assign sequential number as ID to each Document Details View
                dashboard.columns.forEach(function (col) {
                    col.widgets.forEach(function (widget) {
                        if (widget.type === WidgetType.DETAILS) {
                            counter++;
                            widget.detailsId = counter;
                        } else if (widget.type === WidgetType.MULTI_WIDGET) {
                            // in case of multiwidget we would like to handle also "sub-widgets"
                            widget.widgets.forEach(function (item) {
                                if (widget.type === WidgetType.DETAILS) {
                                    counter++;
                                    item.detailsId = counter;
                                }
                            });
                        }
                    });
                });
            }
        };

        /**
         * Searches for Details View components in dashboard and prepares array of items {id, text} that can be
         * used in drop down components (e.g. md-select and md-option).
         *
         * @param dashboard
         */
        thiz.dashboard2items = function (dashboard) {
            var items = [{
                id: 0,
                text: zeroItem
            }];

            if(angular.isArray(dashboard.columns)) {
                // search for all IDs to build up options that can be used for drop down
                dashboard.columns.forEach(function (col) {
                    col.widgets.forEach(function (widget) {
                        if (widget.type === WidgetType.DETAILS) {
                            items.push({
                                id: widget.detailsId,
                                text: prefix + widget.detailsId
                            });
                        } else if (widget.type === WidgetType.MULTI_WIDGET) {
                            // in case of multiwidget we would like to handle also "sub-widgets"
                            widget.widgets.forEach(function (item) {
                                if (item.type === WidgetType.DETAILS) {
                                    items.push({
                                        id: item.detailsId,
                                        text: prefix + item.detailsId
                                    });
                                }
                            });
                        }
                    });
                });
            }

            if (items.length === 1) {
                // there is no any document details view in Layouts
                items[0].text = noItemsText;
            }

            return items;
        };

        /**
         * Gets the selection based on selected item ID and array of items.
         * <p>
         *     In case if item id doesn't fit into specified items array, item on zero index will be selected.
         * </p>
         * @param selectedItemId
         * @param items
         * @return {number}
         */
        thiz.getSelection = function (selectedItemId, items) {
            // we would like to select "None" at index 0 by default
            var selection = selectedItemId ? selectedItemId : 0;
            if (selection >= items.length) {
                selection = 0;
            }
            return selection;
        };

        return thiz;
    }
]);