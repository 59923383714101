
/* global angular, app */

app.factory('TreemapModel', ['config',
    function(config) {

        var cloudSize = config.wordCloud.cloudSize;
        var normalizeFreq = 1;
        var normalizeCorr = config.wordCloud.maxCorrelation;
        /**
         * Create new TreemapModel.
         * @param object
         * @constructor
         */
        function TreemapModel(object) {
            this.all = object;
            setLocalRanks(this.all);
            setGlobalRanks(this.all);
            this.current = [];
            normalizeFreq = this.all.length;
            this.newData = false;
            this.name = "TreemapModel";
            this.sum = 0;
        }

        TreemapModel.prototype.setCurrentTreemap = function(freqParam,corrParam, keywords) {
            var arr = angular.copy(this.all);
            //first remove keywords in query
            var kws = _.map(keywords, function (item) {
                return item.word;
            });
            arr = _.filter(arr, function (item) {
                return !_.contains(kws, item.keyword);
            });
            for(var i in arr){
                arr[i].score = getScore(arr[i],freqParam,corrParam);
            }
            // sort by score to get best #cloudSize keywords
            arr.sort(function(a, b){ return b.score-a.score;});
            this.current = arr.slice(0,Math.min(cloudSize,this.all.length));

            //calculate actual score of keywords
            arrangeSize(this.current);
            var sum = 0;
            for(var i = 0; i < this.current.length; i++){
                sum = sum + this.current[i].score;
            }
            this.sum = sum;
                // add blank childrens keywords
            // names and scores will be added later
            addBlankes(this.current);
            return {
                "keyword": "masterNode",
                "score": sum,
                "children": this.current
            };
        };

        TreemapModel.prototype.getCurrentTreemap = function() {
            this.newData = false;
            return {
                "keyword": "masterNode",
                "score": this.sum,
                "children": this.current
            };
        };

        TreemapModel.prototype.hasNewData = function() {
            return this.newData;
        };

        TreemapModel.prototype.setCurrentTreemapUpdate = function(freqParam,corrParam, object, keyword, keywords) {
            this.newData = true;
            var key = this.current.filter(function (k) {
                return k.keyword === keyword;
            });
            if(key.length == 0)
                return;
            key = key[0];

            var kws = _.map(keywords, function (item) {
                return item.word;
            });
            var arr = _.filter(object, function (item) {
                return !_.contains(kws, item.keyword) && item.keyword !== keyword;
            });
            for(var i in arr){
                arr[i].score = getScore(arr[i],freqParam,corrParam);
            }
            // sort by score to get best #config.treemap.chindlenSize keywords
            arr.sort(function(a, b){ return b.score-a.score;});
            arr = arr.slice(0,Math.min(config.treemap.chindlenSize,this.all.length));
            arrangeSize(arr);

            var sum = 0;
            for(var i = 0; i < arr.length; i++){
                sum = sum + arr[i].score;
            }

            for(var i = 0; i < key.children.length; i++){
                key.children[i].keyword = arr[i].keyword;
                key.children[i].score = key.score * (arr[i].score / sum);
            }

            return {
                "keyword": "masterNode",
                "children": this.current
            };
        };

        TreemapModel.prototype.clone = function() {
            var cloned = angular.copy(this);
            delete cloned.$$hashKey;
            return cloned;
        };
        
        var getScore = function(item,freqParam,corrParam) {
            if(corrParam===-1){
                return item.freq;
            }
            return getFreqScore(item,freqParam)/normalizeFreq + getCorrScore(item,corrParam)/normalizeCorr;
        };
        
        var getFreqScore = function(item,freqParam) {
            return freqParam*item.gRank + (1-freqParam)*item.lRank;
        };
        
        var getCorrScore = function(item,corrParam) {
            return corrParam*Math.min(item.corr,normalizeCorr);
        };
        
        var setGlobalRanks = function(data) {
            data.sort(function(a, b){ return a.freqGlobal-b.freqGlobal;});
            for(var i in data){
                data[i].gRank = data.length - parseInt(i);
            }
        };
        var setLocalRanks = function(data) {
            data.sort(function(a, b){ return b.freq-a.freq;});
            for(var i in data){
                data[i].lRank = data.length - parseInt(i);
            }
        };

        function arrangeSize(data) {
            var max = _.max(data, function (item) {
                return item.freq;
            }).freq;
            var min = _.min(data, function (item) {
                return item.freq;
            }).freq;
            for (var i = 0; i < data.length; i++) {
                data[i].score = (data[i].freq - min) / (max - min) + 0.01;
            }
        }

        function addBlankes(data) {
            for(var i = 0; i < data.length; i++){
                var children = [];
                for(var j = 0; j < config.treemap.chindlenSize; j++){
                    children.push({
                        index: j,
                        keyword: "",
                        score: data[i].score/config.treemap.chindlenSize
                    });
                    data[i].children = children;
                }
            }
        }

        // Return constructor
        return TreemapModel;
    }
]);