
app.controller('adminSourcesCtrl', ['$scope', 'adminService', '$q', 'config', '$mdDialog',
    function ($scope, adminService, $q, config, $mdDialog) {

        //public variables
        $scope.sources = [];
        $scope.sourcesStats = {};
        $scope.total = 0;
        $scope.getter = adminService.getter;
        $scope.filterText = "";
        $scope.sourceType = "News";
        $scope.sourceTypes = ["All", "News", "Patent", "Articles", "Wiki"];
        $scope.tableColumns = ['name', 'desc', 'keywords', 'lang', 'website', 'trusted', 'stat', 'id'];
        $scope.getLoadResultsCallbackFirstPage; // handled by mdtTriggerRequestFirstPage directive
        //private variables
        var deferred = $q.defer();

        $scope.paginatorCallback = function(page, pageSize, options){
            if(!page)
                page = 1;
            deferred = $q.defer();
            adminService.getSources(page, pageSize, options, $scope.tableColumns, $scope.filterText, $scope.sourceType.toLowerCase());
            return deferred.promise;
        };

        var firstFilterCall = true;
        $scope.$watch('filterText', function(newVal, oldVal){
            if(!firstFilterCall){
                $scope.getLoadResultsCallbackFirstPage();
            }
            firstFilterCall = false;
        });

        var firstSelectCall = true;
        $scope.$watch('sourceType', function(newVal, oldVal){
            if(!firstSelectCall){
                $scope.getLoadResultsCallbackFirstPage();
            }
            firstSelectCall = false;
        });

        $scope.$on(config.calls.WS_ADMIN_SOURCES, function (event, args) {
            if (angular.isDefined(args.data.sources)) {
                $scope.sources = args.data.sources;
                $scope.total = args.data.total;
                arrangeData();
                adminService.getSource($scope.sourceType.toLowerCase(), getIds(), config.calls.WS_ADMIN_SOURCE_STAT);
            }
            $scope.$apply();
        });

        function arrangeData(){
            $scope.sources.forEach(function (source) {
                source.stat = source.id;
            });
            deferred.resolve({
                results:  $scope.sources,
                totalResultCount: $scope.total
            });
        }

        function getIds() {
            var ids = [];
            $scope.sources.forEach(function (source) {
                ids.push(source.id);
            });
            return ids;
        }
        $scope.$on(config.calls.WS_ADMIN_SOURCE_STAT, function (event, args) {
            if(args.data.stats.length > 0){
                arrangeStatsData(args.data.stats);
                $scope.$apply();
            }
        });

        function arrangeStatsData(stats) {
            $scope.sourcesStats = {};
            stats.forEach(function (stat) {
                var obj = {};
                obj.row = _.find($scope.sources, function(item){
                    return item.id === stat.id;
                });
                obj.stats = stat.days;
                $scope.sourcesStats[stat.id] = obj;
            });
        }


        $scope.editSource = function (row, ev) {
            if(row){
                row = _.find($scope.sources, function(item){
                    return item.id === row;
                })
            }
            $mdDialog.show({
                controller: 'editSourceCtrl',
                templateUrl: 'app/module/admin/sources/editSource.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                multiple: true,
                clickOutsideToClose: false,
                locals: {
                    currentSource: angular.copy(row),
                    addToOrganization: false,
                    fromAdmin: true
                }
            })
                .then(function (answer) {
                    $scope.getLoadResultsCallbackFirstPage();
                });
        };

    }]);
