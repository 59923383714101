
app.controller('adminCasesCtrl', ['$scope', 'adminService', '$mdDialog',
    function ($scope, adminService, $mdDialog) {

        $scope.cases = adminService.data.cases;
        $scope.getter = adminService.getter;
        $scope.init = adminService.init;
        adminService.getCases();

        $scope.$watch(function(){ return adminService.data.cases;}, function(newVal){
            $scope.cases = newVal;
        });

        $scope.editCase = function (row, ev) {
            if(row){
                row = _.find($scope.cases, function(item){
                    return item.id === row;
                })
            }
            $mdDialog.show({
                controller: 'editCaseCtrl',
                templateUrl: 'app/module/admin/cases/editCase.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {
                    currentCase: angular.copy(row)
                }
            })
                .then(function (answer) {
                    adminService.getCases();
                });
        };
    }]);
