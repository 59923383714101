/**
 * Created by matejk on 2.10.2014.
 */


app.directive("treemap", ["$window", '$timeout', 'Session', 'config', 'TreemapModel', 'ThriftHelper', '$interval',
    'ExportService','DashboardService',
    function ($window, $timeout, Session, config, TreemapModel, ThriftHelper, $interval,
              ExportService,DashboardService) {
    return {
        restrict: "E",
        replace: true,
        templateUrl: 'app/directives/treemap/treemap.html',
        scope:{
            options: "="
        },
        link: function(scope, element){
            scope.service = DashboardService;
            scope.widgetValues = DashboardService.sharedValues.cloud;
            scope.id = "treemap-" + UUID.generate();
            scope.loading = false;
            scope.case = Session.get().currentCase.name;
            var headerHeight = 30;
            scope.width = element.width();
            scope.height = element.height() - headerHeight;
            scope.settings = config.wordCloud;
            scope.data = {};
            scope.keywordMap = {};
            var div = d3.select(element[0]);
            var updateInterval;

            var minFontSize = 12,
                maxFontSize = 22,
                percentTextSizeReduction = 2;

            scope.keyword = undefined;

            var cc = ["#6baed6", "#fd8d3c", "#74c476", "#9e9ac8","#D66011", "#969696",
                "#9ecae1", "#fdae6b", "#a1d99b", "#bcbddc", "#bdbdbd",
                "#c6dbef", "#fdd0a2", "#c7e9c0", "#dadaeb", "#d9d9d9"];
            var x, y,
                color = d3.scale.ordinal().range(cc),
                root,
                node,
                treemap,
                svg,
                cell,
                parents,
                masterNode,
                freqVal,
                corrVal;

            scope.drawTreeMap = function() {
                if (_.isUndefined(scope.data)) {
                    return scope.getKeywords();
                } else if (scope.data.all.length === 0) {
                    scope.loading = false;
                    return;
                }
                //when new query, the whole treemap is redrawn
                d3.select("#" + scope.id + " svg.treemap").remove();
                scope.loading = true;

                scope.updateSize();
                if(scope.width === 0 || scope.height === 0){
                    return;
                }
                div = d3.select("#" + scope.id + " .svg-container");

                scope.updateFreqCorrVals();

                node = root = scope.data.setCurrentTreemap(freqVal, corrVal, scope.service.keywords.data);
                scope.getKeywordsSecondLevel(root);

                treemap = d3.layout.treemap()
                    .ratio(2.3)
                    .round(false)
                    .size([scope.width, scope.height])
                    .sticky(false) //this must be set for treemap transitions to work
                    .sort(function(a, b) {return a.score - b.score; }) //sort for left to right top to bottom look
                    .value(function(d) {return d.score;})
                    .round(false);

                svg = div.append("svg:svg")
                    .attr("class", "treemap main-svg")
                    .attr("width", scope.width)
                    .attr("height", scope.height)
                    .append("svg:g")
                    .attr("width", scope.width)
                    .attr("height", scope.height)
                    .attr("transform", "translate(.5,.5)");
                console.log("SVG",svg);

                var nodes = treemap.nodes(root)
                    .filter(function(d) { return !d.children; });

                var shouldCallClick = false;

                masterNode = svg.selectAll("g");

                cell = masterNode.data(nodes)
                    .enter().append("svg:g")
                    .attr("class", "cell")
                    .attr("transform", function(d) { return "translate(" + d.x + "," + d.y + ")"; });

                cell.append("svg:rect")
                    .attr("width", function(d) { return d.dx - 1 > 0 ? d.dx - 1 : 0; })
                    .attr("height", function(d) { return d.dy - 1 > 0 ? d.dy - 1 : 0; })
                    .attr("shape-rendering","crispEdges")
                    .style("fill", function(d) {
                        d.parent.hexColor = color(d.parent.keyword); //used for text determination
                        if(d.index < config.treemap.chindlenSize/2){
                            return d3.hsl(d.parent.hexColor).darker((config.treemap.chindlenSize/2 - d.index -1)  * 0.3);
                        }else{
                            return d3.hsl(d.parent.hexColor).brighter((d.index - config.treemap.chindlenSize/2 + 1)  * 0.15);
                        }})
                    .on("mouseover", function(d) {
                        d3plus.tooltip.create({
                            "title": "" + d.parent.keyword + " (" + getScoreStr(d.parent) + ")",
                            "color": d.parent.hexColor,
                            "id": "id1",
                            "x": d3.event.clientX,
                            "y": d3.event.clientY,
                            "offset": 3,
                            "arrow": true,
                            "footer": "",
                            "data": [],
                            "html": generateTooltipContent(d)
                        });
                        scope.tooltipTimeout = $timeout(function () {
                            d3plus.tooltip.remove("id1");
                        }, 1000*10);
                    })
                    .on("mousemove",function(d){
                        d3plus.tooltip.move(d3.event.clientX,d3.event.clientY, "id1");
                        $timeout.cancel(scope.tooltipTimeout);
                        scope.tooltipTimeout = $timeout(function () {
                            d3plus.tooltip.remove("id1");
                        }, 1000*10);
                    })
                    .on("mouseout",function(d){
                        d3plus.tooltip.remove("id1");
                        $timeout.cancel(scope.tooltipTimeout);
                    })
                    .on("click", function(d) {
                        d = d.parent;
                        shouldCallClick = true;
                        var evt = d3.event;
                        setTimeout(function () { // timeout because of double click
                            if(shouldCallClick)
                                zoom(node == d ? root : d, evt);
                        },200);
                    })
                    .on("dblclick", function(d) {d = d.parent; shouldCallClick = false; if (d.keyword) { scope.service.onWordClick(d.keyword); }});

                cell.append("svg:text")
                    .attr("class", "childname")
                    .attr("x", function(d) { return d.dx; })
                    .attr("y", function(d) { return d.dy - 2; })
                    .attr("text-anchor", "end")
                    .style("font-size", function (d) { d.fSize = 1; return "1px"; })
                    .style("pointer-events","none")
                    .text(function(d) { return d.keyword; })
                    .style("opacity", function() {return 0;})
                    .style("fill", function(d) { return text_color(d.parent.hexColor); });

                cell.append("svg:text")
                    .attr("class", "childscore")
                    .attr("x", function(d) { return d.dx; })
                    .attr("y", function(d) { return d.dy - 2 - d.fSize; })
                    .attr("text-anchor", "end")
                    .style("font-size", function (d) { d.fSize = 1; return "1px"; })
                    .style("pointer-events","none")
                    .text(function(d) { return getScoreStr(d); })
                    .style("opacity", function() {return 0;})
                    .style("fill", function(d) {return text_color(d.parent.hexColor); });

                parents = masterNode.data(root.children)
                    .enter().append("svg:g")
                    .attr("class", "parent")
                    .attr("transform", function(d) { return "translate(" + d.x + "," + d.y + ")"; })

                parents.append("svg:rect")
                    .attr("width", function(d) { return d.dx - 1 > 0 ? d.dx - 1 : 0; })
                    .attr("height", function(d) { return d.dy - 1 > 0 ? d.dy - 1 : 0; })
                    .attr("shape-rendering","crispEdges")
                    .style("fill", function() { return "rgba(0,0,0,0)"; });

                parents.append("svg:text")
                    .attr("class", "keyname")
                    .attr("x", function(d) { return 0; })
                    .attr("y", function(d) { return 0; })
                    .attr("dy", "20px")
                    .attr("dx", "3px")
                    .text(function(d) { return d.keyword; })
                    .style("pointer-events","none")
                    .style("font-size", function (d) { d.fSize = 20; return "20px"; })
                    .style("font-size", function(d) { setFontSize(d, this,1,1,0); return d.fSize + "px"; })
                    .attr("text-anchor", "start")
                    .style("opacity", function(d) {if(d.fSize === 1)return 0;else return 1;})
                    .style("fill", function(d) { return text_color(d.hexColor); });

                parents.append("svg:text")
                    .attr("class", "keyscore")
                    .attr("x", function(d) { return d.dx / 2; })
                    .attr("y", function(d) { return d.dy - 2; })
                    .text(function(d) { return getScoreStr(d); })
                    .style("pointer-events","none")
                    .style("font-size", function (d) { return d.fsize - percentTextSizeReduction + "px"; })
                    .attr("text-anchor", "middle")
                    .style("opacity", function(d) {
                        if( d.fSize === 1) return 0;
                        else{
                            if (d.fSize >= d.dy/2 - 5){
                                return 0; // to še bo treba posebej poračunati
                            }
                            return 1;
                        }
                    })
                    .style("fill", function(d) {return text_color(d.hexColor);});

                scope.loading = false;
                scope.$apply();
            };

            function zoom(d, event) {
                var kx = scope.width / d.dx, ky = scope.height / d.dy;
                x.domain([d.x, d.x + d.dx]);
                y.domain([d.y, d.y + d.dy]);

                var t = svg.selectAll("g.cell").transition()
                    .duration(event.altKey ? 3000 : 700)
                    .attr("transform", function(d) { return "translate(" + x(d.x) + "," + y(d.y) + ")"; });

                t.select("rect")
                    .attr("width", function(d) { return kx * d.dx - 1 > 0 ? kx * d.dx - 1 : 0; })
                    .attr("height", function(d) { return ky * d.dy - 1 > 0 ? ky * d.dy - 1: 0; });

                t.select("text.childname")
                    .attr("x", function(d) { return kx * d.dx - 4; })
                    .text(function(d) { return d.keyword; })
                    .style("font-size", function (node) {
                        if(d.depth === 0){
                            node.fSize = 1; return "1px";
                        }
                        else{
                            setFontSize(node,this,kx, ky, d.depth);
                            return node.fSize + "px";
                        }
                    })
                    .attr("y", function(d) {
                        return Math.max(ky * d.dy - 2 - 18, d.fSize - 2); })
                    .style("opacity", function (node) {
                        if(d.depth === 0)
                            return 0;
                        else{
                          if(node.fSize === 1)
                              return 0;
                          return 1;
                        }
                    });

                t.select("text.childscore")
                    .attr("x", function(d) { return kx * d.dx-4; })
                    .attr("y", function(d) { return ky * d.dy - 4; })
                    .text(function(d) { return getScoreStr(d); })
                    .style("font-size", function (node) {
                        if(node.depth === 0)
                            return "1px";
                        else{
                            if(ky * d.dy - 2 - 18 < d.fSize - 2)
                                return "1px";
                            return node.fSize - 2 + "px";
                        };
                    })
                    .style("opacity", function (node) {
                        if(d.depth === 0)
                            return 0;
                        else {
                            if(node.fSize === 1 || ky * node.dy < 2 * node.fSize + 5)
                                return 0;
                            return 1;
                        }
                    });

                t = svg.selectAll("g.parent").transition()
                    .duration(event.altKey ? 3000 : 700)
                    .attr("transform", function(d) { return "translate(" + x(d.x) + "," + y(d.y) + ")"; });

                t.select("rect")
                    .attr("width", function(d) { return kx * d.dx - 1 > 0 ? kx * d.dx - 1 : 0; })
                    .attr("height", function(d) { return ky * d.dy - 1 > 0 ? ky * d.dy - 1: 0; });

                t.select("text.keyname")
                    .attr("dy", function(d) { return Math.min(ky * d.dy-3, 20) + "px";})
                    .style("font-size", function(node) { setFontSize(node, this, kx, ky, d.depth); return node.fSize + "px"; })
                    .style("opacity", function(d) {if(d.fSize === 1)return 0; else return 1;});

                t.select("text.keyscore")
                    .attr("x", function(node) {
                        if(d.depth === 1)
                            return 3;
                        return kx * node.dx / 2; })
                    .attr("y", function(node) {
                        if(d.depth === 1){
                            return 2 * maxFontSize;
                        }
                        return ky * node.dy - 3 ; })
                    .attr("text-anchor", function () {
                        if(d.depth === 1)
                            return "start";
                        return "middle"
                    })
                    .style("font-size", function(d) {
                        return (d.fSize-2) + "px";
                    })
                    .style("opacity", function(d) {
                        if( d.fSize === 1) return 0;
                        else{
                            if (d.fSize + 20 >= ky * d.dy){
                                return 0; // to še bo treba posebej poračunati
                            }
                            return 1;
                        }
                    });

                node = d;
                if(event.type)
                    event.stopPropagation();
            }

            var resizeOff = scope.$on("resize", function() {
                scope.updateSize();
                if(scope.width === 0 || scope.height === 0){
                    return;
                }
                d3.select("#" + scope.id + " svg.treemap").attr("width", scope.width)
                    .attr("height", scope.height);
                treemap.size([scope.width, scope.height]).nodes(root);
                zoom(node,{})
            });

            scope.$on("$destroy", function () {
                resizeOff();
                element.remove();
            });

            var getCall = function () {
                return config.calls.WS_WORD_CLOUD + "-" + scope.id;
            };

            scope.getKeywords = function () {
                var dto = {};
                dto.organization = Session.get().currentCase.name;
                dto.size = scope.settings.size;

                // get only words
                var words = _.filter(scope.service.keywords.data, function (item) {
                    return item.source.short === 'w';
                });

                if (words.length === 0) {
                    dto.value = scope.widgetValues.normal;
                    dto.size = scope.settings.cloudSize;
                    scope.keyword = undefined;
                } else {
                    dto.keyword = words[words.length - 1].word;
                    dto.size = scope.settings.searchSize;
                    scope.keyword = dto.keyword;
                }

                d3.select("#" + scope.id + " svg.treemap").remove();
                scope.loading = true;

                ThriftHelper.sendRequest(new SearchOrganizationReq(dto),
                    MsgType.SEARCH_ORGANIZATION_REQ, getCall());

            };


            scope.getKeywordsSecondLevel = function () {
                //no loading, data will be updated in the background
                scope.keywordMap = {};
                for(var i = 0; i < root.children.length;i++){ //go over all treemap elements
                    var dto = {};
                    dto.organization = Session.get().currentCase.name;
                    dto.size = scope.settings.cloudSize;
                    dto.keyword = root.children[i].keyword;
                    var reqId = ThriftHelper.sendRequest(new SearchOrganizationReq(dto), MsgType.SEARCH_ORGANIZATION_REQ, getCall()+ "-2level");
                    scope.keywordMap[reqId] = root.children[i].keyword;
                }
                updateInterval = $interval(updateTreemapData, 500);
            };

            scope.$on(getCall(), function (event, args) {
                scope.data = new TreemapModel(args.data.keywords);
                scope.drawTreeMap();
            });

            scope.$on(getCall()+ "-2level", function (event, args) {
                scope.data.setCurrentTreemapUpdate(freqVal, corrVal, args.data.keywords, scope.keywordMap[args.reqId], scope.service.keywords.data);
            });

            scope.$watch("service.keywords", function (nval, oval) {
                if (nval && nval.data && oval && oval.data) {
                    var ow = _.filter(oval.data, function (item) {
                        return item.source.short === 'w';
                    });
                    var nw = _.filter(nval.data, function (item) {
                        return item.source.short === 'w';
                    });
                    if((ow.length === 0 && nw.length > 0) || (nw.length === 0 && ow.length > 0)){
                        scope.getKeywords();
                    }else if(ow.length > 0 && nw.length > 0 && ow[ow.length-1].word !== nw[nw.length-1].word){
                        scope.getKeywords();
                    }
                }
            }, true);

            var sliderTimer;
            scope.$watch("widgetValues", function (nval, oval) {
                $timeout.cancel(sliderTimer);
                if (nval !== oval && !_.isUndefined(nval.normal)) {
                    var fun;
                    if (nval.normal !== oval.normal) {
                        fun = function () {
                            scope.getKeywords();
                        };
                    } else {
                        fun = function () {
                            scope.drawTreeMap();  //poglej če to lahko z zoom rešiš
                        };
                    }
                    sliderTimer = $timeout(fun, 400);
                }
            }, true);

            $timeout(function () {
                scope.getKeywords();
            }, 200);

            var updateTreemapData = function() {
                if(!scope.data.name)
                    return;
                if(scope.data.hasNewData()){
                    node = root = scope.data.getCurrentTreemap();
                    treemap.size([scope.width, scope.height]).nodes(root);
                    zoom(node,{})
                }else{
                    $timeout(function () {
                        zoom(node,{}); //call zoom one more time
                    },500);

                    $interval.cancel(updateInterval);
                }
            };

            scope.updateSize = function () {
                scope.width = element.width()-1;
                scope.height = element.height()-1-headerHeight;
                x = d3.scale.linear().range([0, scope.width]);
                y = d3.scale.linear().range([0, scope.height]);
            };

            scope.updateFreqCorrVals = function () {
                if (scope.service.keywords.isEmpty()) {
                    freqVal = scope.widgetValues.normal / 100;
                    corrVal = -1;
                } else {
                    freqVal = scope.widgetValues.rare / 100;
                    corrVal = scope.widgetValues.corr / 100;
                }
            };

            var getComputedTextOpacity =  function(obj, d, factor){
                d.w = obj.getComputedTextLength();
                return factor * d.dx > d.w ? 1 : 0;
            };

            function setFontSize(d,obj, kx, ky, depth) {
                var size;
                if(depth === 0)
                    size = Math.min(ky * d.dy - 7, ((kx * d.dx - 1) - 10) / obj.getComputedTextLength() * d.fSize, maxFontSize, d.score * (maxFontSize - minFontSize) + minFontSize);
                else{
                    if(d.depth === 1){
                        size = Math.min(ky * d.dy - 7, ((kx * d.dx - 1) - 10) / obj.getComputedTextLength() * d.fSize, maxFontSize);
                    }else{
                        size = Math.min(ky * d.dy - 7, ((kx * d.dx - 1) - 10) / obj.getComputedTextLength() * d.fSize, maxFontSize, d.score/d.parent.score * (maxFontSize - minFontSize) + minFontSize);
                    }
                }
                if (size < 10) {
                    size = 1;
                }
                d.fSize = size;
            }

            function getScoreStr(d) {
                var score = "";
                if(d.depth === 1){
                    score = d.score/d.parent.score * 100;
                }else{
                    score =  d.score/d.parent.score * 100 ;
                }
                if(score < 0.1)
                    score = score.toFixed(2);
                else if(score < 1)
                    score = score.toFixed(1);
                else
                    score = parseInt(score);
                return score + "%";
            }

            function generateTooltipContent(d) {
                var html = "";
                for(var i = d.parent.children.length - 1; i >= 0; i--){
                    html += "<div class='t_item'>";
                    if(d.parent.children[i] === d){
                        html += "<span class='t_child_name'><b>" + d.parent.children[i].keyword + "</b></span>";
                        html += "<span class='t_child_score'><b>" + getScoreStr(d.parent.children[i]) + "</b></span>";
                    }else{
                        html += "<span class='t_child_name'>" + d.parent.children[i].keyword + "</span>";
                        html += "<span class='t_child_score'>" + getScoreStr(d.parent.children[i]) + "</span>";
                    }
                    html += "</div>";
                }
                return html;
            }

            var text_color = function(color){
                var hsl = d3.hsl(color),
                    light="#fefefe",
                    dark="#333333";
                if(hsl.l>0.65)
                    return dark;
                else if(hsl.l<0.44)
                    return light;
                return hsl.h>35&&hsl.s>=0.3&&hsl.l>=0.41?dark:light;
            };

            scope.export = function ($event) {
                var settings = {
                    description: "case: "+scope.case+", ",
                    svg: $("#" + scope.id + " svg.treemap")
                };
                if(scope.keyword){
                    settings.description+="keyword: "+scope.keyword+", frequent/trendy: "+scope.widgetValues.rare
                        +", correlation: "+scope.widgetValues.corr;
                }else{
                    settings.description+="frequent/trendy: "+scope.widgetValues.rare;
                }
                ExportService.exportDialog("treemap",settings,$event);
            };

        }
    };
}]);