
app.controller('trialDialogCtrl', ['$scope', 'toaster', 'Session',
    '$mdDialog', 'ThriftHelper', 'config',
    function ($scope, toaster, Session,
            $mdDialog, ThriftHelper, config) {

        $scope.form = {};
        $scope.title = "Request Free Trial Account";

        $scope.trial = {
            lastName: "",
//            firstName: "test",
//            organization: "testO",
//            email: "test@test.com"
        };

        $scope.cancel = function () {
            Session.logout();
            $mdDialog.hide();
        };

        $scope.requesting = true;

        $scope.requestClick = function () {
            $scope.requesting = true;
            $scope.error = '';
            console.log($scope.trial);
            ThriftHelper.sendRequest(new SignupReq($scope.trial),
                    MsgType.SIGNUP_REQ, config.calls.WS_TRIAL);
        };

        $scope.$on(config.calls.WS_TRIAL, function (event, args) {
            console.log(args.data.error);
            if(args.data.error !== null){
                toaster.pop("error","Error",args.data.error);
                $scope.getCaptcha();
            }else{
                toaster.pop("success", "Free Trial Account created", "Check your email for password.");
                $scope.cancel();
            }
        });

        $scope.connect = function () {
            var promise = Session.connect();
            if (promise !== false) {
                promise.then(function () {
                    $scope.getCaptcha();
                }, function () {
                });
            } else {
            }
        };
        
        $scope.getCaptcha = function(){
            $scope.requesting = true;
            ThriftHelper.sendRequest(new GetCaptchaReq({}),
                            MsgType.GET_CAPTCHA_REQ, config.calls.WS_CAPTCHA);
        };

        $scope.$on(config.calls.WS_CAPTCHA, function (event, args) {
            if (args.err === false) {
                $scope.trial.captchaId = args.data.captchaId;
                $scope.trial.captchaDate = args.data.captchaDate;
                
                $scope.captcha = args.data.captchaSvg;//.replace('<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">',"");
                
                $scope.requesting = false;
                $scope.$apply();
            }else{
                toaster.pop("error","Captcha Error","Error getting Captcha!");
            }
        });

        $scope.connect();


    }]);


