/**
 * Created by matejk on 2.10.2014.
 */

app.directive("socialText", ["$window", "Session", "$state", '$stateParams', "$rootScope",'$compile',
    function ($window, Session, $state, $stateParams, $rootScope,$compile) {

        var URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
        var HASHTAG_REGEX = /(^|\s)#(\w+)/g;
        var MENTION_REGEX = /(^|\s)@(\w+)/g;

        var tweetTextToHtml = function (text) {
            return text.replace(URL_REGEX,'<a href="$&" target="_blank">$&</a>')
                .replace(MENTION_REGEX,'<a href="https://twitter.com/$2" target="_blank"> @$2</a>')
                .replace(HASHTAG_REGEX,'<a href="" ng-click="hashtagClick(\'$2\')"> #$2</a>');
        };

        return {
            restrict: "E",
            template: '',
            scope: {
                text: '=',
                hashtagClick: '='
            },
            link: function(scope, element, attributes){

                scope.$watch('text', function(newValue, oldValue) {
                    var html = '';
                    if (angular.isString(newValue)) {
                        html = tweetTextToHtml(newValue);
                    }

                    element.html(html);
                    $compile(element.contents())(scope);
                });
            }
        };
    }]);