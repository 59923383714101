/* global d3, app */

/**
 * Created by matejk on 8.10.2014.
 */

app.directive("wordcloud", ["Session", "$state", 'ThriftHelper',
    'config', 'CloudModel', '$window', '$timeout', 'ExportService','DashboardService',
    function (Session, $state, ThriftHelper,
            config, CloudModel, $window, $timeout, ExportService,DashboardService) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/directives/word-cloud/word-cloud.html',
            scope: {
                options: "=",
            },
            link: function (scope, element, attrs) {
                // ------------------
                // --- SCOPE VARS ---
                // ------------------
                scope.service = DashboardService;
                scope.widgetValues = DashboardService.sharedValues.cloud;
                scope.loading = true;
                scope.id = "word-cloud-" + UUID.generate();
                scope.case = Session.get().currentCase.name;
                scope.cloudData = undefined;
                scope.settings = config.wordCloud;
                scope.settings.minFontSize = scope.settings.normalFontSize[0];
                scope.settings.widthFontSize = scope.settings.normalFontSize[1] - scope.settings.normalFontSize[0];
                scope.keyword = undefined;

                if(scope.options){
                    scope.widgetValues.normal = scope.options.freqTrendy;
                    scope.widgetValues.rare = scope.options.freqTrendy;
                    scope.widgetValues.corr = scope.options.corr;
                }

                var getCall = function () {
                    return config.calls.WS_WORD_CLOUD + "-" + scope.id;
                };



                scope.getKeywords = function () {
                    var dto = {};
                    dto.organization = Session.get().currentCase.name;
                    dto.size = scope.settings.size;

                    // get only words
                    var words = _.filter(scope.service.keywords.data, function (item) {
                        return item.source.short === 'w';
                    });

                    if (words.length === 0) {
                        dto.value = scope.widgetValues.normal;
                        dto.size = scope.settings.cloudSize;
                        scope.keyword = undefined;
                    } else {
                        dto.keyword = words[words.length - 1].word;
                        dto.size = scope.settings.searchSize;
                        scope.keyword = dto.keyword;
                    }

                    d3.select("#" + scope.id + " svg.wordcloud").remove();
                    scope.loading = true;

                    ThriftHelper.sendRequest(new SearchOrganizationReq(dto),
                        MsgType.SEARCH_ORGANIZATION_REQ, getCall());

                };

                scope.$on(getCall(), function (event, args) {
                    scope.cloudData = new CloudModel(args.data.keywords);
                    scope.drawWordCloud();
                });

                scope.drawWordCloud = function () { // draw word cloud called after getkeywords
                    if (_.isUndefined(scope.cloudData)) {
                        return scope.getKeywords();
                    } else if (scope.cloudData.all.length === 0) {
                        scope.loading = false;
                        return;
                    }
                    d3.select("#" + scope.id + " svg.wordcloud").remove();
                    scope.loading = true;

                    setSize();
                    var freqVal, corrVal;
                    if (scope.service.keywords.isEmpty()) {
                        freqVal = scope.widgetValues.normal / 100;
                        corrVal = -1;
                    } else {
                        freqVal = scope.widgetValues.rare / 100;
                        corrVal = scope.widgetValues.corr / 100;
                    }
                    var data = scope.cloudData.setCurrentCloud(freqVal, corrVal);

                    var kws = _.map(scope.service.keywords.data, function (item) {
                        return item.word;
                    });
                    data = _.filter(data, function (item) {
                        return !_.contains(kws, item.keyword);
                    });
                    var specialWord = "";
                    if (!scope.service.keywords.isEmpty() && scope.options.showSelectedWord === true) {
                        specialWord = scope.service.keywords.data[scope.service.keywords.data.length - 1].word;
                        data.push({keyword: specialWord, special: true});
                    }
                    scope.arrangeWordcloudSize(data);

                    Math.seedrandom('percipio');
                    d3.layout.cloud().size(scope.size)
                            .words(data)
                            .padding(scope.settings.wordPadding)
                            .rotate(0)
                            //.font("Arial")
                            .fontSize(function (d) {
                                return d.size;
                            })
                            .text(function (d) {
                                return d.keyword;
                            })
                            .on("end", draw)
                            .start();
                    //return;
                    function draw(words) {
                        d3.select("#" + scope.id + " .svg-container").append("svg")
                                .attr("class", "wordcloud main-svg")
                                .attr("width", scope.size[0])
                                .attr("height", scope.size[1])
                                .append("g")
                                .attr("class", "bag")
                                .attr("transform", "translate(" + (scope.size[0] / 2) + "," + (scope.size[1] / 2) + ")")
                                .selectAll("text")
                                .data(words)
                                .enter().append("text")
                                .attr("class", "word")
                                .style("font-size", function (d) {
                                    return d.size + "px";
                                })
                                .attr("text-anchor", "middle")
                                .attr("transform", function (d) {
                                    return "translate(" + [d.x, d.y] + ")";
                                })
                                .text(function (d) {
                                    return d.text;
                                });
                        d3.selectAll("#" + scope.id + " svg .bag text.word")
                                .attr("class", function (d) {
                                    return (d.special) ? "word special-word" : "word";
                                })
                                .on("click", function (d) {
                                    if (d.text) {
                                        scope.service.onWordClick(d.text);
                                    }
                                });
                    }

                    scope.loading = false;
                    scope.$apply();
                };

                scope.factor = 0;
                scope.arrangeWordcloudSize = function (data) {

                    var max = _.max(data, function (item) {
                        return item.freq;
                    }).freq;
                    var min = _.min(data, function (item) {
                        return item.freq;
                    }).freq;
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].special === true) {
                            data[i].size = scope.settings.widthFontSize + scope.settings.minFontSize + 3;
                        } else if (max === min) {
                            data[i].size = scope.settings.minFontSize + scope.settings.widthFontSize / 2;
                        } else {
                            data[i].size = parseInt((data[i].freq - min) / (max - min) * (scope.settings.widthFontSize) + scope.settings.minFontSize);
                        }
                    }

                    scope.factor = 0;
                    scope.factor = setFactor(data, scope.factor, 0);
                    for (var i = 0; i < data.length; i++) {
                        data[i].size = Math.max(data[i].size + scope.factor, scope.settings.minimalFontSize);
                    }
                };

                var setFactor = function (data, f, i) {
                    var sum = getWordCloudSize(data, f);
                    var area = scope.size[0] * scope.size[1];
                    var ratio = 0.5;
                    if (i > 50 || scope.settings.normalFontSize[1] + f >= scope.settings.maximalFontSize) {
                        return f; //nekaj je narobe!
                    } else if (sum / area > scope.settings.cloudAreaRange[1]) {
                        return setFactor(data, f - ratio, ++i);
                    } else if (sum / area < scope.settings.cloudAreaRange[0]) {
                        return setFactor(data, f + ratio, ++i);
                    }
                    return f;
                };

                var getWordCloudSize = function (data, f) {
                    var sum = 0;
                    var c = element.find(".test-canvas");
                    var ctx = c[0].getContext("2d");
                    for (var i = 0; i < data.length; i++) {
                        ctx.font = "" + Math.max(data[i].size + f, scope.settings.minimalFontSize) + "px";
                        var r = ctx.measureText(data[i].keyword);
                        sum += (r.width + 2 * scope.settings.wordPadding) * (data[i].size + f + scope.settings.wordPadding);
                    }
                    return sum;
                };

                var setSize = function () {
                    var svgContainer = element.find('.svg-container');
                    scope.size = [svgContainer.width(), svgContainer.height()];
                    for (var i = 0; i < 2; i++) {
                        scope.size[i] = Math.max(scope.size[i], 140);
                    }

                };

                $timeout(function () {
                    scope.getKeywords();
                }, 200);


                scope.$watch("service.keywords", function (nval, oval) {
                    if (nval && nval.data && oval && oval.data) {
                        var ow = _.filter(oval.data, function (item) {
                            return item.source.short === 'w';
                        });
                        var nw = _.filter(nval.data, function (item) {
                            return item.source.short === 'w';
                        });
                        if((ow.length === 0 && nw.length > 0) || (nw.length === 0 && ow.length > 0)){
                            scope.getKeywords();
                        }else if(ow.length > 0 && nw.length > 0 && ow[ow.length-1].word !== nw[nw.length-1].word){
                            scope.getKeywords();
                        }
                    }
                }, true);

                var sliderTimer;
                scope.$watch("widgetValues", function (nval, oval) {
                    $timeout.cancel(sliderTimer);
                    if (nval !== oval) {
                        var fun;
                        if (nval.normal !== oval.normal) {
                            fun = function () {
                                scope.getKeywords();
                            };
                        } else {
                            fun = function () {
                                scope.drawWordCloud();
                            };
                        }
                        sliderTimer = $timeout(fun, 400);
                    }
                }, true);

                // --------------
                // --- RESIZE ---
                // --------------
                var resizeOff = scope.$on("resize", function (event, args) {
                    scope.drawWordCloud();
                });

                scope.$on("$destroy", function () {
                    $timeout.cancel(sliderTimer);
                    resizeOff();
                    element.remove();
                });

                scope.export = function ($event) {
                    var svg = $("#" + scope.id + " svg.wordcloud")[0];
                    saveSvgAsPng(svg, "word-cloud.png");
                };

                scope.export = function ($event) {
                    var settings = {
                        description: "case: "+scope.case+", ",
                        svg: $("#" + scope.id + " svg.wordcloud")
                    };
                    if(scope.keyword){
                        settings.description+="keyword: "+scope.keyword+", frequent/trendy: "+scope.widgetValues.rare
                            +", correlation: "+scope.widgetValues.corr;
                    }else{
                        settings.description+="frequent/trendy: "+scope.widgetValues.rare;
                    }
                    ExportService.exportDialog("word-cloud",settings,$event);
                };
            }
        };
    }]);