/**
 * Created by matejk on 2.10.2014.
 */

app.directive("widget", ["$window", "$mdMedia", "mobile", "$state", '$stateParams', "$timeout","$compile",'defaultsettings',
    function ($window, $mdMedia, mobile, $state, $stateParams, $timeout,$compile,defaultsettings) {
        return {
            restrict: "A",
            replace: false,
            scope: {
                widget: "="
            },
            link: function (scope, element, attrs) {
                scope.widgetTypes = WidgetType;
                element.addClass("widget");
                var innerElement =  angular.element('<div class="widget-inner"></div>').appendTo(element);
                var w = defaultsettings.dashboardWidgetTypesByType[scope.widget.type];
                if(w) {
                    var directive = w.directive;
                    angular.element("<" + directive + " options='widget'></" + directive + ">").appendTo(innerElement);
                }else{
                    angular.element("<div>{{widget | json}}</div>").appendTo(innerElement);
                }

                var setSize = function(){
                    if(mobile()){
                        element.removeClass("md-whiteframe-4dp widget-desktop")
                    }else{
                        element.addClass("md-whiteframe-4dp widget-desktop")
                    }
                };
                
                setSize();
                
                var resizeOff = scope.$on("resize", function (event, args) {
                    setSize();
                });

                scope.$on("$destroy", function () {
                    resizeOff();
                    element.remove();
                });

                $compile(element.contents())(scope);
            }
        };
    }]);