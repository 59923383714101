
/* global angular, app */

app.factory('TweetModel', ['defaultsettings',
    function(defaultsettings) {
        
        /**
         * Create new TweetModel.
         * @param
         * @constructor
         */
        
        function TweetModel(object) {

            this.id = object.id;
            this.type = "tweet";
            this.createdTime = object.createdTime;
            this.createdTimeFormatted = moment.utc(this.createdTime).local().calendar();
            this.favoriteCount = object.favoriteCount;
            this.geohash = object.geohash;
            this.lang = object.lang;
            this.location = object.location;
            this.quoteCount = object.quoteCount;
            this.replyCount = object.replyCount;
            this.replyToScreenName = object.replyToScreenName;
            this.replyToStatusId = object.replyToStatusId;
            this.replyToUserId = object.replyToUserId;
            this.retweetCount = object.retweetCount;
            this.retweetId = object.retweetId;
            this.source = object.source;
            this.tweetText = object.tweetText;
            this.userId = object.userId;
            this.url = "https://twitter.com/Interior/status/"+this.id;
            //
            // if(this.replyToUserId !== null){
            //     console.log(this);
            // }
        }
        
        composeUrl = function(object) {
            if(angular.isDefined(object.url) && object.url !== "" ){
                return object.url;
            }
            if (angular.isDefined(object.urls) && object.urls.length > 0) {
                return _.first(object.urls);
            }
            return "";
        };

        TweetModel.prototype.getUrl = function() {
            composeUrl(this);
            /*if(angular.isDefined(this.url) && this.url !== "" ){
                return this.url;
            }
            if(!angular.isDefined(this.type) || (this.type === "article")){
                return "http://dx.doi.org/"+this.id;
            }
            return "";*/
        };

        TweetModel.prototype.clone = function() {
            var cloned = angular.copy(this);
            
            // Remove as it's angular specific.
            delete cloned.$$hashKey;

            return cloned;
        };

        TweetModel.prototype.exportPDF = function() {
            var arr = [
                { text: this.tweetText , style: 'abstract', link: this.url},
                { text: this.createdTimeFormatted, style: "details" }
            ];

            if(this.location){
                arr[1].text += ", "+this.location;
            }

            return arr;
        };

        TweetModel.prototype.exportCSV = function(delimiter) {
            var arr = [this.createdTime,
                this.lang,
                this.location,
                this.favoriteCount,
                this.quoteCount,
                this.replyCount,
                this.url,
                this.tweetText
            ];

            for(var i = 0; i < arr.length;i++){
                if(arr[i] === null || arr[i] === undefined){
                    arr[i] = "";
                }else{
                    arr[i]+="";
                }
                arr[i] = arr[i].replace(new RegExp(delimiter, 'g'),"").replace(new RegExp("\n", 'g')," ");
                if(arr[i].split("\"").length % 2 === 0){
                    arr[i]+="\"";
                }
            }

            return arr.join(delimiter);
        };
        
        var getAuthorString = function(authors){
            var arr = [];
            for(var a in authors){
                arr.push(authors[a].name);
            }
            return arr.join(", ");
        };
        
        var getDateObj = function(date){
            if(!angular.isString(date)){
                return {d:'',m:'',y:''};
            }
            var arr = date.split("-");
            if(arr.length<3){
                return {d:'',m:'',y:''};
            }
            return {d:arr[2],m:arr[1],y:arr[0]}; 
        };

        // Return constructor
        return TweetModel;
    }
]);