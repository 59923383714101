/* global app, _ */

/**
 * Created by matejk on 17.10.2014.
 * search2Ctr site has directives: headermenu, wordcloud, foot
 * 
 */

app.controller('searchCtrl', ['$scope', '$stateParams', 'config', 'mobile', '$state',
    'defaultsettings', 'Session', 'QueryModel','HeaderOptions',
    function ($scope, $stateParams, config, mobile, $state,
            defaultsettings, Session, QueryModel,HeaderOptions) {


        $scope.keywords = new QueryModel($stateParams.q,"search");

        $scope.layouts = {
            all: [],
            mobile: undefined,
            selected: undefined
        };

        var setLayout = function(){
            var user = Session.get();
            if(mobile()){
                $scope.layouts.all = user.layoutSettings.search.mobile;
                $scope.layouts.mobile = true;
            }else{
                $scope.layouts.all = user.layoutSettings.search.desktop;
                $scope.layouts.mobile = false;
            }
            var c = $scope.layouts.all[0];
            var d = _.findWhere($scope.layouts.all, {default: true});
            if(d){
                c = d;
            }
            if ($stateParams.layout) {
                var s = _.findWhere($scope.layouts.all, {name: $stateParams.layout});
                if (s) {
                    c = s;
                }
            }
            $scope.layouts.selected = c;
        };
        setLayout();

        $scope.redirect = function () {
            var params = {
                q: undefined,
                layout: undefined
            };
            if (!$scope.keywords.isEmpty()) {
                params.q = $scope.keywords.toString();
            }
            params.layout = $scope.layouts.selected.name;
            
            $state.go("app.search", params, {
                reload: false,
                inherit: true,
                notify: false
            });
        };

        $scope.mainToolsOptions = {
            keywords: $scope.keywords,
            redirect: $scope.redirect,
            layouts: $scope.layouts
        };

        $scope.sliderValues = {};

        $scope.controls = {
            cloud: {
                keywords: $scope.keywords,
                showSelectedWord: false,
                onWordClick: function (word) {
                    $scope.keywords.addKeyword(word);
                    $scope.redirect();
                }
            },
            treemap: {
                keywords: $scope.keywords,
                onWordClick: function (word) {
                    $scope.keywords.addKeyword(word);
                    $scope.redirect();
                }
            },
            complexity: {
                keywords: $scope.keywords,
                onWordClick: function (word) {
                    $scope.keywords.addKeyword(word);
                    $scope.redirect();
                }
            },
            details: {
                keywords: $scope.keywords
            },
            list: {
                keywords: $scope.keywords
            },
            documentgeo: {
                keywords: $scope.keywords
            },
            documentdetails: {
                onWordClick: function (word) {
                    $scope.keywords.addSingleKeyword(word);
                    $scope.redirect();
                }
            }
        };


        // --------------
        // --- SET HEADER OPTIONS ---
        // --------------
        if(Session.get().role === UserRole.ANONYMOUS){
            HeaderOptions.set([
                HeaderOptions.defaultOptions.webSearch($scope.keywords)
            ]);
        }else {
            HeaderOptions.set([
                HeaderOptions.defaultOptions.layouts($scope.layouts, $scope.redirect, $scope,setLayout),
                HeaderOptions.defaultOptions.switchCase($scope.redirect),
               // HeaderOptions.defaultOptions.switchScope($scope.redirect),
                HeaderOptions.defaultOptions.webSearch($scope.keywords)
            ]);
        }

        // --------------
        // --- RESIZE ---
        // --------------
        var resizeOff = $scope.$on("resize", function (event, args) {
            if(mobile()!== $scope.layouts.mobile){
                setLayout();
            }
        });

        $scope.$on("$destroy", function () {
            resizeOff();
            //element.remove();
        });

    }
]);
