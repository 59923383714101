// TODO: remove it

app.directive('documentdetails',
    [
        'ThriftHelper', 'config', '$timeout', '$stateParams', 'HitModel', '$anchorScroll', '$location','ExportService',
        'exportableDetailsHitModel', 'DashboardService',
        function (ThriftHelper, config, $timeout, $stateParams, HitModel, $anchorScroll, $location, ExportService,
                  exportableDetailsHitModel,DashboardService) {
            return {
                restrict: "E",
                replace: true,
                templateUrl: 'app/directives/document-details/document-details.html',
                scope: {
                    options: "="
                },
                link: function (scope, element, attrs) {
                    scope.service = DashboardService;
                    scope.id = "document-details-" + UUID.generate();
                    scope.item = null;
                    scope.previewImage = null;
                    scope.imageId = "document-details-image" + UUID.generate();
                    scope.previewImageFullId = "document-details-image-full-div" + UUID.generate();
                    scope.timer = null;
                    scope.loading = true;
                    scope.generating = false;
                    scope.error = null;
                    scope.showFullImage = false;
                    scope.references = {
                        loading: true,
                        error: undefined,
                        items: []
                    };
                    scope.backItemsStack = [];
                    scope.forwardItemsStack = [];

                    var wsWebshot = function () {
                        return config.calls.WS_WEBSHOT + "-" + scope.id;
                    };

                    var wsGetEs = function () {
                        return config.calls.WS_GET_ES + "-" + scope.id;
                    };

                    var eventId = 'onDocumentSelected_' + scope.options.detailsId;
                    scope.$on(eventId, function (event, item) {
                        // when document is selected outside of Details component, we would like to reset items stack to
                        // reset "back" button history
                        resetItemsStack();

                        scope.backItemsStack.unshift(item);

                        selectDocument(item);
                    });

                    scope.$on(wsWebshot(), function (event, args) {
                        if (args && args.data) {
                            $timeout(function () {
                                if (args.data.status == WebshotStatus.VALID) {
                                    scope.previewImage = config.previewUrl + args.data.webUrl;

                                    scope.loading = false;
                                    scope.generating = false;
                                    scope.error = null;
                                } else if (args.data.status == WebshotStatus.PENDING) {
                                    loadDocumentAfterTimer();

                                    scope.loading = false;
                                    scope.generating = true;
                                    scope.error = null;
                                } else {
                                    scope.loading = false;
                                    scope.generating = false;
                                    scope.error = 'Unable to generate preview.';
                                }
                            });
                        }
                    });

                    scope.$on(wsGetEs(), function (event, args) {
                        $timeout(function () {
                            scope.references.loading = false;
                            if (args && args.data && args.data.documents && args.data.documents != null && args.data.documents.length > 0) {
                                var refs = [];
                                args.data.documents.forEach(function (document) {
                                    refs.push(new HitModel(document));
                                });

                                scope.references.items = refs;
                            } else {
                                scope.references.error = 'No references.';
                            }
                        });
                    });

                    function selectDocument(item) {
                        scope.item = item;
                        scope.previewImage = null;

                        cancelTimer();

                        scope.loading = true;
                        scope.generating = false;
                        scope.error = null;

                        loadPreview(item);

                        var divId = '#' + scope.id;
                        $(divId).animate({ scrollTop: 0 }, 'fast');

                        loadReferences(item);
                        loadDocumentTitleDetails(item);

                        scrollToTop();
                    }

                    function loadPreview(document) {
                        if (document.url != null) {
                            ThriftHelper.sendRequest(new GetWebshotReq({
                                    id: document.id,
                                    url: document.url
                                }),
                                MsgType.GET_WEBSHOT_REQ, wsWebshot());
                        }
                    }

                    function loadDocuments(ids, type) {
                        var idsArray = [];
                        if (ids.constructor === Array) {
                            idsArray = ids;
                        } else {
                            idsArray.push(ids);
                        }

                        ThriftHelper.sendRequest(new GetEsReq({
                                type: type,
                                indexName: 'document',
                                ids: idsArray
                            }),
                            MsgType.GET_ES_REQ, wsGetEs());
                    }

                    function cancelTimer() {
                        if (scope.timer !== null) {
                            clearTimeout(scope.timer);
                            scope.timer = null;
                        }
                    }

                    function loadDocumentAfterTimer() {
                        cancelTimer();
                        scope.timer = setTimeout(function () {
                            loadPreview(scope.item);
                        }, 1000);
                    }

                    function loadReferences(document) {
                        scope.references = {
                            loading: true,
                            error: undefined,
                            items: []
                        };

                        if (document == null || document.references == null || document.references.length == 0) {
                            scope.references.error = 'No references.';
                            scope.references.loading = false;
                        } else {
                            loadDocuments(document.references, document.type); // FIXME each reference can have different type
                        }
                    }

                    function loadDocumentTitleDetails(item) {
                        var titleObjs = [];
                        if (item.type == 'project') {
                            if (item.source != null && item.source.length > 0) {
                                titleObjs.push(item.source);
                            }
                            if (item.publisher != null && item.publisher.length > 0) {
                                titleObjs.push(item.publisher);
                            }
                        } else {
                            if (item.source != null && item.source.length > 0) {
                                titleObjs.push(item.source);
                            }

                            if (item.year != null && item.year.length > 0) {
                                titleObjs.push(item.year);
                            }

                            if (item.volume != null && item.volume.length > 0) {
                                titleObjs.push(item.volume);
                            }

                            if (item.issue != null && item.issue.length > 0) {
                                titleObjs.push(item.issue);
                            }
                        }

                        if (titleObjs.length > 0) {
                            scope.documentDetails = titleObjs.join(', ');
                        }
                    }

                    function resetItemsStack() {
                        scope.backItemsStack = [];
                        scope.forwardItemsStack = [];
                    }

                    function scrollToTop() {
                        $location.hash(scope.id + '-title');
                        $anchorScroll();
                    }

                    scope.onShowFullImage = function () {
                        scope.showFullImage = true;
                    };

                    scope.onHideFullImage = function () {
                        scope.showFullImage = false;
                    };
                    
                    scope.selectWord = function (keyword) {
                        var word = 'w:' + keyword;
                        scope.service.onWordClick(word);
                    };

                    scope.selectFos = function (keyword) {
                        var fos = 'f:' + keyword;
                        scope.service.onSingleWordClick(fos);
                    };

                    scope.openMenu = function ($mdMenu, ev) {
                        $mdMenu.open(ev);
                    };

                    scope.onReferenceClick = function (item) {
                        scope.backItemsStack.unshift(item);
                        scope.forwardItemsStack = [];

                        selectDocument(item);
                    };

                    scope.onBackClick = function (item, index) {
                        scope.forwardItemsStack.push(scope.item);

                        scope.backItemsStack.splice(0, index);

                        selectDocument(item);
                    };

                    scope.onForwardClick = function (item, index) {
                        var i = scope.forwardItemsStack[index];
                        scope.backItemsStack.unshift(i);

                        scope.forwardItemsStack.splice(0, index + 1);

                        selectDocument(item);
                    };

                    scope.export = function ($event) {
                        var settings = {
                            item: exportableDetailsHitModel(scope.item)
                        };
                        ExportService.exportDialog("document-details",settings,$event);
                    };

                    scope.selectDocument = selectDocument;
                }
            };
        }
    ]);