app.factory('exportableNewsDetailsHitModel', ['$q', 'config', 'ThriftHelper', '$rootScope', 'HitModel',
    function ($q, config, ThriftHelper, $rootScope, HitModel) {
        function exportableNewsDetailsHitModelDecorator(hitModel) {
            initExportPdf(hitModel);

            return hitModel;
        }

        function initExportPdf(hitModel) {
            var id = "news-details-" + UUID.generate();
            hitModel._originalExportPDF = hitModel.exportPDF;
            hitModel.exportPDF = export2Pdf;

            hitModel._requestId = config.calls.WS_WEBSHOT + "-" + id;
            hitModel._previewImageRetries = 5;
        }

        function loadDocumentPreviewImage(item) {
            var deferred = $q.defer();

            loadDocumentPreviewUrl(item).then(function (url) {
                getBase64Image(url)
                    .then(function (imageBase64) {
                        deferred.resolve(imageBase64);
                    }, function (error) {
                        deferred.resolve(undefined);
                    });
            }, function (error) {
                // in case of error we generate PDF anyway - no image
                deferred.resolve(undefined);
            });

            return deferred.promise;
        }

        function loadDocumentPreviewUrl(item) {
            var deferred = $q.defer();

            var requestId = item._requestId + UUID.generate();

            $rootScope.$on(requestId, function (event, args) {
                if (args && args.data) {
                    if (args.data.status === WebshotStatus.VALID) {
                        var url = config.previewUrl + args.data.webUrl;

                        deferred.resolve(url);
                    } else if (args.data.status === WebshotStatus.PENDING) {
                        // we do a reload after some time
                        item._previewImageRetries--;
                        if (item._previewImageRetries > 0) {
                            setTimeout(function () {
                                if (item.url != null) {
                                    ThriftHelper.sendRequest(new GetWebshotReq({
                                            id: item.id,
                                            url: item.url
                                        }),
                                        MsgType.GET_WEBSHOT_REQ, requestId);
                                }
                            }, 1000);
                        } else {
                            // Unable to generate preview.
                            deferred.resolve(undefined)
                        }
                    } else {
                        deferred.resolve(undefined)
                    }
                }
            });

            if (item.url != null) {
                ThriftHelper.sendRequest(new GetWebshotReq({
                        id: item.id,
                        url: item.url
                    }),
                    MsgType.GET_WEBSHOT_REQ, requestId);
            }

            return deferred.promise;
        }

        function getBase64Image(url) {
            var deferred = $q.defer();
            var img = document.createElement('img');
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = function () {
                // image loaded
                var canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL('image/png');
                deferred.resolve(dataURL);
            };

            img.onerror = function (error) {
                deferred.reject('Failed to load image: ' + error);
            };

            img.src = url;

            return deferred.promise;
        }

        function export2Pdf() {
            var self = this;
            var deferred = $q.defer();

            $q.all([loadDocumentPreviewImage(self)])
                .then(function (data) {
                    var references = data[0];
                    var preview = data[1];

                    deferred.resolve(generatePdfData(self, preview));
                }, function (reason) {
                    // in case of error we generate PDF anyway - no image, no references, ...
                    deferred.resolve(generatePdfData(self, undefined, undefined));
                });

            return deferred.promise;
        }

        function generatePdfData(item, preview) {
            var output = [];

            // Authors, dates, ...
            prepareHeader(item, output);

            if (item.abstract) {
                output.push({text: item.abstract, margin: [0, 0, 0, 10]});
            }

            preparePreview(preview, output);

            return output;
        }

        function prepareHeader(item, output) {
            output.push({text: item.title, style: 'title', link: item.link, margin: [0, 0, 0, 15]});

            output.push({text: 'Date: ' + item.date});
            output.push({text: 'Journal: ' + item.journal});

            // empty line
            output.push({text: '', margin: [0, 0, 0, 10]});
        }

        function preparePreview(preview, output) {
            if (preview) {
                output.push({image: preview, width: 450});
            }
        }

        return exportableNewsDetailsHitModelDecorator;
    }
]);