/**
 * Created by matejk on 2.10.2014.
 */

app.directive("fullscreen", ["$window", "$mdMedia","Session", "$state", '$stateParams', "$timeout",
    function ($window, $mdMedia, Session, $state, $stateParams, $timeout) {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                var elm = element[0];

                var setHeight = function () {
                    var h = $window.innerHeight - elm.getBoundingClientRect().top;
                    element.height(h);
                    scope.$broadcast('main-resize',{mobile: !$mdMedia('gt-sm')});
                    
                };

                var set = function () {
                    //if($mdMedia('gt-xs')){
                        element.addClass('fullscreen-dir');
                        element.parent().css('display', 'relative');
                        $timeout(function () {
                            setHeight();
                        }, 0);
                        $timeout(function () {
                            setHeight();
                        }, 500);
                    //}else{
                    //    element.css('height', 'auto');
                    //}
                };
                
                set();

                angular.element($window).bind('resize', function () {
                    set();
                });
            }
        };
    }]);