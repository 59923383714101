/**
 * Created by Matej on 31.7.2014.
 */

/**
 *  controller for home page
 **/

app.controller('signupCtrl', ['$scope', 'Toast', '$state', 'Session', 'config','ThriftHelper', '$stateParams', '$log', 'defaultsettings',
    function ($scope, Toast, $state, Session, config, ThriftHelper, $stateParams, $log, defaultsettings) {
        
        $scope.form = {};
        $scope.title = "Sign Up";
        $scope.order = "";
        $scope.btnName = "Sign up";

        $scope.requesting = true;

        $scope.signup = {
            lastName: "",
            keywords: []
        };

        $scope.orderList = {
            selected: ($stateParams.order === 'eitm') ? defaultsettings.order[3] : defaultsettings.order[1],
            all: _.filter(defaultsettings.order, function (item) {
                    if ($stateParams.order === 'eitm') return item.id >= 2 && item.id <= 3;
                    else return item.id >= 0 && item.id <= 1;
                })
            };

        var init = function () {
            $log.info('Signup init. Order=', JSON.stringify($stateParams));
            if ($stateParams.order) $scope.order = $stateParams.order;
            if ($scope.order !== '') $scope.btnName = "Sign up and Order";
            $log.info('$scope.order=', $scope.order);
        };

        $scope.signupClick = function () {
            $scope.requesting = true;
            // set order from selected if needed
            if ($stateParams.order) {
                $scope.signup.order = $scope.orderList.selected.id.toString();
            }
            $log.info($scope.signup);
            ThriftHelper.sendRequest(new SignupReq($scope.signup), MsgType.SIGNUP_REQ, config.calls.WS_SIGNUP);
        };

        $scope.$on(config.calls.WS_SIGNUP, function (event, args) {
            if(args.data.error !== null){
                Toast.error(args.data.error);
                $scope.getCaptcha();
            }else{
                $scope.requesting = false;
                if ($stateParams.order) {
                    Toast.success("New Account created! Check your email for password.");
                } else {
                    Toast.success("Free Account created! Check your email for password.");
                }
                $state.go("login");
            }
        });

        $scope.getCaptcha = function(){
            $scope.requesting = true;
            var promise = Session.connect();
            promise.then(function () {
                ThriftHelper.sendRequest(new GetCaptchaReq({}), MsgType.GET_CAPTCHA_REQ, config.calls.WS_CAPTCHA);
            }, function () {
                Toast.error("Can't connect to the server...");
            });
        };

        $scope.$on(config.calls.WS_CAPTCHA, function (event, args) {
            if (args.err === false) {
                $scope.signup.captchaId = args.data.captchaId;
                $scope.signup.captchaDate = args.data.captchaDate;

                $scope.captcha = args.data.captchaSvg;//.replace('<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">',"");

                $scope.requesting = false;
                $scope.$apply();
            }else{
                Toast.error("Error getting Captcha!");
            }
        });

        $scope.loginClick = function(){
            $state.go("login");
        };
        init();
        $scope.getCaptcha();


    }]);
