/**
 * Created by timkos on 19/01/17.
 */


angular.module('percipioApp').filter('getvalue', [
    function () {
        return function (value) {
            if (angular.isFunction(value)) {
                return value();
            }
            return value;
        };
    }]);
