
/* global angular, app */

app.factory('ComplexityModel', ['config',
    function(config) {

        var cloudSize = config.complexity.filterSize;
        var normalizeFreq = 1;
        var normalizeCorr = config.wordCloud.maxCorrelation;
        /**
         * Create new TreemapModel.
         * @param object
         * @constructor
         */
        function ComplexityModel(object) {
            this.all = object;
            setLocalRanks(this.all);
            setGlobalRanks(this.all);
            this.currentFilter = [];
            this.currentDisplay = [];
            this.cache = {};
            normalizeFreq = this.all.length;
            this.keyword = "";
        }

        ComplexityModel.prototype.updateCurrent1 = function(freqParam,corrParam) {
            //console.log("update1",freqParam,corrParam);
            var arr = [];//angular.copy(this.all);
            for(var i = 0; i<this.all.length;i++){
                var k = this.all[i];
                var v = { keyword: k.keyword, score: getScore(k,freqParam,corrParam)};
                arr.push(v);
            }
            arr.sort(function(a, b){ return b.score-a.score;});
            this.currentFilter = arr.slice(0,Math.min(config.complexity.filterSize,this.all.length));

            var cache = this.cache;
            return _.filter(this.currentFilter,function (item) {
                return angular.isUndefined(cache[item.keyword]);
            });
        };

        ComplexityModel.prototype.updateCurrent2 = function(newKeywords,freqParam,corrParam) {
            //console.log("update2",freqParam,corrParam);
            for(var i = 0; i<newKeywords.length; i++){
                var c = { keyword: newKeywords[i].keyword, all: newKeywords[i].result};
                setLocalRanks(c.all);
                setGlobalRanks(c.all);
                this.cache[newKeywords[i].keyword] = c;
            }

            var matrix = {};
            for(var i = 0; i<this.currentFilter.length; i++){
                var k = this.currentFilter[i];
                matrix[k.keyword] = {
                    keyword: k.keyword,
                    score: k.score,
                    count: 0,
                    links: []
                };
            }

            var kws = _.pluck(this.currentFilter,"keyword");
            for(var i in matrix){
                var d = matrix[i];
                var c = this.cache[d.keyword];
                for(var j = 0; j< c.all.length; j++){
                    var r = c.all[j];
                    if(_.contains(kws,r.keyword)){
                        d.count++;
                        d.links.push(r);
                    }
                }
            }
            console.log("matrix",matrix);

            var arr = Object.values(matrix);
            arr.sort(function(a, b){
                if(b.count === a.count)
                    return b.score-a.score;
                return b.count-a.count;
            });
            var selected = arr.slice(0,Math.min(config.complexity.displaySize,arr.length));
            kws = _.pluck(selected,"keyword");
            var cache = this.cache;
            var compl = [];
            var pairs = {};
            selected.forEach(function (item) {
                var c = cache[item.keyword];
                var range = [10000000,0.01];
                var imports = [];
                pairs[item.keyword] = {};
                for(var j = 0; j< c.all.length; j++){
                    var r = c.all[j];
                    if(_.contains(kws,r.keyword) && item.keyword!== r.keyword){
                        // dto.links.push({
                        //     keyword: r.keyword,
                        //     score: getScore(r,freqParam,corrParam)
                        // });
                        var size = getScore(r,freqParam,corrParam);
                        imports.push({name:"masterNode.0." + r.keyword, size: size, keyword: r.keyword});
                        pairs[item.keyword][r.keyword] = true;
                        if(size > range[1]){
                            range[1] = size;
                        }
                        if(size < range[0]){
                            range[0] = size;
                        }
                    }
                }
                compl.push({
                    "name": "masterNode.0." + item.keyword,
                    "keyword": item.keyword,
                    "size": item.score,
                    "imports" : imports,
                    "range" : range
                });
            });


            compl.forEach(function (item) {
                item.imports.forEach(function (i) {
                    if(item.range[0] === item.range[1])
                        i.size = 1;
                    else
                        i.size = (i.size - item.range[0])/(item.range[1] - item.range[0]);
                    if (pairs[item.keyword][r.keyword] === true && pairs[r.keyword][item.keyword] === true)
                        i.bidirectional = true;
                    else
                        i.bidirectional = false;
                });
            });

            console.log("selected",compl);

            this.currentDisplay = compl;

            return this.currentDisplay;
        };

        ComplexityModel.prototype.clone = function() {
            var cloned = angular.copy(this);
            delete cloned.$$hashKey;
            return cloned;
        };

        var getScore = function(item,freqParam,corrParam) {
            if(corrParam===-1){
                return item.freq;
            }
            return getFreqScore(item,freqParam)/normalizeFreq + getCorrScore(item,corrParam)/normalizeCorr;
        };

        var getFreqScore = function(item,freqParam) {
            return freqParam*item.gRank + (1-freqParam)*item.lRank;
        };

        var getCorrScore = function(item,corrParam) {
            return corrParam*Math.min(item.corr,normalizeCorr);
        };

        var setGlobalRanks = function(data) {
            data.sort(function(a, b){ return a.freqGlobal-b.freqGlobal;});
            for(var i in data){
                data[i].gRank = data.length - parseInt(i);
            }
        };
        var setLocalRanks = function(data) {
            data.sort(function(a, b){ return b.freq-a.freq;});
            for(var i in data){
                data[i].lRank = data.length - parseInt(i);
            }
        };

        // Return constructor
        return ComplexityModel;
    }
]);