/* global d3, app */


app.directive("multiwidget", ["Session", "$state", 'ThriftHelper',
    'config', '$window', '$timeout',
    function (Session, $state, ThriftHelper,
            config, $window, $timeout) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/directives/multi-widget/multi-widget.html',
            scope: {
                control: "=",
                options: "=",
                slider: "="
            },
            link: function (scope, element, attrs) {
            }
        };
    }]);