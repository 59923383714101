/* global app, angular */

/**
 * Created by matejk on 2.10.2014.
 */

app.directive("timelinelist", ['$timeout', '$state', '$rootScope',
    '$window', 'config', 'ThriftHelper', 'HitModel','$sce','$mdMedia',
    'Session','ExportService','DashboardService',
    function ($timeout, $state, $rootScope, $window, config, ThriftHelper, HitModel,$sce,$mdMedia,
              Session,ExportService,DashboardService) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/directives/timeline-list-new/timeline-list.html',
            scope: {
                options: '='
            },
            link: function (scope, element, attrs) {
                scope.service = DashboardService;
                scope.id = "timeline-list-" + UUID.generate();
                scope.settings = config.infiniteList;
                var scroller = element.find(".md-virtual-repeat-scroller");

                var getCall = function () {
                    return config.calls.WS_TIMELINE_LIST + "-" + scope.id;
                };

                var setLastCurrent = function(last, current){
                    if(last === null){
                        current.newDate = true;
                        current.newJournal = true;
                    }else {
                        if (last.date === current.date) {
                            current.newDate = false;
                            last.lastDate = false;
                        } else {
                            current.newDate = true;
                            last.lastDate = true;
                        }
                        if (last.journal === current.journal) {
                            current.newJournal = false;
                            last.lastJournal = false;
                        } else{
                            current.newJournal = true;
                            last.lastJournal = true;
                        }
                    }
                };

                scope.$on(getCall(), function (event, args) {
                    var data = args.data;
                    if(data instanceof ErrorMsg){
                        return;
                    }
                    //console.log(data);
                    var batchNumber = Math.floor(data.start / scope.infiniteItems.BATCH_SIZE);
                    //console.log("recived:"+batchNumber);
                    scope.infiniteItems.loadedBatches[batchNumber] = [];
                    var cur = scope.infiniteItems.loadedBatches[batchNumber];
                    for (var i = 0; i < data.documents.length; i++) {
                        var hit = new HitModel(data.documents[i]);

                        var last = null;
                        if(i>0){
                            last = cur[i-1];
                        }else if(batchNumber>0 && scope.infiniteItems.loadedBatches[batchNumber-1] &&
                            scope.infiniteItems.loadedBatches[batchNumber-1].length>0){
                            var lb = scope.infiniteItems.loadedBatches[batchNumber-1];
                            last = lb[lb.length - 1];
                        }
                        setLastCurrent(last,hit);
                        scope.infiniteItems.loadedBatches[batchNumber].push(hit);
                    }
                    if(scope.infiniteItems.loadedBatches[batchNumber+1] && scope.infiniteItems.loadedBatches[batchNumber+1].length>0){
                        var c = scope.infiniteItems.loadedBatches[batchNumber];
                        setLastCurrent(c[c.length - 1],
                            scope.infiniteItems.loadedBatches[batchNumber+1][0]);
                    }

                    if(data.start === 0){
                        scope.infiniteItems.total = data.total;
                        var pn = Math.ceil(Math.min(data.total,scope.infiniteItems.MAX_SIZE)/scope.infiniteItems.PAGE_SIZE);
                        scope.infiniteItems.pages = [];
                        for(var i = 0; i<pn; i++){
                            scope.infiniteItems.pages.push((i*scope.infiniteItems.PAGE_SIZE +1) + " - " +
                                Math.min((i+1)*scope.infiniteItems.PAGE_SIZE, data.total, scope.infiniteItems.MAX_SIZE));
                        }
                        scope.infiniteItems.busy = false;
                    }

                    scope.$apply();
                });

                scope.infiniteItems = {
                    topIndex: 0,
                    page: 0,
                    pages: [],
                    busy: true,
                    total: 0,
                    loadedBatches: {},
                    PAGE_SIZE: 1000,
                    BATCH_SIZE: 50,
                    MAX_SIZE: 10000,
                    isLoading: function(){
                        if(this.busy){
                            return true;
                        }
                        var batchNumber = this.getBatchNumber(this.topIndex);
                        var batch = this.loadedBatches[batchNumber];
                        if(batch){
                            return false;
                        }
                        return true;
                    },
                    getBatchNumber: function(index){
                        return Math.floor((this.page*this.PAGE_SIZE + index) / this.BATCH_SIZE);
                    },
                    getRealIndex: function(index){
                        return index + this.page*this.PAGE_SIZE;
                    },
                    getItemAtIndex: function (index) {
                        var batchNumber = this.getBatchNumber(index);
                        var batch = this.loadedBatches[batchNumber];

                        if (batch) {
                            return batch[index % this.BATCH_SIZE];
                        } else if (batch !== null) {
                            this.fetchBatch_(batchNumber);
                        }
                        return null;
                    },
                    getLength: function () {
                        if (this.busy === true)
                            return 1;

                        return this.getPageSize();
                    },
                    getPageSize: function () {
                        return Math.max(0,Math.min(this.PAGE_SIZE, Math.min(this.total, this.MAX_SIZE) -this.page*this.PAGE_SIZE));
                    },
                    prevPage: function () {
                        if(this.page > 0) {
                            this.page--;
                            this.topIndex = 0;
                        }
                    },
                    nextPage: function () {
                        if(this.total>(this.page+1)*this.PAGE_SIZE){
                            this.page++;
                            this.topIndex = 0;
                        }
                    },
                    fetchBatch_: _.debounce(function (batchNumber) {
                        //console.log("send:"+batchNumber);
                        this.loadedBatches[batchNumber] = null;
                        var dto = toDto(batchNumber);
                        ThriftHelper.sendRequest(new SearchEsReq(dto), MsgType.SEARCH_ES_REQ, getCall());
                    }, 300)
                };

                var toDto = function (batchNumber) {
                    var opt = {
                        type: "news",
                        sortBy: scope.options.sortBy
                    };
                    return scope.service.keywords.toDto(batchNumber*scope.infiniteItems.BATCH_SIZE, scope.infiniteItems.BATCH_SIZE, opt);
                };

                //start
                var init_main = function () {
                    scroller.scrollTop(0);
                    if (scope.infiniteItems) {
                        scope.infiniteItems.topIndex = 0;
                        scope.infiniteItems.page = 0;
                    }
                    $timeout(function () {
                        scope.infiniteItems.loadedBatches = {};
                        scope.infiniteItems.busy = true;
                        scope.infiniteItems.topIndex = 0;
                        scope.infiniteItems.total = 0;
                        scope.infiniteItems.page = 0;
                    });
                };
                var init = _.debounce(init_main, 100);
                init();
                
                
                scope.showDate = function(idx, item){
                    if(scope.infiniteItems.total===0 || angular.isUndefined(item) || item === null)
                        return false;
                    if(idx>scope.infiniteItems.topIndex && item.newDate===true){
                        return true;
                    }
                    if(idx<=scope.infiniteItems.topIndex && item.lastDate===true){
                        return true;
                    }
                    return false;
                };
                scope.showJournal = function(idx, item){
                    if(scope.infiniteItems.total===0 || angular.isUndefined(item) || item === null)
                        return false;
                    if(idx>scope.infiniteItems.topIndex && item.newJournal===true){
                        return true;
                    }
                    if(idx<=scope.infiniteItems.topIndex && item.lastJournal===true){
                        return true;
                    }
                    return false;
                };
                scope.showFixedDate = function(){
                    if(scope.infiniteItems.total===0 || scope.infiniteItems.topIndex<0)
                        return false;
                    return !scope.showDate(scope.infiniteItems.topIndex,
                        scope.infiniteItems.getItemAtIndex(scope.infiniteItems.topIndex));
                };
                scope.showFixedJournal = function(){
                    if(scope.infiniteItems.total===0 || scope.infiniteItems.topIndex<0)
                        return false;
                    return !scope.showJournal(scope.infiniteItems.topIndex,
                        scope.infiniteItems.getItemAtIndex(scope.infiniteItems.topIndex));
                };
                
                scope.open = function(item,event){
                    if (event.metaKey || event.ctrlKey){
                       scope.openExternal(item);
                    }else{
                        scope.selectedNews = item;
                        scope.selectedNews.url = $sce.trustAsResourceUrl(scope.selectedNews.url);
                    }
                };
                scope.openExternal = function(item){
                    window.open(item.url,'_blank');
                };
                scope.closeSelectedNews = function(){
                    scope.selectedNews = undefined;
                };

                scope.openExport = function(ev){
                    var settings = {
                        selected: [],
                        dto: angular.copy(toDto(scope.infiniteItems.getBatchNumber(0))),
                        description: {
                            case: Session.get().currentCase.name,
                            keywords: scope.service.keywords.data
                        }
                    };
                    console.log(settings);
                    ExportService.exportDialog("timeline",settings,ev);
                };

                scope.$watch("service.keywords", function (nval, oval) {
                    if (nval && nval.data && oval && oval.data) {
                        if (oval.data.length !== nval.data.length) {
                            init();
                        } else {
                            for (var i = 0; i < nval.data.length; i++) {
                                if (oval.data[i].toString() !== nval.data[i].toString()) {
                                    init();
                                    return;
                                }
                            }
                        }
                    }
                }, true);

//                scroller.on('wheel', function (e) {
//                    if (scope.infiniteItems &&
//                            scope.infiniteItems.numLoaded_ < scope.infiniteItems.total) {
//                        var delta = e.originalEvent.wheelDelta;
//                        if (delta < 0) {
//                            var scrollTop = scroller.scrollTop();
//                            var scrollHeight = this.scrollHeight;
//                            var height = scroller.height();
//                            var bottom = scrollHeight - height - 300;
//                            if (scrollTop + (-delta) > bottom) {
//                                console.log("prevent scroll");
//                                e.preventDefault();
//                                return false;
//                            }
//                        }
//
//                    }
//                });

                scope.$on("$destroy", function () {
                    element.off("resize");
//                    scroller.off('wheel');
                    element.remove();
                });

                scope.onTitleClick = function (item) {
                    var eventId = 'onItemSelected_' + scope.options.detailsId;
                    $rootScope.$broadcast(eventId, item);
                };

            }
        };
    }]);
