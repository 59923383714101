/**
 * Created by timkos on 19/01/17.
 */
angular.module('percipioApp').factory(
    "DashboardService", function ($rootScope, $mdDialog, mobile, $stateParams, config, ThriftHelper, Toast, $state, Session,
                                  QueryModel, defaultsettings) {

        var thiz = this;

        thiz.keywords = new QueryModel($stateParams.q,"dashboard");
        thiz.sharedValues = {};
        thiz.dashboard = {};
        thiz.layout = [];
        thiz.widgets = [];

        var setDefaultSharedValues = function(){
            thiz.sharedValues = {
                cloud: {
                    normal: 0,
                    rare: 0,
                    corr: 100
                }
            };
        };

        thiz.init = function(){
            //thiz.keywords.clear();
            thiz.sharedValues = {};
            thiz.dashboard = {};
            thiz.layout = [];
            thiz.widgets = [];

            if($state.current.name === "app.dashboard.item"){
                setDefaultSharedValues();
                var template = Session.get().uiTemplate;
                var idx = parseInt($stateParams.id);
                if(template !== null && template.dashboards.length > 0 && idx >=0 && idx < template.dashboards.length){
                    thiz.dashboard = template.dashboards[idx];
                    if(thiz.dashboard.type < DashboardType.CUSTOM){
                        thiz.layout = defaultsettings.defaultDashboards[thiz.dashboard.type].columns;
                        thiz.layout.forEach(function (col) {
                            col.widgets.forEach(function (widget) {
                                var defWidget = defaultsettings.dashboardWidgetTypesByType[widget.type];
                                widget.name = defWidget.name;
                                _.defaults(widget,defWidget.defaultOptions);
                            });
                        });
                    }else{
                        thiz.layout = thiz.dashboard.columns;
                    }
                    thiz.layout.forEach(function (col) {
                        col.widgets.forEach(function (widget) {
                            var defWidget = defaultsettings.dashboardWidgetTypesByType[widget.type];
                            if(!angular.isString(widget.name)) widget.name = defWidget.name;
                            for(var key in defWidget.defaultOptions){
                                if(widget[key] === null || widget[key] === undefined) widget[key] = defWidget.defaultOptions[key];
                            }
                            thiz.widgets.push(widget);
                        });
                    });
                }
            }
        };

        thiz.redirect = function () {
            var params = {
                q: undefined,
                id: $stateParams.id
            };
            if (!thiz.keywords.isEmpty()) {
                params.q = thiz.keywords.toString();
            }
            $state.go("app.dashboard.item", params, {
                reload: false,
                inherit: true,
                notify: false
            });
        };



        thiz.onWordClick = function(word) {
            thiz.keywords.addKeyword(word);
            thiz.redirect();
        };
        thiz.onSingleWordClick = function(word) {
            thiz.keywords.addSingleKeyword(word);
            thiz.redirect();
        };

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState) {
            if(toState.name === "app.dashboard.item" || fromState.name === "app.dashboard.item") {
                thiz.init();
            }
            if(toState.name !== "app.dashboard.item"){
                thiz.keywords.clear();
            }
        });
        thiz.init();

        return thiz;
    });
