/* global d3, app */

/**
 * Created by matejk on 8.10.2014.
 */

app.directive("worddetails", ["Session", "$state", 'config',
    function (Session, $state, config) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/directives/word-details/word-details.html',
            scope: {
                options: "="
            },
            link: function (scope, element, attrs) {
                // ------------------
                // --- SCOPE VARS ---
                // ------------------
                scope.id = "word-details-" + UUID.generate();



            }
        };
    }]);