
app.controller('adminCurrentCtrl', ['$scope', 'adminService',
    function ($scope, adminService) {

        $scope.current = adminService.data.current;
        $scope.getter = adminService.getter;
        $scope.init = adminService.init;

        adminService.getCurrent();

        $scope.refreshCurrent = function(){
            adminService.getCurrent();
        };

        $scope.$watch(function(){ return adminService.data.current;}, function(newVal){
            $scope.current = newVal;
        });
    }]);
