
/* global angular, app */
app.constant('SESSION_STATUS', {
    notconnected: 0,
    connected: 1,
    connecting: 2
});
/**
 * app Session for user data info
 **/
app.service('Session', ['$state', '$cookies', 'ThriftHelper', '$rootScope', 'config',
    'Idle', '$q', 'SESSION_STATUS', 'defaultsettings',
    function ($state, $cookies, ThriftHelper, $rootScope, config,
            Idle, $q, SESSION_STATUS, defaultsettings) {


        $rootScope.user = undefined;
        $rootScope.sessionStatus = SESSION_STATUS.notconnected;

        var tempUser = undefined;

        var thiz = this;

        thiz.init = function () {
            var c = $cookies.get("sessionToken");
            if (angular.isDefined(c)) {
                var promise = thiz.connect();
                if (promise !== false) {
                    promise.then(function () {
                        ThriftHelper.sendRequest(new LoginReq({}), MsgType.LOGIN_REQ, config.calls.WS_LOGIN_INIT);
                    }, function () {
                        $rootScope.$broadcast("init-login-response", {data: {user: null}});
                    });
                }
            } else {
                $rootScope.$broadcast("init-login-response", {data: {user: null}});
            }
        };

        thiz.isLogged = function () {
            return $rootScope.user !== undefined;
        };
        thiz.logout = function () {
            if ($rootScope.sessionStatus !== SESSION_STATUS.notconnected) {
                $rootScope.socket.close(4000);
            }
            thiz.remove();
            $state.go('login');
        };
        thiz.login = function (req) {
            var promise = thiz.connect();
            if (promise !== false) {
                promise.then(function () {
                    tempUser = req;
                    ThriftHelper.sendRequest(new LoginReq(tempUser), MsgType.LOGIN_REQ, config.calls.WS_LOGIN);
                }, function () {
                    $rootScope.$broadcast("login-response", {data: {user: null, error: "WS"}});
                });
            } else {
                $rootScope.$broadcast("login-response", {data: {user: null, error: "WS"}});
            }
        };
        thiz.loginAnonymous = function (req) {
            var promise = thiz.connect();
            if (promise !== false) {
                promise.then(function () {
                    tempUser = req;
                    ThriftHelper.sendRequest(new LoginReq(tempUser), MsgType.LOGIN_REQ, config.calls.WS_LOGIN_ANONYMOUS);
                }, function () {
                    $rootScope.$broadcast("login-anonymous-response", {data: {user: null, error: "WS"}});
                });
            } else {
                $rootScope.$broadcast("login-anonymous-response", {data: {user: null, error: "WS"}});
            }
        };
        thiz.get = function () {
            return $rootScope.user;
        };
        thiz.isAnonymous = function () {
            return angular.isDefined($rootScope.user) && $rootScope.user.role === UserRole.ANONYMOUS;
        };
        thiz.isAnonymousOrTrial = function () {
            return angular.isDefined($rootScope.user) && ($rootScope.user.role >= UserRole.TRIAL);
        };
        thiz.set = function (u, o, c,tc,cs,ui) {
            $rootScope.user = u;
            $rootScope.user.simpleUser = angular.copy(u);
            
            $rootScope.user.organization = o;
            $rootScope.user.cases = c;
            $rootScope.user.twitterChannels = tc;
            $rootScope.user.customSources = cs;
            $rootScope.user.uiTemplate = ui;
            if ($rootScope.user.currentCase === null) {
                $rootScope.user.currentCase = c[0];
            } else {
                $rootScope.user.currentCase = _.findWhere(c, {id: $rootScope.user.currentCase});
                if ($rootScope.user.currentCase === null || $rootScope.user.currentCase === undefined) {
                    $rootScope.user.currentCase = c[0];
                }
            }
            if ($rootScope.user.currentTwitterChannel !== null) {
                $rootScope.user.currentTwitterChannel = _.findWhere(tc, {id: $rootScope.user.currentTwitterChannel});
            }
            if (($rootScope.user.currentTwitterChannel === null || $rootScope.user.currentTwitterChannel === undefined) &&
                tc !== null && angular.isArray(tc) && tc.length > 0) {
                $rootScope.user.currentTwitterChannel = tc[0];
            }
            if($rootScope.user.customSources === null){
                $rootScope.user.customSources = [];
            }
            if($rootScope.user.role !== UserRole.ANONYMOUS) {
                thiz.getScopes();
            }
            if($rootScope.user.uiTemplate === null){
                if($rootScope.user.role === UserRole.TRIAL){
                    $rootScope.user.uiTemplate = defaultsettings.defaultUiTemplate.trial;
                }else if($rootScope.user.role === UserRole.ANONYMOUS){
                    $rootScope.user.uiTemplate = defaultsettings.defaultUiTemplate.anonymous;
                }else{
                    $rootScope.user.uiTemplate = defaultsettings.defaultUiTemplate.user;
                }
            }
            $rootScope.showPopoverInfo = $rootScope.user.role >= UserRole.TRIAL;
        };

        thiz.getScopes = function(){
            var dto = {
                organizationId: $rootScope.user.organizationId
            };
            ThriftHelper.sendRequest(new GetScopeReq(dto), MsgType.GET_SCOPE_REQ, config.calls.WS_SCOPES + "header");
        };

        thiz.setScopes = function (sc) {
            $rootScope.user.scopes = sc;
            $rootScope.user.currentScope = sc[0];
        };

        thiz.remove = function () {
            $cookies.remove("sessionToken");
            $rootScope.user = undefined;
        };

        var getLoginResponse = function (data) {
            var res = {
                user: null,
                error: null};

            if (data.error === null && data.user !== null &&
                (data.user.active === true || data.user.role === UserRole.ANONYMOUS) &&
                data.user.role < UserRole.EXPERIED) {
                $cookies.put("sessionToken",data.sessionToken,{ expires: new Date(data.sessionTokenExpirationTime)});
                thiz.set(data.user, data.organization, data.cases, data.twitterChannels,data.customNewsSources,
                    data.uiTemplate);
                res.user = data.user;
            }else {
                if (data.error === "Cannot read property 'email' of null") {
                    res.error = 'Invalid username or password.';
                } else if (data.user && data.user.active !== true) {
                    res.error = 'User is inactive.';
                } else if (data.user && data.user.role == UserRole.EXPERIED) {
                    res.error = 'User is experied.';
                } else if (data.user && data.user.role == UserRole.LOCKED) {
                    res.error = 'User is locked';
                } else if (data.user && data.user.role == UserRole.INVALID) {
                    res.error = 'User role is invalid.';
                } else {
                    res.error = data.error;
                }
                thiz.logout();
            }
            return res;
        };


        //get login response!!!
        $rootScope.$on(config.calls.WS_LOGIN, function (event, args) {
            var res = getLoginResponse(args.data);
            $rootScope.$broadcast("login-response", {data: res});
        });
        //get init login response!!!
        $rootScope.$on(config.calls.WS_LOGIN_INIT, function (event, args) {
            var res = getLoginResponse(args.data);
            $rootScope.$broadcast("init-login-response", {data: res});
        });
        //get anonymous login response!!!
        $rootScope.$on(config.calls.WS_LOGIN_ANONYMOUS, function (event, args) {
            var res = getLoginResponse(args.data);
            $rootScope.$broadcast("login-anonymous-response", {data: res});
        });

        $rootScope.$on(config.calls.WS_SCOPES + "header", function (event, args) {
            if (angular.isDefined(args.data.scopes)) {
                thiz.setScopes(args.data.scopes);
            }
            $rootScope.$apply();
        });


        //WEBSOCKET

        thiz.connect = function () {

            var deferred = $q.defer();
            if ($rootScope.sessionStatus === SESSION_STATUS.connected ||
                    $rootScope.sessionStatus === SESSION_STATUS.connecting) {
                deferred.resolve();
                return deferred.promise;
            }

            $rootScope.sessionStatus = SESSION_STATUS.connecting;

            try {
                var connSocket = new WebSocket(config.ws);
                connSocket.binaryType = "arraybuffer";
            } catch (err) {
                console.error(err);
            }

            connSocket.onopen = function (event) {
                //console.log("connection open!");
                //try to login throu cookie
                $rootScope.sessionId = UUID.generate();
                $rootScope.socket = connSocket;
                $rootScope.sessionStatus = SESSION_STATUS.connected;
                Idle.watch();
                deferred.resolve();
            };

            //WebSocket on messages listener
            connSocket.onmessage = function (event) {
                var msg = ThriftHelper.getMessage(event.data);
            };

            connSocket.onclose = function (event) {
                console.log("connection closed!");
                $rootScope.sessionStatus = SESSION_STATUS.notconnected;
                Idle.unwatch();
                deferred.reject();
                //logout
                if (event.code !== 4000 && event.code !== 1001 && thiz.isLogged()) {
                    thiz.logout();
                }
                //On close reconnect
                //limitConnections();
            };

            connSocket.onerror = function (event) {
                console.log("connection error!");
                deferred.reject();
            };
            return deferred.promise;
        };


        return this;
    }]);
