

app.controller('scopesManagementCtrl', ['$scope', 'scopeService', 'Session', '$mdDialog', 'adminService', 'config',
    function ($scope, scopeService, Session, $mdDialog, adminService, config) {

        $scope.scopes = [];
        $scope.sources = {};
        $scope.getter = scopeService.getter;
        $scope.init = scopeService.init;
        $scope.user = Session.get();
        scopeService.getScopes($scope.user.organizationId);

        $scope.$watch(function(){ return scopeService.data.scopes;}, function(newVal){
            $scope.scopes = newVal;
            $scope.scopes.forEach(function (sc) {
                sc.range = {"from": sc.fromRange, "to":sc.toRange};
                if(sc.types.length === 1 && sc.sourceIds.length > 0){
                    adminService.getSourcesByIds(sc.types[0], sc.sourceIds);
                }
            });
        });

        $scope.$on(config.calls.WS_ADMIN_SOURCES_BY_ID, function (event, args) {
            if (angular.isDefined(args.data.sources)) {
                args.data.sources.forEach(function (source) {
                    $scope.sources[source.id] = source.name;
                });
            }
            $scope.$apply();
        });

        $scope.editScope = function (row, ev) {
            if(row){
                row = _.find($scope.scopes, function(item){
                    return item.id === row;
                });
            }
            $mdDialog.show({
                controller: 'editScopeCtrl',
                templateUrl: 'app/module/scope/edit-scope.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {
                    currentScope: angular.copy(row)
                }
            })
                .then(function (answer) {
                    scopeService.getScopes($scope.user.organizationId);
                });
        };
    }]);
