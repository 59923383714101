/**
 * Created by timkos on 19/01/17.
 */
angular.module('percipioApp').factory(
    "HeaderOptions", function ($rootScope, $mdDialog, mobile, hotkeys, config, ThriftHelper, Toast, $state, Session) {

        var thiz = this;

        thiz.options = [];

        thiz.set = function (opt) {
            thiz.options = opt;
        };

        var casesObj = {
            all: $rootScope.user.cases,
            selected: $rootScope.user.currentCase
        };

        var channelObj = {
            all: $rootScope.user.twitterChannels,
            selected: $rootScope.user.currentTwitterChannel
        };


        thiz.defaultOptions = {
            switchCase: function (redirect) {
                casesObj = {
                    all: $rootScope.user.cases,
                    selected: $rootScope.user.currentCase
                };
                return {
                    type: 'case',
                    data: casesObj,
                    change: function (c, $event) {
                        if (this.data.selected !== c) {
                            this.data.selected = c;
                            var dto = {
                                email: $rootScope.user.email,
                                currentCase: this.data.selected.id
                            };
                            ThriftHelper.sendRequest(new UpdateCurrentCase(dto),
                                MsgType.UPDATE_CURRENT_CASE, config.calls.WS_SWITCH_CASE);
                        }
                    }
                };
            },
            switchTwitterChannel: function (redirect) {
                channelObj = {
                    all: $rootScope.user.twitterChannels,
                    selected: $rootScope.user.currentTwitterChannel
                };
                return {
                    type: 'channel',
                    data: channelObj,
                    edit: function (ev, channel) {
                        var thiz = this;
                        var d = this.data;
                        ev.stopPropagation();
                        var current = false;
                        if (channel === d.selected) {
                            current = true;
                        }
                        var isAdd = false;
                        if(channel === null){
                            isAdd = true;
                        }
                        $mdDialog.show({
                            controller: 'editTwitterChannelsCtrl',
                            templateUrl: 'app/module/admin/twitter-channels/twitter-channels-edit.html',
                            parent: angular.element(document.body),
                            targetEvent: ev,
                            clickOutsideToClose: false,
                            locals: {
                                currentTwitterChannel: channel===null? null:angular.copy(channel),
                                addToOrganization: true
                            }
                        }).then(function (c) {
                            if (current) {
                                if(c === "remove") {
                                    $state.reload();
                                }else{
                                    $rootScope.user.currentTwitterChannel = c;
                                    d.selected = c;
                                    $state.reload();
                                }
                            }
                            if(isAdd){
                                if(d.selected === undefined){
                                    d.selected = $rootScope.user.currentTwitterChannel;
                                    $state.reload();
                                }
                            }
                        });
                    },
                    change: function (c, $event) {
                        if (this.data.selected !== c) {
                            this.data.selected = c;
                            var dto = {
                                email: $rootScope.user.email,
                                currentTwitterChannel: this.data.selected.id
                            };
                            ThriftHelper.sendRequest(new UpdateCurrentTwitterChannel(dto),
                                MsgType.UPDATE_CURRENT_TWITTER_CHANNEL, config.calls.WS_SWITCH_TWITTER_CHANNEL);
                        }
                    },
                    canAdd: function(){
                        if(!angular.isObject($rootScope.user) || $rootScope.user.role > UserRole.ORGANIZATION){
                            return false;
                        }
                        var numActive = _.where($rootScope.user.twitterChannels,{active:true}).length;
                        if(numActive < $rootScope.user.organization.maxNoOfTwitterChannels){
                            return true;
                        }
                        return false;
                    },
                    canEdit: function(){
                        if(!angular.isObject($rootScope.user) || $rootScope.user.role > UserRole.ORGANIZATION){
                            return false;
                        }
                        return true;
                    }
                };
            },
            selectCustomSource: function (redirect,keywords) {
                return {
                    type: 'source',
                    data: $rootScope.user.customSources,
                    edit: function (ev, source) {
                        var thiz = this;
                        ev.stopPropagation();
                        var current = false;
                        var isAdd = false;
                        if(source === null){
                            isAdd = true;
                        }else{
                            current = thiz.isSelected(source);
                        }
                        $mdDialog.show({
                            controller: 'editSourceCtrl',
                            templateUrl: 'app/module/admin/sources/editSource.html',
                            parent: angular.element(document.body),
                            targetEvent: ev,
                            clickOutsideToClose: false,
                            locals: {
                                currentSource: source===null? null:angular.copy(source),
                                addToOrganization: true,
                                fromAdmin: false
                            }
                        }).then(function (c) {
                            if(c === "remove"){
                                thiz.data = $rootScope.user.customSources
                            }
                        });
                    },
                    change: function (c, $event) {
                        keywords.addSingleKeyword("s:"+c.id+"|"+c.name);
                        keywords.setValid();
                        redirect();
                    },
                    isSelected: function (c) {
                        return _.where(keywords.data,{id: c.id}).length > 0;
                    },
                    canAdd: function(){
                        var numCustomSources = $rootScope.user.customSources.length;
                        if(numCustomSources < $rootScope.user.organization.maxNoOfCustomNewsSources){
                            return true;
                        }
                        return false;
                    }
                };
            },
            layouts: function (layouts, redirect, scope,setLayout) {
                hotkeys.bindTo(scope)
                    .add({
                        combo: 'ctrl+k',
                        description: 'Previous layout',
                        callback: function () {
                            var l = layouts;
                            var idx = _.findIndex(l.all, {name: l.selected.name});
                            if (idx !== -1) {
                                l.selected = l.all[(l.all.length + idx - 1) % l.all.length];
                                redirect();
                            }
                        }
                    })
                    .add({
                        combo: 'ctrl+l',
                        description: 'Next layout',
                        callback: function () {
                            var l = layouts;
                            var idx = _.findIndex(l.all, {name: l.selected.name});
                            if (idx !== -1) {
                                l.selected = l.all[(idx + 1) % l.all.length];
                                redirect();
                            }
                        }
                    });

                return {
                    type: 'layout',
                    data: layouts,
                    edit: function (ev, layoutIdx) {
                        var thiz = this;
                        var d = this.data;
                        ev.stopPropagation();
                        var current = false;
                        var m = d.mobile;
                        if (layoutIdx !== null &&
                            d.all[layoutIdx] === d.selected) {
                            current = true;
                        }
                        $mdDialog.show({
                            controller: 'layoutCtrl',
                            templateUrl: 'app/module/dialogs/layout/layout.html',
                            parent: angular.element(document.body),
                            targetEvent: ev,
                            locals: {
                                layoutIdx: layoutIdx
                            }
                        }).then(function (isRemove) {
                            setLayout();
                            if (current) {
                                if (m !== mobile()) {
                                    redirect();
                                } else if (isRemove) {
                                    redirect();
                                } else {
                                    d.selected = d.all[layoutIdx];
                                    redirect();
                                }
                            }
                        });
                    },
                    change: function (layout, $event) {
                        if (this.data.selected !== layout) {
                            this.data.selected = layout;
                            redirect();
                        }
                    }
                };
            },
            webSearch: function (keywords) {
                return {
                    type: 'button',
                    name: "See web results",
                    disabled: function () {
                        return keywords.isEmpty();
                    },
                    icon: 'google',
                    action: function (ev) {
                        var mustWords = [];
                        var shouldWords = [];
                        var notWords = [];
                        for (var i = 0; i < keywords.data.length; i++) {
                            var word = keywords.data[i];
                            if (word.type.short === "m") {
                                mustWords.push(word.word);
                            } else if (word.type.short === "s") {
                                shouldWords.push(word.word);
                            } else if (word.type.short === "n") {
                                notWords.push(word.word);
                            }
                        }
                        var webQuery = "";
                        for (var i = 0; i < mustWords.length; i++) {
                            if (i === 0) {
                                webQuery += "\"";
                            }
                            if (i > 0) {
                                webQuery += " ";
                            }
                            webQuery += mustWords[i];
                            if (i === mustWords.length - 1) {
                                webQuery += "\"";
                            }
                        }
                        if (mustWords.length > 0) {
                            webQuery += " ";
                        }
                        for (var i = 0; i < shouldWords.length; i++) {
                            if (i > 0) {
                                webQuery += " ";
                            }
                            webQuery += shouldWords[i];
                        }
                        if (shouldWords.length > 0) {
                            webQuery += " ";
                        }
                        for (var i = 0; i < notWords.length; i++) {
                            if (i > 0) {
                                webQuery += " ";
                            }
                            webQuery += "-";
                            webQuery += notWords[i];
                        }

                        var win = window.open('http://google.com/search?q=' + webQuery);
                        win.focus();
                    }
                };
            },
            switchScope: function (redirect) {
                return {
                    type: 'scope',
                    getData: function () {
                        if(angular.isDefined($rootScope.user))
                            return $rootScope.user.scopes;
                        else
                            return [];
                    },
                    getSelected: function () {
                        if(angular.isDefined($rootScope.user))
                            return $rootScope.user.currentScope;
                        else
                            return {}
                    },
                    edit: function ($event,c) {
                        $mdDialog.show({
                            controller: 'editScopeCtrl',
                            templateUrl: 'app/module/scope/edit-scope.html',
                            parent: angular.element(document.body),
                            targetEvent: $event,
                            clickOutsideToClose: false,
                            locals: {
                                currentScope: angular.copy(c)
                            }
                        })
                            .then(function (answer) {
                                Session.getScopes();
                            });
                    },
                    change: function (c, $event) {
                        $rootScope.user.currentScope = c;
                        //functionality to be determined
                    }
                };
            }
        };

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState) {
            if(toState.name !== "app.dashboard.item" && fromState.name !== "app.dashboard.item") {
                thiz.options = [];
            }
        });

        $rootScope.$on(config.calls.WS_SWITCH_CASE, function (event, args) {
            if (args.data === null) {
                Toast.success("Current case was successfully updated.");
                $rootScope.user.currentCase = casesObj.selected;
                $state.reload();
            } else if (args.data instanceof ErrorMsg) {
                Toast.error(args.data.errorDesc);
                casesObj.selected = $rootScope.user.currentCase;
            }
            $rootScope.$apply();
        });

        $rootScope.$on(config.calls.WS_SWITCH_TWITTER_CHANNEL, function (event, args) {
            if (args.data === null) {
                Toast.success("Current twitter channel was successfully updated.");
                $rootScope.user.currentTwitterChannel = channelObj.selected;
                $state.reload();
            } else if (args.data instanceof ErrorMsg) {
                Toast.error(args.data.errorDesc);
                channelObj.selected = $rootScope.user.currentCase;
            }
            $rootScope.$apply();
        });

        return thiz;
    });
