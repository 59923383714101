

app.controller('adminCtrl', ['$scope', '$state',
    function ($scope, $state) {

        $scope.tabs = [
            {id: 'users', name: 'Users', link: 'app.admin.users'},
            {id: 'organizations', name: 'Organizations', link: 'app.admin.organizations'},
            {id: 'cases', name: 'Cases', link: 'app.admin.cases'},
            {id: 'channels', name: 'Twitter channels', link: 'app.admin.channels'},
            {id: 'current', name: 'Current', link: 'app.admin.current'},
            {id: 'history', name: 'History', link: 'app.admin.history'},
            {id: 'sources', name: 'Sources', link: 'app.admin.sources'}
        ];
        $scope.goTo = function(item){
            $state.go(item.link);
        };

        $scope.selectedIndex = _.findIndex($scope.tabs,{link: $state.current.name});
    }]);
