
/* global angular, app */

/**
 * app Session for user data info
 **/
app.service('Auth', ['$state', '$rootScope','Session',
    function ($state, $rootScope,Session) {

        var thiz = this;

        thiz.isAuth = function (toState, toParams) {
            if($rootScope.loading){
                return true;
            }
            if (Session.isLogged()) {//if logged in
                if (toState.name.indexOf('app')===-1 && $rootScope.user.role !== UserRole.ANONYMOUS) {
                    $state.go('app.dashboard.item',{ id:0 }, {location: 'replace'});
                    return false;
                }
            } else { //if not logged in
                if (toState.name.indexOf('app')>-1) {
                    $state.go('login',{}, {location: 'replace'});
                    return false;
                }
            }
            if(toState.auth && !toState.auth(Session, toParams)){
                $state.go('login',{}, {location: 'replace'});
                return false;
            }
            return true;
        };

        $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
            if (toState.redirectTo) {
                event.preventDefault();
                var newParams = toState.redirectToParams;
                if(toParams){
                    _.assign(newParams,toParams);
                }
                $state.go(toState.redirectTo, newParams, {location: 'replace'})
            }
            //console.log(toState,toParams);
            if (!thiz.isAuth(toState, toParams)) {
                event.preventDefault();
            }else{
                 //console.log('yes! '+toState.name+" "+toParams.q);
            }
        });

        return this;
    }]);