
app.run(function (config, ThriftHelper, $rootScope, Session, $state, Auth, Toast,$cookies) {
    //waiting for user!!!
    $rootScope.loading = true;

    //response of trying to login
    $rootScope.$on("init-login-response", function (event, args) {
        if(args.data.error && args.data.error !== null){
            //console.log(args);
            Toast.error(args.data.error);
        }
        $rootScope.loading = false;
        $rootScope.$apply();
        Auth.isAuth($state.current,$state.params);
        if (Session.isLogged()) {
            if($rootScope.user.role !== UserRole.ANONYMOUS){
                var userData = "Logged in as " + $rootScope.user.firstName;
                if ($rootScope.user.lastName) userData += " " + $rootScope.user.lastName;
                Toast.success(userData);
            }
        }
    });
    
    $rootScope.$on('IdleStart', function() {
        // the user appears to have gone idle
        if (Session.isLogged()) {
            Toast.warning("You will be logged out in 30 seconds.");
            $rootScope.$apply();
        }
    });

    $rootScope.$on('IdleTimeout', function() {
        // the user has timed out (meaning idleDuration + timeout has passed without any activity)
        // this is where you'd log them
        
        if (Session.isLogged()) {
            Toast.warning("You have been logged out for inactivity.");
            Session.logout();
            $rootScope.$apply();
        }
    });


    moment.updateLocale('en',config.moment);
    moment.locale('en');

    //WebSocket Connection
    Session.init();
});