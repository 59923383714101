/**
 * Created by Matej on 31.7.2014.
 */

/**
 *  controller for home page
 **/

app.controller('loginCtrl', ['$scope', 'Toast', '$state', 'Session', '$rootScope','$timeout',
    function ($scope, Toast, $state, Session, $rootScope, $timeout) {
        
        $scope.form = {};
        $scope.title = "Percipio Login";

        $scope.login = {
            email: "",
            passwd: ""
        };

        $scope.loging = false;
        $scope.error = '';

        $scope.loginClick = function () {
            $scope.loging = true;
            $scope.error = '';
            if ($scope.form.login.$valid) {
                Session.login($scope.login);
            }else{
                $scope.error = "Please fill in login credentials.";
                $scope.loging = false;
            }
        };

        $scope.tryClick = function(){
            $state.go("trial");
        };
        $scope.signUpClick = function(){
            $state.go("signup");
        };

        $scope.$on("login-response", function (event, args) {
            if (args.data.error === null) {
                var userData = "Logged in as " + $rootScope.user.firstName;
                if ($rootScope.user.lastName) userData += " " + $rootScope.user.lastName;
                Toast.success(userData);
                $state.go('app.dashboard',{}, {location: 'replace'});
            } else {
                if (args.data.error === "WS") {
                    Toast.error("Can't connect to the server...");
                } else {
                    $scope.error = args.data.error;
                }
                $scope.loging = false;

                $scope.$apply();
            }
        });

    }]);
