/**
 * Created by matejk on 2.10.2014.
 */

app.directive("loading", ["$window", "Session", "$state", '$stateParams', "$rootScope",
    function ($window, Session, $state, $stateParams, $rootScope) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/directives/loading/loading.html',
            scope: {
                text: '@',
                type: '@'
            },
            link: function ($scope, $elm, $attrs) {
                if (!angular.isDefined($scope.text)) {
                    $scope.text = 'Loading...';
                }
                switch ($scope.type) {
                    case 'small':
                        $scope.class = 'loading-sm';
                        $scope.diameter = '30';
                        break;
                    case 'inline':
                        $scope.class = 'loading-inline';
                        $scope.diameter = '30';
                        break;
                    default :
                        $scope.type = 'large';
                        $scope.class = 'loading-lg';
                        $scope.diameter = '50';
                }
            }
        };
    }]);