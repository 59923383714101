'use strict';

angular.module('percipioApp').service('ThriftHelper', ['$window', '$rootScope','$cookies','$log',
    function ($window, $rootScope,$cookies,$log) {
        
        function getErrorCodeString(code){
            switch(code){
                case ErrorCode.GENERIC: return "GENERIC";
                case ErrorCode.TIMEOUT: return "TIMEOUT";
                case ErrorCode.UNKNOWN_MSG_TYPE: return "UNKNOWN_MSG_TYPE";
            }
            return "UNKNOWN";
        }
        
        function getMsgTypeString(type){
            switch(type){
                case MsgType.ERROR: return "ERROR";
                case MsgType.EMPTY: return "EMPTY";
                case MsgType.LOGIN_RESP: return "LOGIN_RESP";
                case MsgType.GET_USER_RESP: return "GET_USER_RESP";
                case MsgType.GET_ORGANIZATION_RESP: return "GET_ORGANIZATION_RESP";
                case MsgType.GET_CASE_RESP: return "GET_CASE_RESP";
                case MsgType.GET_TWITTER_CHANNEL_RESP: return "GET_TWITTER_CHANNEL_RESP";
                case MsgType.UPDATE_TWITTER_CHANNEL_RESP: return "UPDATE_TWITTER_CHANNEL_RESP";
                case MsgType.SEARCH_ES_RESP: return "SEARCH_ES_RESP";
                case MsgType.SEARCH_ORGANIZATION_RESP: return "SEARCH_ORGANIZATION_RESP";
                case MsgType.SEARCH_VOCABULARY_RESP: return "SEARCH_VOCABULARY_RESP";
                case MsgType.DELETE_ORGANIZATION: return "DELETE_ORGANIZATION";
                case MsgType.DELETE_USER: return "DELETE_USER";
                case MsgType.GET_CAPTCHA_RESP: return "GET_CAPTCHA_RESP";
                case MsgType.SIGNUP_RESP: return "SIGNUP_RESP";
                case MsgType.SEARCH_VOCABULARY_HISTORY_RESP: return "SEARCH_VOCABULARY_HISTORY_RESP";
                case MsgType.GET_SESSION_RESP: return "GET_SESSION_RESP";
                case MsgType.GET_SOURCE_REQ: return "GET_SOURCE_REQ";
                case MsgType.GET_SOURCE_STAT_REQ: return "GET_SOURCE_STAT_REQ";
                case MsgType.GET_SCOPE_REQ: return "GET_SCOPE_REQ";
                case MsgType.GET_WEBSHOT_REQ: return "GET_WEBSHOT_REQ";
                case MsgType.GET_ES_REQ: return "GET_ES_REQ";
                case MsgType.GET_UI_TEMPLATE_RESP: return "GET_UI_TEMPLATE_RESP";
                case MsgType.GET_UI_TEMPLATES_RESP: return "GET_UI_TEMPLATES_RESP";
                case MsgType.UPDATE_UI_TEMPLATE_RESP: return "UPDATE_UI_TEMPLATE_RESP";
                case MsgType.CHECK_NEWS_FEED_RESP: return "CHECK_NEWS_FEED_RESP";
                case MsgType.GET_CHECKOUT_SESSION_RESP: return "GET_CHECKOUT_SESSION_RESP";
                case MsgType.SET_CHECKOUT_STATUS_RESP: return "SET_CHECKOUT_STATUS_RESP";
            }
            return "UNKNOWN";
        }
        
        function returnParam(param) {
                return param;
            }
            function nextReqId() {
                if (typeof $rootScope.reqId === 'undefined')
                    $rootScope.reqId = 0;

                return $rootScope.reqId++;
            }
             function popReqQueueItem(reqId) {
                if (typeof $rootScope.reqQueue === 'undefined')
                    $rootScope.reqQueue = [];

                var tmpReq = null;
                for (var i = 0; i < $rootScope.reqQueue.length; i++)
                {
                    if ($rootScope.reqQueue[i].id == reqId)
                    {
                        tmpReq = $rootScope.reqQueue[i].function;
                        $rootScope.reqQueue.splice(i, 1);
                        break;
                    }
                }
                return tmpReq;
            }
            function pushReqQueueItem(item) {
                if (typeof $rootScope.reqQueue === 'undefined')
                    $rootScope.reqQueue = [];

                $rootScope.reqQueue.push(item);
            }
            function generateRequest(tdata, type, funType) {
                //console.log(tdata);
                var tProtocol = new Thrift.TBinaryProtocol({});
                tdata.write(tProtocol);
                
                var tmpReqId = nextReqId();
                pushReqQueueItem({
                    id: tmpReqId,
                    function: funType
                });

                var token = $cookies.get("sessionToken");
                if(token && type === MsgType.LOGIN_REQ && tdata.email !== null){
                    token = undefined;
                }
                var msg = new Msg({'type': type, 'value':new Uint8Array(tProtocol.buffer), 'reqId': tmpReqId,
                    sessionId:$rootScope.sessionId, sessionToken: token});
                $log.debug("send msg", tmpReqId,msg,tdata);
                var msgProt = new Thrift.TBinaryProtocol({});
                msg.write(msgProt);
                var req = [new Uint8Array(msgProt.buffer), tmpReqId];
                
                //console.log("Msg send: "+tmpReqId);
                return req;
            }
            function sendRequest(tdata, type, funType) {
                //console.log(tdata);
                var reqArr = generateRequest(tdata, type, funType);
                var req = reqArr[0];
                var reqId = reqArr[1];

                if($rootScope.socket.readyState === 1){
                    $rootScope.socket.send(req, {binary: true});
                    return reqId;
                }
                return -1;
            }
            function getResponse(data) {
//                var byteCharacters = atob(data);
//                var byteNumbers = new Array(byteCharacters.length);
//                for (var i = 0; i < byteCharacters.length; i++) {
//                    byteNumbers[i] = byteCharacters.charCodeAt(i);
//                }
//
//                var responseProt = new Thrift.TBinaryProtocol({});
//                responseProt.buffer = new Uint8Array(byteNumbers);
//
//                var responseData = new StringTest();
//
//                responseData.read(responseProt);
//
//                return responseData;
            }
            function getMessage(data) {
                var MessageProt = new Thrift.TBinaryProtocol({});
                MessageProt.buffer = new Uint8Array(data);
                var msg = new Msg();
                msg.read(MessageProt);
                //console.log("Msg recived: ",msg);
                
                var ValueProt = new Thrift.TBinaryProtocol({});
                ValueProt.buffer = new Uint8Array(msg.value);
                var valueObject = getValueObject(msg.type);
                if(valueObject !== null){
                    valueObject.read(ValueProt);
                    msg.value = valueObject;
                }else{
                    msg.value = null;
                }

                $log.debug("recived msg", msg.reqId,msg);
                
                if(msg.type === MsgType.ERROR){
                    console.error("Error response: "+getErrorCodeString(msg.value.code)+ " ("
                        + getMsgTypeString(msg.value.msgType)+"):"+msg.value.errorDesc);
                    //return;
                }
                if(valueObject === null && msg.type !== MsgType.EMPTY){
                    console.error("Unsopported response!");
                    console.error(msg);
                }

                var sendData = {data: msg.value, err: false, reqId: msg.reqId};
                if(msg.type === MsgType.LOGIN_RESP){
                    sendData.data.sessionToken = msg.sessionToken; //add session token
                }
                var funType = popReqQueueItem(msg.reqId);
                //console.debug(msg.value);
                $rootScope.$broadcast(funType, sendData);
            }
            function getValueObject(type) {
                switch(type){
                    case MsgType.ERROR: return new ErrorMsg();
                    case MsgType.EMPTY: return null;
                    case MsgType.LOGIN_RESP: return new LoginResp();
                    case MsgType.GET_USER_RESP: return new GetUserResp();
                    case MsgType.GET_ORGANIZATION_RESP: return new GetOrganizationResp();
                    case MsgType.GET_CASE_RESP: return new GetCaseResp();
                    case MsgType.GET_TWITTER_CHANNEL_RESP: return new GetTwitterChannelResp();
                    case MsgType.UPDATE_TWITTER_CHANNEL_RESP: return new UpdateTwitterChannelResp();
                    case MsgType.SEARCH_ES_RESP: return new SearchEsResp();
                    case MsgType.SEARCH_ORGANIZATION_RESP: return new SearchOrganizationResp();
                    case MsgType.SEARCH_VOCABULARY_RESP: return new SearchVocabularyResp();
                    case MsgType.DELETE_ORGANIZATION: return new DeleteOrganization();
                    case MsgType.DELETE_USER: return new DeleteUser();
                    case MsgType.SIGNUP_RESP: return new SignupResp();
                    case MsgType.GET_CAPTCHA_RESP: return new GetCaptchaResp();
                    case MsgType.SEARCH_VOCABULARY_HISTORY_RESP: return new SearchVocabularyHistoryResp();
                    case MsgType.GET_SESSION_RESP: return new GetSessionResp();
                    case MsgType.GET_SOURCE_RESP: return new GetSourceResp();
                    case MsgType.GET_SOURCE_STAT_RESP: return new GetSourceStatResp();
                    case MsgType.GET_SCOPE_RESP: return new GetScopeResp();
                    case MsgType.SEARCH_VOCABULARY2_RESP: return new SearchVocabulary2Resp();
                    case MsgType.GET_WEBSHOT_RESP: return new GetWebshotResp();
                    case MsgType.GET_ES_RESP: return new GetEsResp();
                    case MsgType.GET_UI_TEMPLATE_RESP: return new GetUiTemplateResp();
                    case MsgType.GET_UI_TEMPLATES_RESP: return new GetUiTemplatesResp();
                    case MsgType.UPDATE_UI_TEMPLATE_RESP: return new UpdateUiTemplateResp();
                    case MsgType.CHECK_NEWS_FEED_RESP: return new CheckNewsFeedResp();
                    case MsgType.GET_CHECKOUT_SESSION_RESP: return new GetCheckoutSessionResp();
                    case MsgType.SET_CHECKOUT_STATUS_RESP: return new SetCheckoutStatusResp();
                }
                return null;
            }


        return {
            returnParam: returnParam,
            nextReqId: nextReqId,
            popReqQueueItem: popReqQueueItem,
            pushReqQueueItem: pushReqQueueItem,
            generateRequest: generateRequest,
            sendRequest:sendRequest,
            getMessage: getMessage,
            getValueObject: getValueObject
        };
    }]);
