
app.controller('adminUsersCtrl', ['$scope', 'config', 'Toast', 'ThriftHelper', '$mdDialog', 'adminService','Session',
    function ($scope, config, Toast, ThriftHelper, $mdDialog, adminService,
              Session) {

        $scope.users = adminService.data.users;
        $scope.organizations = adminService.data.organizations;
        $scope.cases = adminService.data.cases;
        $scope.twitterChannels = adminService.data.twitterChannels;
        $scope.getter = adminService.getter;
        $scope.init = adminService.init;

        $scope.adminService = adminService;

        adminService.getUsers();
        adminService.getOrganizations();
        adminService.getCases();

        $scope.$watch(function(){ return adminService.data.users;}, function(newVal){
            newVal.forEach(function (user) {
                user.email2 = user.email; //duplicate email, because of md-table issue
            });
            $scope.users = newVal;
        });
        $scope.$watch(function(){ return adminService.data.organizations;}, function(newVal){
            $scope.organizations = newVal;
        });
        $scope.$watch(function(){ return adminService.data.cases;}, function(newVal){
            $scope.cases = newVal;
        });
        $scope.$watch(function(){ return adminService.data.twitterChannels;}, function(newVal){
            $scope.twitterChannels = newVal;
        });

        $scope.isNotMe = function(email){
            return Session.get().email !== email;
        }


    }]);
