/* global angular, app */

app.factory('HitModel', ['defaultsettings',
    function (defaultsettings) {

        /**
         * Create new document item.
         * @param object represents response data from logedin rest
         * @constructor
         */

        function HitModel(object) {
            this.id = object.id;
            this.title = object.title;
            this.url = object.url;
            this.url = composeUrl(object);
            this.type = object.type;
            this.abstract = object.summary;
            this.authors = object.authors;
            this.authorsString = getAuthorString(this.authors);
            this.assignee = object.assignee;
            this.classification = object.classification;
            this.issn = object.issn;
            this.keywords = object.keywords;
            this.year = object.year;
            this.date = object.date;
            this.dateObj = getDateObj(this.date);
            this.journal = object.journal;
            this.references = object.references;
            this.source = object.source;
            this.volume = object.volume;
            this.issue = object.issue;
            this.doi = object.doi;
            this.applicationDate = object.publicationDate; // wrong index name
            this.priorityDate = object.priorityDate;
            this.urls = object.urls;
            this.fos = object.fos;
            this.selected = false;

            var t = this.type;
            var art = _.find(defaultsettings.articleTypes, function (item) {
                return t === item.type;
            });
            if (angular.isDefined(art)) {
                this.typeTitle = art.text;
            } else {
                this.type = defaultsettings.articleTypes[1].type;
                this.typeTitle = defaultsettings.articleTypes[1].text;
            }
        }

        composeUrl = function (object) {
            if (angular.isDefined(object.url) && object.url !== "") {
                return object.url;
            }
            if (angular.isDefined(object.urls) && object.urls.length > 0) {
                return _.first(object.urls);
            }
            return "";
        };

        HitModel.prototype.getUrl = function () {
            composeUrl(this);
            /*if(angular.isDefined(this.url) && this.url !== "" ){
                return this.url;
            }
            if(!angular.isDefined(this.type) || (this.type === "article")){
                return "http://dx.doi.org/"+this.id;
            }
            return "";*/
        };

        HitModel.prototype.exportBibTex = function () {
            var exportText = "@article{" + this.id + ",\n" +
                " title = \"" + this.title + "\",\n" +
                " author = \"" + getAuthorBibtexString(this.authors) + "\",\n";

            if (this.source) {
                exportText += " journal = \"" + this.source + "\",\n";
            }
            if (this.year) {
                exportText += " year = \"" + this.year + "\",\n";
            }
            if (this.volume) {
                exportText += " volume = \"" + this.volume + "\",\n";
            }
            if (this.issue) {
                exportText += " number = \"" + this.issue + "\",\n";
            }
            if (this.url) {
                exportText += " doi = \"" + this.url + "\", \n" + " url = \"" + this.url + "\", \n";
            }
            if (this.abstract) {
                exportText += " abstract = \"" + this.abstract + "\", \n";
            }

            exportText += "}\n";

            return exportText;
        };

        HitModel.prototype.exportRIS = function () {
            var authors = "";
            for (var i in this.authors) {
                authors += "AU  - " + this.authors[i].name + "\n";
            }

            var exportText = "TY  - JOUR\n" +
                "T1  - " + this.title + " \n" +
                authors;
            if (this.source) {
                exportText += "JO  - " + this.source + "\n";
            }
            if (this.year) {
                exportText += "PY  - " + this.year + "\n";
            }
            if (this.volume) {
                exportText += "VL  - " + this.volume + "\n";
            }
            if (this.issue) {
                exportText += "IS  - " + this.issue + "\n";
            }
            if (this.url) {
                exportText += "DO  - " + this.url + " \n" + "UR  - " + this.url + " \n";
            }
            if (this.abstract) {
                exportText += "AB  - " + this.abstract + " \n";
            }

            exportText += "ER  - \n";

            return exportText;
        };

        HitModel.prototype.exportPDF = function (withDetails) {
            var arr = [
                {text: this.title, style: 'title'}
            ];
            if (this.url) {
                arr[0].link = this.url;
            }

            if (this.type === "news") {
                arr.push({text: this.date + ", " + this.journal, style: "details"})
                if (this.abstract) {
                    arr.push({text: this.abstract, style: 'abstract'});
                }
                return arr;
            }

            arr.push({text: this.authorsString, style: 'author'});
            var d = [];
            if (this.source) {
                d.push(this.source);
            }
            if (this.year) {
                d.push(this.year);
            }
            if (this.volume) {
                d.push(this.volume);
            }
            if (this.issue) {
                d.push(this.issue);
            }
            if (d.length > 0) {
                arr.push({text: d.join(", "), style: "details"});
            }

            if (this.abstract) {
                arr.push({text: this.abstract, style: 'abstract'});
            }
            if (this.keywords && this.keywords.length > 0) {
                arr.push({text: ["Keywords: ", this.keywords.join(", ")], style: 'keywords', margin: [0, 5, 0, 0]});
            }

            return arr;
        };

        HitModel.prototype.exportCSV = function (delimiter) {
            var arr = [this.title,
                getAuthorArray(this.authors).join(delimiter === "," ? "|" : ", "),
                this.type,
                this.type === "patent" ? "https://worldwide.espacenet.com/patent/search?q=" + this.id : this.url,
                this.doi,
                this.year,
                this.date,
                this.source,
                this.journal,
                this.volume,
                this.issue,
                this.keywords.join(delimiter === "," ? "|" : ", "),
                this.abstract,
                getAssigneesArray(this.assignee).join(delimiter === "," ? "|" : ", "),
                this.type === "patent" ? this.id : undefined,//patent number
                this.type === "patent" ? this.priorityDate : undefined,
                this.type === "patent" ? this.applicationDate : undefined,
                this.type === "patent" ? this.date : undefined//publishing date
            ];

            if (this.type === "news") {
                arr = [
                    this.date,
                    this.journal,
                    this.title,
                    this.url,
                    this.abstract
                ];
            }

            for (var i = 0; i < arr.length; i++) {
                if (arr[i] === null || arr[i] === undefined) {
                    arr[i] = "";
                } else {
                    arr[i] += "";
                }
                arr[i] = arr[i].replace(new RegExp(delimiter, 'g'), "").replace(new RegExp("\n", 'g'), " ");
                if (arr[i].split("\"").length % 2 === 0) {
                    arr[i] += "\"";
                }
            }
            return arr.join(delimiter);
        };

        HitModel.prototype.clone = function () {
            var cloned = angular.copy(this);

            // Remove as it's angular specific.
            delete cloned.$$hashKey;

            return cloned;
        };

        var getAssigneesArray = function (assignees) {
            var arr = [];
            for (var i in assignees) {
                if (assignees[i].name) {
                    var s = assignees[i].name;
                    if (assignees[i].org) {
                        s += " (" + assignees[i].org + ")";
                    }
                    arr.push(s);
                } else if (assignees[i].org) {
                    arr.push(assignees[i].org);
                }
            }
            return arr;
        };

        var getAuthorString = function (authors) {
            var arr = [];
            for (var a in authors) {
                arr.push(authors[a].name);
            }
            return arr.join(", ");
        };

        var getAuthorArray = function (authors) {
            var arr = [];
            for (var a in authors) {
                arr.push(authors[a].name);
            }
            return arr;
        };

        var getAuthorBibtexString = function (authors) {
            var arr = [];
            for (var a in authors) {
                arr.push(authors[a].name);
            }
            return arr.join(" and ");
        };

        var getDateObj = function (date) {
            if (!angular.isString(date)) {
                return {d: '', m: '', y: ''};
            }
            var arr = date.split("-");
            if (arr.length < 3) {
                return {d: '', m: '', y: ''};
            }
            return {d: arr[2], m: arr[1], y: arr[0]};
        };

        // Return constructor
        return HitModel;
    }
]);
