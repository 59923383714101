app.directive('details',
    [
        'ThriftHelper', 'config', '$timeout', '$stateParams', 'HitModel', '$anchorScroll', '$location','ExportService',
        'exportableDetailsHitModel', 'DashboardService',
        function (ThriftHelper, config, $timeout, $stateParams, HitModel, $anchorScroll, $location, ExportService,
                  exportableDetailsHitModel,DashboardService) {
            return {
                restrict: "E",
                replace: true,
                templateUrl: 'app/directives/details/details.html',
                scope: {
                    options: "="
                },
                link: function (scope, element, attrs) {
                    scope.service = DashboardService;
                    scope.id = "details-" + UUID.generate();
                    scope.item = null;
                    scope.history = [];

                    var eventId = 'onItemSelected_' + scope.options.detailsId;
                    scope.$on(eventId, function (event, item) {
                        // when document is selected outside of Details component, we would like to reset items stack to
                        // reset "back" button history
                        scope.selectItem(item);
                    });

                    scope.selectItem = function(item) {
                        var arr = scope.history.slice();
                        if(scope.item !== null) {
                            arr.unshift(scope.item);
                        }
                        scope.history = _.filter(arr, function (i) {
                            return i.id !== item.id;
                        });
                        scope.item = item;
                        var divId = '#' + scope.id + " .details-inner";
                        $(divId).animate({ scrollTop: 0 }, 'fast');
                    };

                    scope.openMenu = function(open, ev) {
                        open(ev);
                    };
                }
            };
        }
    ]);