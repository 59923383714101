/**
 * Created by Matej on 31.7.2014.
 */

/**
 *  controller for home page
 **/

app.controller('masterCtrl', ['$scope', '$window', '$rootScope', 'mobile',
    function ($scope, $window, $rootScope, mobile) {
        
        var win = angular.element($window);
        
        var getScreen = function(){
            return {mobile: mobile(), width:win.width(), height:win.height()};
        };
        $rootScope.screen = getScreen();
        
        var onResize = _.debounce(function () {
            $rootScope.screen = getScreen();
            $scope.$broadcast('resize', $rootScope.screen);
            $scope.$apply();
        }, 200);

        win.on('resize', onResize);

        $scope.$on("$destroy", function () {
            win.off('resize', onResize);
        });
    }]);
