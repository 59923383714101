/**
 * Created by matejk on 2.10.2014.
 */

app.directive("header", ["$window", "Session", "$state", '$stateParams', 'Toast', '$location',
    "$mdSidenav", '$mdDialog', 'HeaderOptions', 'hotkeys', 'mobile', 'config', 'ThriftHelper',
    function ($window, Session, $state, $stateParams, Toast, $location,
              $mdSidenav, $mdDialog, HeaderOptions, hotkeys, mobile, config, ThriftHelper) {
        return {
            restrict: "E",
            replace: false,
            templateUrl: 'app/directives/header/header.html',
            scope: {},
            link: function (scope, element, attrs) {
                scope.session = Session;
                scope.params = $stateParams;
                scope.service = HeaderOptions;
                scope.mobile = mobile;

                scope.currentPage = $state.current.name;
                scope.currentParams = $stateParams;
                scope.$on('$stateChangeSuccess', function (event, toState, toParams) {
                    scope.currentPage = toState.name;
                    scope.currentParams = toParams;
                });

                scope.toggleSidenav = function () {
                    $mdSidenav('left').toggle();
                };

                var originatorEv;
                scope.openMenu = function (open, ev) {
                    originatorEv = ev;
                    open(ev);
                };
                scope.isAdmin = function () {
                    var user = scope.session.get();
                    return angular.isDefined(user) && user.role === UserRole.ADMIN;
                };
                scope.isPaymentAvailable = function () {
                    var user = scope.session.get();
                    return angular.isDefined(user) && (user.role === UserRole.TRIAL || user.role === UserRole.CONSUMER) && config.ws.startsWith('wss://dev'); // enable temporary for dev
                };

                scope.isAdminOrganization = function () {
                    var user = scope.session.get();
                    return angular.isDefined(user) && (user.role === UserRole.ADMIN || user.role === UserRole.ORGANIZATION);
                };

                scope.isAnonymous = function () {
                    var user = scope.session.get();
                    return angular.isDefined(user) && user.role === UserRole.ANONYMOUS;
                };

                scope.changePassword = function (ev) {
                    $mdDialog.show({
                        controller: 'passwordChangeCtrl',
                        templateUrl: 'app/module/admin/users/passwordChange.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        locals: {
                            currentUser: angular.copy(Session.get())
                        }
                    });
                };

                scope.goTo = function (item) {
                    var p = {q: $stateParams.q};
                    if (item.id === 'scientific') {
                        p.q = undefined;
                    }
                    $state.go(item.link, p);
                    $mdSidenav('left').close();
                };
                scope.goAdmin = function () {
                    $state.go('app.admin.users');
                    $mdSidenav('left').close();
                };

                scope.goAdminOrganization = function () {
                    $state.go('app.admin-organization.users');
                    $mdSidenav('left').close();
                };

                scope.pages = [
                    // {id: 'scientific', name: 'Scientific', link: 'app.scientific'},
                    {id: 'search', name: 'Documents', link: 'app.search', icon: "file-document-box-multiple"},
                    {id: 'news', name: 'News', link: 'app.news', icon: "newspaper"},
                    {id: 'social', name: 'Social', link: 'app.social', icon: "twitter"}
                ];


                scope.dashboards = [];
                if (scope.session.get().uiTemplate !== null) {
                    scope.dashboards = scope.session.get().uiTemplate.dashboards;
                }
                scope.goToDashboard = function (idx) {
                    var p = {
                        q: $stateParams.q,
                        id: idx
                    };
                    $state.go("app.dashboard.item", p);
                    $mdSidenav('left').close();
                };
                scope.isDashboardSelected = function (idx) {
                    if (scope.currentParams && "" + idx === scope.currentParams.id && scope.currentPage === "app.dashboard.item") {
                        return true;
                    }
                    return false;
                };

                scope.shortcutsClick = hotkeys.toggleCheatSheet;
                scope.logout = Session.logout;

                scope.onStripe = function ($event) {
                    ThriftHelper.sendRequest(new GetCheckoutSessionReq({
                            email: Session.get().email,
                            type: CheckoutType.SINGLE_LICENCSE_1_MONTH,
                            images: 'https://enterprise.percipio-big-data.com/img/background.jpg',
                            successUrl: config.previewUrl + '/#checkout?status=1&id={CHECKOUT_SESSION_ID}',
                            cancelUrl: config.previewUrl + '/#checkout?status=0&id={CHECKOUT_SESSION_ID}'
                        }),
                        MsgType.GET_CHECKOUT_SESSION_REQ, config.calls.WS_GET_CHECKOUT_SESSION);

                };

                scope.$on(config.calls.WS_GET_CHECKOUT_SESSION, function (event, args) {
                    if (args && args.data instanceof GetCheckoutSessionResp) {
                        var sessionId = args.data.session;
                        var stripe = Stripe(config.stripe);
                        stripe.redirectToCheckout({
                            // Make the id field from the Checkout Session creation API response
                            // available to this file, so you can provide it as parameter here
                            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                            sessionId: sessionId
                        }).then(function (result) {
                            // If `redirectToCheckout` fails due to a browser or network
                            // error, display the localized error message to your customer
                            // using `result.error.message`.
                            console.log(result);
                            Toast.error(result.error.message);
                        });
                    }
                });

                scope.$on("$destroy", function () {
                    element.remove();
                });

                scope.showHelp = false;
                scope.toggleHelp = function($event){
                    scope.showHelp = !scope.showHelp;
                };
            }
        };
    }]);
