

app.service('adminService', [ 'ThriftHelper', 'config', '$filter', '$rootScope','Toast','$mdDialog',
    function (ThriftHelper, config, $filter, $rootScope,Toast,$mdDialog) {

        var data = {
            users: [],
            organizations: [],
            cases: [],
            twitterChannels: [],
            current: [],
            history: [],
            uiTemplates: []
        };

        var getUsers = function () {
            ThriftHelper.sendRequest(new GetUserReq({}), MsgType.GET_USER_REQ, config.calls.WS_ADMIN_DATA);
        };
        var getOrganizations = function () {
            ThriftHelper.sendRequest(new GetOrganizationReq({}), MsgType.GET_ORGANIZATION_REQ, config.calls.WS_ADMIN_DATA);
        };
        var getCases = function () {
            ThriftHelper.sendRequest(new GetCaseReq({}), MsgType.GET_CASE_REQ, config.calls.WS_ADMIN_DATA);
        };
        var getTwitterChannels = function () {
            ThriftHelper.sendRequest(new GetTwitterChannelReq({}), MsgType.GET_TWITTER_CHANNEL_REQ, config.calls.WS_ADMIN_DATA);
        };
        var getCurrent = function () {
            ThriftHelper.sendRequest(new GetSessionReq({type: SessionType.CURRENT}), MsgType.GET_SESSION_REQ, config.calls.WS_ADMIN_CURRENT);
        };
        var getSources = function (page, pageSize, options, columnNames, filter, type, mustIds) {
            var query = "{}";
            if(filter){
                query = '{"query":{"simple_query_string":{ "fields":["name"], "query":"' + filter + '"}}}';
            }
            if(mustIds && mustIds.length > 0){
                query = '{"query": { "bool": {';
                if(filter){
                    query = query + '"must":[{"match":{"name":"' + filter + '"}}],'
                }
                    query = query + '"must_not": [ ';
                for(var i = 0; i < mustIds.length; i++){
                    query= query + '{ "match": { "id":          "' + mustIds[i] + '" }}';
                    if(i === mustIds.length-1){
                        query = query + "]}";
                    }else{
                        query = query + ",";
                    }
                }
                query = query + "}}";
            }
            if(!type)
                type ="news";
            var dto = {
                type: type,
                start: (page -1) * pageSize,
                size: pageSize,
                query: query
            };
            ThriftHelper.sendRequest(new GetSourceReq(dto), MsgType.GET_SOURCE_REQ, config.calls.WS_ADMIN_SOURCES);
        };
        var getSourcesByIds = function (type, mustIds) {
            var query = '{"query": { "bool": {';
                query = query + '"should": [ ';
            for(var i = 0; i < mustIds.length; i++){
                query= query + '{ "match": { "id":          "' + mustIds[i] + '" }}';
                if(i === mustIds.length-1){
                    query = query + "]}";
                }else{
                    query = query + ",";
                }
            }
            query = query + "}}";
            if(!type)
                type ="news";
            var dto = {
                type: type,
                start: 0,
                size: mustIds.length,
                query: query
            };
            ThriftHelper.sendRequest(new GetSourceReq(dto), MsgType.GET_SOURCE_REQ, config.calls.WS_ADMIN_SOURCES_BY_ID);
        };
        var getSource = function (type, ids, call) {
            var dto = {
                type: type,
                ids: ids
            };
            ThriftHelper.sendRequest(new GetSourceStatReq(dto), MsgType.GET_SOURCE_STAT_REQ, call);
        };

        var getHistory = function (historyDate) {
            if(!angular.isDefined(historyDate))
                historyDate = new Date();
            var dto = {type: SessionType.HISTORY, dates: [$filter('date')(historyDate, "yyyy-MM-dd")]};
            ThriftHelper.sendRequest(new GetSessionReq(dto),MsgType.GET_SESSION_REQ, config.calls.WS_ADMIN_HISTORY);
        };
        var getUiTemplates = function (organizationId) {
            if(organizationId === undefined){
                organizationId = $rootScope.user.organizationId;
            }
            var dto = { organizationId: organizationId};
            ThriftHelper.sendRequest(new GetUiTemplatesReq(dto), MsgType.GET_UI_TEMPLATES_REQ, config.calls.WS_ADMIN_UI_TEMPLATES);
        };

        var getter = {};
        getter.getOrganizationName = function (organizations, id) {
            var org = _.findWhere(organizations, {id: id});
            if (angular.isDefined(org)) {
                return org.name;
            }
            return '';
        };

        getter.getCases = function (cases ,cases_filter) {
            var c = _.filter(cases, function(item){
                return _.contains(cases_filter,item.id);
            });
            if (angular.isDefined(c)) {
                return _.pluck(c,'name').join(', ');
            }
            return '';
        };

        getter.getTwitterChannels = function (channels ,tc_filter) {
            var c = _.filter(channels, function(item){
                return _.contains(tc_filter,item.id);
            });
            var arr =[];
            for(var i = 0; i<c.length;i++){
                arr.push("<span class='"+
                    (c[i].active===true?"green-text":"red-text")+
                    "'>"+c[i].name+"</span>");
            }
            return arr.join(", ");
        };

        getter.getRoleName = function (id) {
            for (role in UserRole) {
                if (UserRole[role] === id) {
                    return role;
                }
            }
            return '';
        };

        getter.getKeywords = function (keywords) {
            if(angular.isArray(keywords)){
                return keywords.join(', ');
            }
            return '';
        };

        getter.getTags = function (tags) {
            if(angular.isArray(tags)){
                return tags.join(', ');
            }
            return '';
        };

        getter.getTime = function (time) {
            var d = new Date(time);
            return $filter('date')(d, config.format.shortDate);
        };

        getter.getDashboards = function (dashboards) {
            var arr = [];
            dashboards.forEach(function (d) {
                arr.push(d.name);
            });
            return arr.join(', ');
        };
        getter.getUiTemplateById = function (id) {
            var template = _.findWhere(data.uiTemplates, {id: id});
            if (angular.isDefined(template)) {
                return template.name;
            }
            return '';
        };

        getter.sortByColumn = function (headerRowData, columnNames, array){

            var index = headerRowData.findIndex(function (item) {
                return item.sort
            });
            var dir;
            if(index === -1){
                index = columnNames[0];
                dir = "asc"
            }else{
                dir = headerRowData[index].sort;
                index = columnNames[index];
            }
            var sortFunction = function (a, b) {
                var result;
                if(typeof a[index] === 'string' && typeof b[index] === 'string'){

                    if(a[index] > b[index]){
                        result = 1;
                    }else if(a[index] < b[index]){
                        result = -1;
                    }else{
                        result = 0;
                    }
                }else{
                    result = a[index] - b[index];
                }
                if(dir === "asc"){
                    return result;
                }else{
                    return -result;
                }
            };

            return array.sort(sortFunction);
        };

        $rootScope.$on(config.calls.WS_ADMIN_DATA, function (event, args) {
            if (angular.isDefined(args.data.users)) {
                data.users = args.data.users;
            } else if (angular.isDefined(args.data.organizations)) {
                data.organizations = args.data.organizations;
            } else if (angular.isDefined(args.data.cases)) {
                data.cases = args.data.cases;
            } else if (angular.isDefined(args.data.channels)) {
                data.twitterChannels = args.data.channels;
            }
            $rootScope.$apply();
        });
        $rootScope.$on(config.calls.WS_ADMIN_CURRENT, function (event, args) {
            if (angular.isDefined(args.data.sessions)) {
                data.current = args.data.sessions;
            }
            $rootScope.$apply();
        });
        $rootScope.$on(config.calls.WS_ADMIN_HISTORY, function (event, args) {
            if (angular.isDefined(args.data.sessions)) {
                data.history = args.data.sessions;
            }
            $rootScope.$apply();
        });

        $rootScope.$on(config.calls.WS_ADMIN_UI_TEMPLATES, function (event, args) {
            if (angular.isDefined(args.data.templates)) {
                data.uiTemplates = args.data.templates;
            }
            $rootScope.$apply();
        });

        var init = function(){
            if($(".column-header-content:first").length > 0){
                setTimeout(function () {
                    $(".column-header-content:first").click();
                },100);
            }
        };

        $rootScope.$on(config.calls.WS_DELETE_USER, function (event, args) {
            Toast.success("User was successfully deleted.");
            getUsers();
        });

        var deleteUser = function (row, ev) {
            row = _.find(data.users, function(item){
                return item.email === row;
            });
            var confirm = $mdDialog.confirm()
                .title('Would you like to delete user "' + row.email + '"?')
                .ariaLabel('Delete user')
                .targetEvent(ev)
                .ok('Delete')
                .clickOutsideToClose(true)
                .cancel('Cancel');
            $mdDialog.show(confirm).then(function () {
                var user = new DeleteUser({email: row.email});
                ThriftHelper.sendRequest(user, MsgType.DELETE_USER, config.calls.WS_DELETE_USER);
            });
        };

        var editUser = function (row, ev, fromOrganization) {
            if(row){
                row = _.find(data.users, function(item){
                    return item.email === row;
                });
            }
            $mdDialog.show({
                controller: 'editUserCtrl',
                templateUrl: 'app/module/admin/users/editUser.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {
                    currentUser: angular.copy(row),
                    organizations: data.organizations,
                    cases: data.cases,
                    channels: data.channels,
                    uiTemplates: data.uiTemplates,
                    fromOrganization: fromOrganization
                }
            })
                .then(function (answer) {
                    getUsers();
                });
        };

        var changePassword = function (row, ev) {
            if(row){
                row = _.find(data.users, function(item){
                    return item.email === row;
                });
            }
            $mdDialog.show({
                controller: 'passwordChangeCtrl',
                templateUrl: 'app/module/admin/users/passwordChange.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                locals: {
                    currentUser: angular.copy(row)
                }
            });
        };

        var editUiTemplate = function (id, ev, fromOrganization) {
            var template = null;
            if(id !== null){
                template = _.findWhere(data.uiTemplates,{id:id});
            }
            $mdDialog.show({
                controller: 'adminOrganizationUiTemplatesEditCtrl',
                templateUrl: 'app/module/admin-organization/admin-organization-ui-templates/admin-organization-ui-templates-edit.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                multiple:true,
                locals: {
                    currentUiTemplate: angular.copy(template),
                    fromOrganization: fromOrganization
                }
            })
                .then(function (answer) {
                    getUiTemplates();
                });
        };

        return {
            data: data,
            getUsers: getUsers,
            getOrganizations: getOrganizations,
            getCases: getCases,
            getTwitterChannels: getTwitterChannels,
            getCurrent: getCurrent,
            getHistory: getHistory,
            getUiTemplates: getUiTemplates,
            getSources: getSources,
            getSourcesByIds: getSourcesByIds,
            getSource: getSource,
            deleteUser: deleteUser,
            editUser: editUser,
            changePassword: changePassword,
            editUiTemplate: editUiTemplate,
            getter: getter,
            init: init
        };
    }]);