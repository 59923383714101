
app.controller('editUserCtrl', ['$scope', 'Session', 'currentUser', 'organizations', 'config',
    'cases','channels','Toast', '$mdDialog', 'ThriftHelper', 'config','fromOrganization','adminService',
    function ($scope, Session, currentUser, organizations, config,
    cases, channels, Toast, $mdDialog, ThriftHelper, config,fromOrganization,adminService) {

        console.log(organizations);

        $scope.form = {};
        $scope.confirm = { val: undefined};
        $scope.organizations = organizations;
        $scope.uiTemplates = [];
        $scope.roles = UserRole;

        $scope.isAdd = currentUser === null;
        $scope.title = $scope.isAdd ? "Add user" : "Edit user";
        $scope.fromOrganization = fromOrganization===true;

        if ($scope.isAdd) {
            $scope.user = {
                active: true,
                confirmed: true,
                lastName: ""
            };
            if($scope.fromOrganization){
                $scope.user.organizationId = Session.get().organizationId;
                $scope.user.role = UserRole.CONSUMER;
            }
        } else {
            $scope.user = currentUser;
        }
        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.save = function () {
            if ($scope.form.user.$valid) {
                //console.log($scope.user);
                ThriftHelper.sendRequest(new UpdateUser({user: new User($scope.user)}),
                        MsgType.UPDATE_USER, config.calls.WS_EDIT_USER);
            }
        };

        $scope.$on(config.calls.WS_EDIT_USER, function (event, args) {
            if (args.data === null) {
                if ($scope.isAdd) {
                    Toast.success("User was successfully added.");
                } else {
                    Toast.success("User was successfully updated.");
                    if(Session.get().email === $scope.user.email){
                        location.reload();
                    }
                }
                $mdDialog.hide();
            }
        });

        var getUiTemplates = function () {
            if(fromOrganization){
                $scope.uiTemplates = adminService.data.uiTemplates;
                return;
            }
            $scope.uiTemplates = [];
            if($scope.user.organizationId !== undefined && $scope.user.organizationId !== null) {
                var dto = { organizationId: $scope.user.organizationId};
                ThriftHelper.sendRequest(new GetUiTemplatesReq(dto), MsgType.GET_UI_TEMPLATES_REQ, config.calls.WS_EDIT_USER_UI_TEMPLATES);
            }
        };
        getUiTemplates();
        $scope.$on(config.calls.WS_EDIT_USER_UI_TEMPLATES, function (event, args) {
            if (angular.isDefined(args.data.templates)) {
                $scope.uiTemplates = args.data.templates;
            }
            $scope.$apply();
        });

    }]);
