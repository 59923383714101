app.factory('exportableDetailsHitModel', ['$q', 'config', 'ThriftHelper', '$rootScope', 'HitModel',
    function ($q, config, ThriftHelper, $rootScope, HitModel) {
        function exportableDetailsHitModelDecorator(hitModel) {
            initExportPdf(hitModel);

            return hitModel;
        }

        function initExportPdf(hitModel) {
            var id = "document-details-" + UUID.generate();
            hitModel._originalExportPDF = hitModel.exportPDF;
            hitModel.exportPDF = export2Pdf;

            hitModel._requestId = config.calls.WS_WEBSHOT + "-" + id;
            hitModel._previewImageRetries = 5;
        }

        function loadDocumentPreviewImage(item) {
            var deferred = $q.defer();

            loadDocumentPreviewUrl(item).then(function (url) {
                getBase64Image(url)
                    .then(function (imageBase64) {
                        deferred.resolve(imageBase64);
                    }, function (error) {
                        deferred.resolve(undefined);
                    });
            }, function (error) {
                // in case of error we generate PDF anyway - no image
                deferred.resolve(undefined);
            });

            return deferred.promise;
        }

        function loadDocumentPreviewUrl(item) {
            var deferred = $q.defer();

            var requestId = item._requestId + UUID.generate();

            $rootScope.$on(requestId, function (event, args) {
                if (args && args.data) {
                    if (args.data.status === WebshotStatus.VALID) {
                        var url = config.previewUrl + args.data.webUrl;

                        deferred.resolve(url);
                    } else if (args.data.status === WebshotStatus.PENDING) {
                        // we do a reload after some time
                        item._previewImageRetries--;
                        if (item._previewImageRetries > 0) {
                            setTimeout(function () {
                                if (item.url != null) {
                                    ThriftHelper.sendRequest(new GetWebshotReq({
                                            id: item.id,
                                            url: item.url
                                        }),
                                        MsgType.GET_WEBSHOT_REQ, requestId);
                                }
                            }, 1000);
                        } else {
                            // Unable to generate preview.
                            deferred.resolve(undefined)
                        }
                    } else {
                        deferred.resolve(undefined)
                    }
                }
            });

            if (item.url != null) {
                ThriftHelper.sendRequest(new GetWebshotReq({
                        id: item.id,
                        url: item.url
                    }),
                    MsgType.GET_WEBSHOT_REQ, requestId);
            }

            return deferred.promise;
        }

        function loadDocumentReferences(item) {
            var deferred = $q.defer();

            if (item.references && item.references.length > 0) {
                var requestId = item._requestId + UUID.generate();
                $rootScope.$on(requestId, function (event, args) {
                    if (args && args.data && args.data.documents && args.data.documents != null && args.data.documents.length > 0) {
                        var refs = [];
                        args.data.documents.forEach(function (document) {
                            refs.push(new HitModel(document));
                        });

                        deferred.resolve(refs);
                    } else {
                        deferred.resolve([]);
                    }
                });

                var idsArray = [];
                if (item.references.constructor === Array) {
                    idsArray = item.references;
                } else {
                    idsArray.push(item.references);
                }

                ThriftHelper.sendRequest(new GetEsReq({
                        type: item.type,
                        indexName: 'document',
                        ids: idsArray
                    }),
                    MsgType.GET_ES_REQ, requestId);
            } else {
                // no references
                deferred.resolve([]);
            }

            return deferred.promise;
        }

        function getBase64Image(url) {
            var deferred = $q.defer();
            var img = document.createElement('img');
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = function () {
                // image loaded
                var canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL('image/png');
                deferred.resolve(dataURL);
            };

            img.onerror = function (error) {
                deferred.reject('Failed to load image: ' + error);
            };

            img.src = url;

            return deferred.promise;
        }

        function export2Pdf() {
            var self = this;
            var deferred = $q.defer();

            $q.all([loadDocumentReferences(self), loadDocumentPreviewImage(self)])
                .then(function (data) {
                    var references = data[0];
                    var preview = data[1];

                    deferred.resolve(generatePdfData(self, references, preview));
                }, function (reason) {
                    // in case of error we generate PDF anyway - no image, no references, ...
                    deferred.resolve(generatePdfData(self, undefined, undefined));
                });

            return deferred.promise;
        }

        function generatePdfData(item, references, preview) {
            var output = [];

            // Authors, dates, ...
            prepareHeader(item, output);

            if (item.abstract) {
                output.push({text: item.abstract, margin: [0, 0, 0, 10]});
            }

            prepareKeywords(item, output);

            prepareFos(item, output);

            prepareReferences(references, output);

            preparePreview(preview, output);

            return output;
        }

        function prepareHeader(item, output) {
            output.push({text: item.title, style: 'title', link: item.link, margin: [0, 0, 0, 15]});

            if (item.type === 'patent') {
                output.push({text: 'Author(s):'});
            } else if (item.type === 'project') {
                output.push({text: 'Participants(s):'});
            }

            for (var i = 0; i < item.authors.length; i++) {
                var author = item.authors[i];
                var authorString = author.name;
                if (author.org !== null) {
                    authorString += ', ' + author.org;
                }

                var margin = i === item.authors.length - 1 ? [0, 0, 0, 10] : undefined;
                output.push({text: authorString, margin: margin});
            }

            if (item.type === 'patent') {
                output.push({text: 'Assignee(s):'});
            } else if (item.type === 'project') {
                output.push({text: 'Coordinator(s):'});
            }

            if (item.type === 'patent' || item.type === 'project') {
                for (var j = 0; j < item.assignee.length; j++) {
                    var assignee = item.assignee[j];
                    var assigneeString = assignee.name;
                    if (assignee.org !== null) {
                        assigneeString += ', ' + assignee.org;
                    }

                    var margin = j === item.assignee.length - 1 ? [0, 0, 0, 10] : undefined;
                    output.push({text: assigneeString, margin: margin});
                }
            }

            var dateAdded = false;
            if (item.type === 'patent' && item.priorityDate !== null) {
                output.push({text: 'Priority date: ' + item.priorityDate});
                dateAdded = true;
            }

            if (item.type === 'patent' && item.applicationDate !== null) {
                output.push({text: 'Application date: ' + item.applicationDate});
                dateAdded = true;
            }

            if (item.type === 'patent' && item.date !== null) {
                output.push({text: 'Publishing date: ' + item.date});
                dateAdded = true;
            }

            if (item.type === 'project' && item.date !== null) {
                output.push({text: 'Start date: ' + item.date});
                dateAdded = true;
            }

            if (item.type === 'project' && item.applicationDate !== null) {
                output.push({text: 'End date: ' + item.applicationDate});
                dateAdded = true;
            }

            if (dateAdded) {
                // empty line
                output.push({text: '', margin: [0, 0, 0, 10]});
            }

            if (item.type !== 'patent') {
                var documentTitleDetails = loadDocumentTitleDetails(item);
                if (documentTitleDetails) {
                    output.push({text: documentTitleDetails});
                }
            }

            if (item.type === 'patent') {
                output.push({text: item.id});
            }

            if (item.type === 'article' && item.doi) {
                output.push({text: 'DOI: ' + item.doi});
            }

            // empty line
            output.push({text: '', margin: [0, 0, 0, 10]});
        }

        function prepareKeywords(item, output) {
            if (item.keywords && item.keywords.length > 0) {
                output.push({text: 'Keywords:'});
                output.push({text: item.keywords.join(', '), margin: [0, 0, 0, 10]});
            }
        }

        function prepareFos(item, output) {
            if (item.type === 'article' && item.fos) {
                output.push({text: 'FOS:'});
                output.push({text: item.fos.join(', '), margin: [0, 0, 0, 10]});
            }
        }

        function prepareReferences(references, output) {
            if (references && references.length > 0) {
                output.push({text: 'References:'});
                for (var i = 0; i < references.length; i++) {
                    var reference = references[i];
                    output.push({text: (i + 1) + '. ' + reference.authorsString + '; ' + reference.title + ', ' + reference.year + ', ' + reference.volume + ', ' + reference.issue});
                }
                // empty line
                output.push({text: '', margin: [0, 0, 0, 10]});
            }
        }

        function preparePreview(preview, output) {
            if (preview) {
                output.push({image: preview, width: 450});
            }
        }

        function loadDocumentTitleDetails(item) {
            var titleObjs = [];
            if (item.type === 'project') {
                if (item.source != null && item.source.length > 0) {
                    titleObjs.push(item.source);
                }
                if (item.publisher != null && item.publisher.length > 0) {
                    titleObjs.push(item.publisher);
                }
            } else {
                if (item.source != null && item.source.length > 0) {
                    titleObjs.push(item.source);
                }

                if (item.year != null && item.year.length > 0) {
                    titleObjs.push(item.year);
                }

                if (item.volume != null && item.volume.length > 0) {
                    titleObjs.push(item.volume);
                }

                if (item.issue != null && item.issue.length > 0) {
                    titleObjs.push(item.issue);
                }
            }

            if (titleObjs.length > 0) {
                return titleObjs.join(', ');
            }

            return undefined;
        }

        return exportableDetailsHitModelDecorator;
    }
]);