
app.controller('switchCaseCtrl', ['$scope', 'Toast', 'Session',
    '$mdDialog', 'ThriftHelper', '$state', '$rootScope','config',
    function ($scope, Toast, Session,
            $mdDialog, ThriftHelper, $state, $rootScope,config) {

        $scope.form = {};

        $scope.title = "Switch Case";
        $scope.confirm = { val: undefined};
        //console.log($rootScope.user.cases);
        $scope.cases = {
            all: _.sortBy($rootScope.user.cases, 'name'),
            selected: $rootScope.user.currentCase
        };
        
        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.save = function () {
            if ($scope.form.case.$valid) {
                var dto = {
                    email: $rootScope.user.email,
                    currentCase: $scope.cases.selected.id};
                ThriftHelper.sendRequest(new UpdateCurrentCase(dto),
                       MsgType.UPDATE_CURRENT_CASE, config.calls.WS_SWITCH_CASE);
            }
        };

        $scope.$on(config.calls.WS_SWITCH_CASE, function (event, args) {
            if (args.data === null) {
                Toast.success("Current case was successfully updated.");
                
                $rootScope.user.currentCase = $scope.cases.selected;
                $mdDialog.hide();
                $state.reload();
            } else if (args.data instanceof ErrorMsg) {
                Toast.error(args.data.errorDesc);
            }
            $scope.$apply();
        });

    }]);
