app.controller('dashboardListOptionsCtrl', [
    '$scope', 'detailIdGenerator',
    function ($scope, detailIdGenerator) {
        $scope.$on('onDetailsIdsChanged', function (event) {
            // called when Document Details has changed on Layout panel
            onDocumentDetailsIdsChanged();
        });

        // load right away
        onDocumentDetailsIdsChanged();

        function onDocumentDetailsIdsChanged() {
            // first converts layout to drop down items
            $scope.detailsViews = detailIdGenerator.dashboard2items($scope.$parent.dashboard);

            // gets proper selection
            $scope.widget.detailsId = detailIdGenerator.getSelection($scope.widget.detailsId, $scope.detailsViews);
        }
    }
]);