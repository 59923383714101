/**
 * Created by Matej on 31.7.2014.
 */

/**
 *  controller for home page
 **/

app.controller('trialCtrl', ['$scope', 'Toast', '$state', 'Session', '$rootScope','$http','config','ThriftHelper', '$stateParams', '$log',
    function ($scope, Toast, $state, Session, $rootScope, $http, config, ThriftHelper, $stateParams, $log) {
        
        $scope.form = {};
        $scope.title = "Try out!";

        $scope.loading = true;
        $scope.keywords = [];
        $scope.case = '';
        $scope.error = false;

        var getCall = function () {
            return config.calls.WS_TRIAL;
        };

        var init = function () {
            $log.warn('Trial init. CaseId=', JSON.stringify($stateParams));
            if(!Session.isLogged()) {
                var l = {
                    role: UserRole.ANONYMOUS,
                    caseId: $stateParams.caseId
                };
                Session.loginAnonymous(l);
            }else{
                getData();
            }
        };

        $scope.loginClick = function(){
            $state.go("login");
        };
        $scope.signUpClick = function(){
            $state.go("signup");
        };

        var getData = function () {
            $scope.case = $rootScope.user.currentCase.name;
            var dto = {
                size: 10,
                organization: $rootScope.user.currentCase.name,
                keyword: $rootScope.user.currentCase.keyword
            };
            ThriftHelper.sendRequest(new SearchOrganizationReq(dto),MsgType.SEARCH_ORGANIZATION_REQ, config.calls.WS_TRIAL);
        };

        $scope.keywordClick = function(keyword){
            $state.go('app.dashboard.item',{q:keyword, id:0});
        };

        $scope.$on(config.calls.WS_TRIAL, function (event, args) {
            // skip todays keywords
            $state.go('app.dashboard.item', { q: '', id: 0});
            /*
            $scope.keywords = _.shuffle(_.pluck(args.data.keywords, 'keyword'));
            $scope.loading = false;
            $scope.$apply();
            */
        });


        $scope.$on("login-anonymous-response", function (event, args) {
            //console.log("data",args.data);
            if (args.data.error === null) {
                getData();
            } else {
                if (args.data.error === "WS") {
                    Toast.error("Can't connect to the server...");
                } else if(args.data.error === 'Expired or invalid session token'){
                    Toast.error("Sorry, your try out session has expired.");
                    $state.go('login');
                } else {
                    Toast.error(args.data.error);
                }
            }
        });
        init();

    }]);
