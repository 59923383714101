app.service('documentDetailId', [
    function () {
        var zeroItem = 'None';
        var itemTextPrefix = 'View';
        var noItemsText = 'None (No Document Details View added to layout)';

        /**
         * Generates ID for all Document Details View widgets in specified layout.
         *
         * @param layout
         */
        this.generateId = function (layout) {
            var detailsCount = 0;

            // automatically assign sequential number as ID to each Document Details View
            layout.cols.forEach(function (columns) {
                columns.rows.forEach(function (row) {
                    if (row.id === 'documentdetails') {
                        detailsCount++;

                        row.options.detailsViewId = detailsCount;
                    } else if (row.id === 'multiwidget') {
                        // in case of multiwidget we would like to handle also "sub-widgets"
                        row.options.items.forEach(function (item) {
                            if (item.id === 'documentdetails') {
                                detailsCount++;
                                item.options.detailsViewId = detailsCount;
                            }
                        });
                    }
                });
            });
        };

        /**
         * Searches for Document Details View components in layout and prepares array of items {id, text} that can be
         * used in drop down components (e.g. md-select and md-option).
         *
         * @param layout
         */
        this.layout2items = function (layout) {
            var items = [{
                id: 0,
                text: zeroItem
            }];

            // search for all IDs to build up options that can be used for drop down
            layout.cols.forEach(function (columns) {
                columns.rows.forEach(function (row) {
                    if (row.id === 'documentdetails') {
                        items.push({
                            id: row.options.detailsViewId,
                            text: itemTextPrefix + row.options.detailsViewId
                        });
                    } else if (row.id === 'multiwidget') {
                        // in case of multiwidget we would like to handle also "sub-widgets"
                        row.options.items.forEach(function (item) {
                            if (item.id === 'documentdetails') {
                                items.push({
                                    id: item.options.detailsViewId,
                                    text: itemTextPrefix + item.options.detailsViewId
                                });
                            }
                        });
                    }
                });
            });

            if (items.length === 1) {
                // there is no any document details view in Layouts
                items[0].text = noItemsText;
            }

            return items;
        };

        /**
         * Gets the selection based on selected item ID and array of items.
         * <p>
         *     In case if item id doesn't fit into specified items array, item on zero index will be selected.
         * </p>
         * @param selectedItemId
         * @param items
         * @return {number}
         */
        this.getSelection = function (selectedItemId, items) {
            // we would like to select "None" at index 0 by default
            var selection = selectedItemId ? selectedItemId : 0;
            if (selection >= items.length) {
                selection = 0;
            }

            return selection;
        };

        return this;
    }
]);