/* global app, d3 */

/**
 * app Session for user data info
 * @param Session : user session
 **/
app.service('defaultsettings', ['config','$mdDialog','KeywordModel',
    function (config,$mdDialog,KeywordModel) {

        var thiz = this;

        thiz.historyYears = [(new Date).getFullYear() - 10, (new Date).getFullYear()];
        thiz.views = [{
                id: 'cloud',
                icon: 'cloud',
                text: 'Word cloud + list'
            }, {
                id: 'details',
                icon: 'history',
                text: 'Word details + list'
            }, {
                id: '3lists',
                icon: 'view_column',
                text: '3 lists'
            }, {
                id: 'list',
                icon: 'line_weight',
                text: 'Just list'
            }];
        
        thiz.widgets = [
            {
                id: 'cloud',
                name: 'Word cloud',
                simple: true
            },
            {
                id: 'details',
                name: 'Word details',
                simple: true
            },
            {
                id: 'list',
                name: 'List',
                simple: true
            },
            {
                id: 'complexity',
                name: 'Complexity view',
                simple: true
            },
            {
                id: 'treemap',
                name: 'Tree view',
                simple: true
            },
            {
                id: 'geomap',
                name: 'Map view',
                simple: true
            },
            {
                id: 'multiwidget',
                name: 'Multi widget'
            },
            {
                id: 'documentgeo',
                name: 'Document Geo View',
                simple: true
            },
            {
                id: 'documentdetails',
                name: 'Document Details',
                simple: true
            }
        ];
        
        thiz.layoutSettings = {
            search: {
                desktop: [{
                    name: "Cloud, details, list",
                    cols: [
                        {
                            rows: [{
                                id: 'cloud',
                                options: {
                                    ft: 0,
                                    corr: 100
                                }
                            },
                                {
                                    id: 'details',
                                    options: {
                                        showFreq: true,
                                        fromYear: 1970
                                    }
                                }]
                        },
                        {
                            rows: [{
                                id: 'list',
                                options: {
                                    type: 0,
                                    sortBy: 'relevance',
                                    range: 0
                                }
                            }]
                        }
                    ]
                }],
                mobile: [{
                    name: "Cloud, details, list",
                    cols: [
                        {
                            rows: [{
                                id: 'multiwidget',
                                options: {
                                    items: [
                                        {
                                            id: 'cloud',
                                            name: "Word cloud",
                                            options: {
                                                ft: 0,
                                                corr: 100
                                            }
                                        },
                                        {
                                            id: 'details',
                                            name: "Word details",
                                            options: {
                                                showFreq: true,
                                                fromYear: 1970
                                            }
                                        },
                                        {
                                            id: 'list',
                                            name: "List",
                                            options: {
                                                type: 0,
                                                sortBy: 'relevance',
                                                range: 0
                                            }
                                        }
                                    ]
                                }
                            }]
                        }]
                }]
            },
            social: {
                desktop: [{
                    name: "List, geo, aggregation, details",
                    cols: [
                        {
                            rows: [{
                                id: 'social-list',
                                options: {
                                    sortBy: 'created_time',
                                    range: 0,
                                    detailsViewId: 1
                                }
                            }
                            ,{
                                id: "documentgeo",
                                options: {
                                    detailsViewId: 1,
                                    documentGeoMaxLoad: 1000,
                                    spreadDensity: 0,
                                    type: 0
                                }
                            }
                            ]
                        },
                        {
                            rows: [{
                                id: 'social-agg',
                                options: {}
                                },
                                {
                                id: 'social-details',
                                options: {
                                    detailsViewId: 1
                                }
                            }]
                        }
                    ]
                }],
                mobile: [{
                    name: "List, geo, aggregation, details",
                    cols: [
                        {
                            rows: [{
                                id: 'multiwidget',
                                options: {
                                    items: [
                                        {
                                            id: 'social-list',
                                            name: "Social list",
                                            options: {
                                                sortBy: 'created_time',
                                                range: 0,
                                                detailsViewId: 1
                                            }
                                        },
                                        {
                                            id: "documentgeo",
                                            name: "Social map",
                                            options: {
                                                detailsViewId: 1,
                                                documentGeoMaxLoad: 1000,
                                                spreadDensity: 0,
                                                type: 0
                                            }
                                        },
                                        {
                                            id: 'social-agg',
                                            name: "Social aggregation",
                                            options: {}
                                        },
                                        {
                                            id: 'social-details',
                                            name: "Social details",
                                            options: {
                                                detailsViewId: 1
                                            }
                                        }
                                    ]
                                }
                            }]
                        }]
                }]
            }
        };

        thiz.anonymousLayoutSettings = {
            search: {
                desktop: [{
                    name: "l",
                    cols: [
                        {
                            rows: [{
                                id: 'cloud',
                                options: {
                                    ft: 0,
                                    corr: 100
                                }
                            },
                                {
                                    id: 'details',
                                    options: {
                                        showFreq: true,
                                        fromYear: 1970
                                    }
                                }]
                        },
                        {
                            rows: [{
                                id: 'list',
                                options: {
                                    type: 0,
                                    sortBy: 'relevance',
                                    range: 0,
                                    detailsViewId: 1
                                }
                            }]
                        },
                        {
                            rows: [{
                                id: 'documentdetails',
                                options: {
                                    detailsViewId: 1
                                }
                            }]
                        }
                    ]
                }],
                mobile: [{
                    name: "l",
                    cols: [
                        {
                            rows: [{
                                id: 'multiwidget',
                                options: {
                                    items: [
                                        {
                                            id: 'cloud',
                                            name: "Word cloud",
                                            options: {
                                                ft: 0,
                                                corr: 100
                                            }
                                        },
                                        {
                                            id: 'details',
                                            name: "Word details",
                                            options: {
                                                showFreq: true,
                                                fromYear: 1970
                                            }
                                        },
                                        {
                                            id: 'list',
                                            name: "List",
                                            options: {
                                                type: 0,
                                                sortBy: 'relevance',
                                                range: 0,
                                                detailsViewId: 1
                                            }
                                        },
                                        {
                                            id: 'documentdetails',
                                            name: "Document details",
                                            options: {
                                                detailsViewId: 1
                                            }
                                        }
                                    ]
                                }
                            }]
                        }]
                }]
            }
        };

        thiz.newsViews = [{
                id: 'cloud',
                icon: 'cloud',
                text: 'Word cloud + list'
            }, {
                id: 'details',
                icon: 'history',
                text: 'Word details + list'
            }, {
                id: 'list',
                icon: 'line_weight',
                text: 'Just list'
            }];

        thiz.socialSortBy = [
            {id: "createdTime",
                text: "Sort by time"},
            {id: "relevance",
                text: "Sort by relevance"},
            {id: "retweetCount",
                text: "Sort by retweets"}];

        thiz.sortBy = [
            {id: "year",
                text: "Sort by year"},
            {id: "relevance",
                text: "Sort by relevance"}];

        thiz.articleTypes = [
            {id: -1,
                type: "document",
                text: "Document or news",
                textplural: "All documents and news"},
            {id: 0,
                type: ["article", "patent", "project", "report"],
                text: "Document",
                textplural: "All documents"},
            {id: 1,
                type: "article",
                text: "Article",
                textplural: "Articles"},
            {id: 2,
                type: "patent",
                text: "Patent",
                textplural: "Patents"},
            {id: 3,
                type: "project",
                text: "Project",
                textplural: "Projects"},
            {id: 4,
                type: "report",
                text: "Report",
                textplural: "Reports"},
            {id: 10,
                type: "news",
                text: "News",
                textplural: "News"}
        ];

        thiz.aggDocTypes = [
            {id: 1,
                type: "article",
                text: "Article",
                textplural: "Articles",
                inlineText: "articles"},
            {id: 2,
                type: "patent",
                text: "Patent",
                textplural: "Patents",
                inlineText: "patents"},
            {id: 3,
                type: "project",
                text: "Project",
                textplural: "Projects",
                inlineText: "projects"},
            {id: 4,
                type: "report",
                text: "Report",
                textplural: "Reports",
                inlineText: "reports"},
            // {id: 10,
            //     type: "news",
            //     text: "News",
            //     textplural: "News",
            //     inlineText: "news"},
            {id: 100,
                type: "social",
                text: "Social",
                textplural: "Social",
                inlineText: "tweets"
            }
        ];

        var year = (new Date()).getFullYear();
        thiz.articleYears = [
            {id: 0, from: 0, to: 0,
                text: "Anytime"},
            {id: 2, from: year - 2, to: year,
                text: "Last 2 years"},
            {id: 5, from: year - 5, to: year,
                text: "Last 5 years"},
            {id: 10, from: year - 10, to: year,
                text: "Last 10 years"}
        ];

        thiz.socialRange = [
            {id: 0, from: 0, to: 0,
                text: "Anytime"},
            {id: 1, from: "now-24h", to: "now",
                text: "24 hours"},
            {id: 3, from: "now-3d/d", to: "now",
                text: "Last 3 days"},
            {id: 7, from: "now-7d/d", to: "now",
                text: "Last week"}
        ];

        thiz.getDateString = function (type) {
            var date = new Date();
            if (angular.isDefined(type)) {
                switch (type) {
                    case "-1d":
                        date.setDate(date.getDate() - 1);
                        break;
                    case "-1w":
                        date.setDate(date.getDate() - 7);
                        break;
                    case "-1m":
                        date.setMonth(date.getMonth() - 1);
                        break;
                }
            }
            var y = date.getFullYear();
            var m = (date.getMonth() + 1);
            if (m < 10)
                m = "0" + m;
            var d = date.getDate();
            if (d < 10)
                d = "0" + d;
            return y + "-" + m + "-" + d;
        };

        var date = thiz.getDateString();
        thiz.articleDays = [
            {id: 0, from: 0, to: 0,
                text: "Anytime"},
            {id: 1, from: thiz.getDateString("-1d"), to: date,
                text: "Last day"},
            {id: 7, from: thiz.getDateString("-1w"), to: date,
                text: "Last week"},
            {id: 30, from: thiz.getDateString("-1m"), to: date,
                text: "Last month"}
        ];

        thiz.newsSortBy = [
            {id: "timeline",
                text: "Timeline"},
            {id: "relevance",
                text: "Sort by Relevance"},
            {id: "date",
                text: "Sort by Date"}];


        thiz.spreadDensity = [
            {
                id: 0,
                text: "None"
            },
            {
                id: 500,
                text: "500 m"
            },
            {
                id: 1000,
                text: "1 km"
            },
            {
                id: 5000,
                text: "5 km"
            }
        ];

        thiz.documentGeoMaxLoad = [
            {
                id: 1000,
                text: "1000"
            },
            {
                id: 2000,
                text: "2000"
            },
            {
                id: 5000,
                text: "5000"
            },
            {
                id: 10000,
                text: "10000"
            }
        ];

        var sources = KeywordModel.getSources();
        var thisYear = moment().year();
        var s1 = _.findWhere(sources,{short:'y'});
        thiz.rangeYears = [{ name:(thisYear), text:"This year", source: s1},
            { name:(thisYear-1), text:"Last year",source: s1},
            { name:(thisYear-5)+":"+(thisYear), text:"Last 5 years",source: s1},
            { name:(thisYear-10)+":"+(thisYear), text:"Last 10 years",source: s1},
            { name:(thisYear-30)+":"+(thisYear), text:"Last 30 years",source: s1}];


        var l0 = moment().subtract(2, 'd').format("YYYY-MM-DD");
        var l1 = [moment().subtract(1, 'M').date(1).format("YYYY-MM-DD"),moment().subtract(0, 'M').date(0).format("YYYY-MM-DD")];
        var l2 = moment().subtract(1, 'M').format("YYYY-MM");
        var l3 = [moment().subtract(3, 'M').format("YYYY-MM"),moment().subtract(1, 'M').format("YYYY-MM")];
        var s2 = _.findWhere(sources,{short:'d'});
        thiz.rangeDates = [
            { name:(thisYear), text:"This year", source: s2},
            { name:(thisYear-1), text:"Last year",source: s2},
            { name:(thisYear-3)+":"+(thisYear), text:"Last 3 years",source: s2},
            { name:l2, text:"Previous month",source: s2},
            { name:l3[0]+":"+l3[1], text:"Previous 3 months",source: s2}
        ];


        var s3 = _.findWhere(sources,{short:'t'});
        thiz.rangeTimes = [{ name:"6h", text:"Last 6 hours", source: s3},
            { name:"1d", text:"Last day",source: s3},
            { name:"3d", text:"Last 3 days",source: s3},
            { name:l0, text:"Specific day",source: s3},
            { name:l1[0]+":"+l1[1], text:"Previous month",source: s3}];


        thiz.dashboardIconList = ['bank','atom','at','baseball','basketball','book-open-variant','book-open','bus',
        'car','cards-heart','airplane','alarm-light','alarm','access-point','all-inclusive','alphabetical','ambulance','anchor',
            'android-messages','apple-keyboard-command','apps','apple-safari','battery-charging','bell','bike','bitcoin',
        'briefcase','buffer','bug','bullhorn','bullseye','calendar','camcorder','camera','camera-iris','cart','cash',
        'cellphone','chart-line','chef-hat','chess-rook','city','clipboard-pulse','clipboard-text','comment','coffee','collage',
        'compass','cookie','creation','credit-card','crosshairs-gps','cube','crown','cup','currency-eur','currency-usd',
            'database'];

        thiz.dashboardTypes = [{ id: DashboardType.DOCUMENT_SIMPLE, name: "Simple Document"},
            {id: DashboardType.DOCUMENT, name: "Document"},
            { id: DashboardType.NEWS, name: "News"},
            { id: DashboardType.SOCIAL, name: "Social"},
            { id: DashboardType.CUSTOM, name: "Custom"}
        ];

        thiz.dashboardWidgetCategories = [
            {id:'general',name: "General"},
            {id:'document',name: "Documents"},
            {id:'news',name: "News"},
            {id:'social',name: "Social"},
        ];

        thiz.dashboardWidgetTypes = [
            {
                type: WidgetType.WORD_CLOUD,
                category: 'general',
                name: 'Word cloud',
                simple: true,
                directive: "wordcloud",
                optionsPath: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/cloud-options.html',
                defaultOptions: {
                    freqTrendy: 0,
                    corr: 100,
                    showSelectedWord: false
                }
            },
            {
                type: WidgetType.WORD_DETAILS,
                category: 'general',
                name: 'Word details',
                simple: true,
                directive: "worddetails",
                optionsPath: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/word-details-options.html',
                defaultOptions: {
                    showFreq: true,
                    fromYear: 1970,
                    showPercentage: false,
                    showRelNorm: false

                }
            },
            {
                type: WidgetType.MAP_TREE,
                category: 'general',
                name: 'Map tree view',
                simple: true,
                directive: "treemap"
            },
            {
                type: WidgetType.COMPLEXITY_VIEW,
                category: 'general',
                name: 'Complexity view',
                simple: true,
                directive: "complexity"
            },
            {
                type: WidgetType.DETAILS,
                category: 'general',
                name: 'Details',
                simple: true,
                directive: "details",
                optionsPath: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/details-options.html',
                defaultOptions: {}
            },
            {
                type: WidgetType.AGGR,
                category: 'general',
                name: 'Aggregations',
                simple: true,
                directive: "aggregations",
                optionsPath: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/aggregation-options.html',
                defaultOptions: {
                    docType: 1
                }
            },
            {
                type: WidgetType.DOCUMENT_LIST,
                category: 'document',
                name: 'Document list',
                simple: true,
                directive: "infinitelist",
                optionsPath: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/document-list-options.html',
                defaultOptions: {
                    docType: 0,
                    sortBy: 'relevance',
                    locationOnly: false
                }
            },
            // {
            //     type: WidgetType.DOCUMENT_DETAILS,
            //     category: 'document',
            //     name: 'Document details',
            //     simple: true,
            //     directive: "documentdetails",
            //     optionsPath: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/document-details-options.html',
            //     defaultOptions: {}
            // },
            {
                type: WidgetType.DOCUMENT_GEO,
                category: 'document',
                name: 'Document geo view',
                simple: true,
                directive: "documentgeo",
                optionsPath: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/document-geo-options.html',
                defaultOptions: {
                    spreadDensity: 0, // None by default
                    docGeoMaxLoad: 5000,
                    docType: 0
                }
            },
            {
                type: WidgetType.NEWS_LIST,
                category: 'news',
                name: 'News list',
                simple: true,
                directive: "timelinelist",
                optionsPath: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/news-list-options.html',
                defaultOptions: {
                    sortBy: 'timeline'
                }
            },
            {
                type: WidgetType.SOCIAL_LIST,
                category: 'social',
                name: 'Social list',
                simple: true,
                directive: "social-list",
                optionsPath: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/social-list-options.html',
                defaultOptions: {
                    sortBy: 'createdTime',
                    locationOnly: false
                }
            },
            // {
            //     type: WidgetType.SOCIAL_DETAILS,
            //     category: 'social',
            //     name: 'Social details',
            //     simple: true,
            //     directive: "social-details",
            //     optionsPath: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/document-details-options.html',
            //     defaultOptions: {}
            // },
            {
                type: WidgetType.SOCIAL_GEO,
                category: 'social',
                name: 'Social geo view',
                simple: true,
                directive: "documentgeo",
                optionsPath: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/social-geo-options.html',
                defaultOptions: {
                    spreadDensity: 0, // None by default
                    docGeoMaxLoad: 1000
                }
            }
            // {
            //     type: WidgetType.MULTI_WIDGET,
            //     name: 'Multi widget',
            //     simple: false,
            //     optionsPath: 'app/module/admin-organization/admin-organization-ui-templates/ao-uit-dashboard/multiwidget-options.html',
            //     defaultOptions: {
            //         widgets: [{}]
            //     }
            // }
        ];
        thiz.dashboardWidgetTypesByType = _.reduce(thiz.dashboardWidgetTypes , function(obj,wt) { obj[wt.type] = wt; return obj;}, {});

        thiz.defaultDashboards = {};
        thiz.defaultDashboards[DashboardType.DOCUMENT] = {
            icon: 'file-document-box-multiple',
            name: "Documents",
            type: DashboardType.DOCUMENT,
            locked: true,
            columns: [{widgets: [{ type: WidgetType.WORD_CLOUD }, { type: WidgetType.WORD_DETAILS }]},
                {widgets: [{ type: WidgetType.DOCUMENT_LIST, detailsId: 1 }]},
                {widgets: [{ type: WidgetType.DOCUMENT_GEO, detailsId: 1 }, { type: WidgetType.DETAILS, detailsId: 1 }]}]
        };
        thiz.defaultDashboards[DashboardType.DOCUMENT_SIMPLE] = {
            icon: 'file-document-box',
            name: "Simple Documents",
            type: DashboardType.DOCUMENT_SIMPLE,
            locked: true,
            columns: [{widgets: [{ type: WidgetType.WORD_CLOUD }, { type: WidgetType.DETAILS, detailsId: 1 }]},
                {widgets: [{ type: WidgetType.DOCUMENT_LIST, detailsId: 1 }]}]
        };
        thiz.defaultDashboards[DashboardType.NEWS] = {
            icon: 'newspaper',
            name: "News",
            type: DashboardType.NEWS,
            locked: true,
            columns: [{widgets: [{ type: WidgetType.NEWS_LIST, detailsId: 1 }]},{widgets: [{ type: WidgetType.DETAILS, detailsId: 1 }]}]
        };
        thiz.defaultDashboards[DashboardType.SOCIAL] = {
            icon: 'twitter',
            name: "Social",
            type: DashboardType.SOCIAL,
            locked: true,
            columns: [{widgets: [{ type: WidgetType.SOCIAL_LIST, detailsId: 1 }, { type: WidgetType.SOCIAL_GEO, detailsId: 1 }]},
                {widgets: [{ type: WidgetType.AGGR, docType: 100 }, { type: WidgetType.DETAILS, detailsId: 1 }]}]
        };
        thiz.defaultUiTemplate = {
            user: {
                dashboards: [thiz.defaultDashboards[DashboardType.DOCUMENT_SIMPLE],
                    thiz.defaultDashboards[DashboardType.DOCUMENT],
                    thiz.defaultDashboards[DashboardType.NEWS],
                    thiz.defaultDashboards[DashboardType.SOCIAL]]
            },
            anonymous: {
                dashboards: [thiz.defaultDashboards[DashboardType.DOCUMENT],
                    thiz.defaultDashboards[DashboardType.NEWS]]
            },
            trial: {
                dashboards: [thiz.defaultDashboards[DashboardType.DOCUMENT],
                    thiz.defaultDashboards[DashboardType.NEWS]]
            }
        };
        thiz.order = [
            { id: 0, name: 'Package A: 400 € excl. VAT'},
            { id: 1, name: 'Package B: 1200 € excl. VAT' },
            { id: 2, name: 'Package A: 300 € excl. VAT'},
            { id: 3, name: 'Package B: 900 € excl. VAT' }
        ];

        return thiz;

    }]);