
app.controller('editSourceCtrl', ['$scope', 'currentSource', 'addToOrganization', 'fromAdmin', 'Toast','$mdDialog',
    '$rootScope','config','ThriftHelper','Session','$filter',
    function ($scope, currentSource, addToOrganization, fromAdmin, Toast,$mdDialog,
              $rootScope,config,ThriftHelper,Session,$filter) {
        
        $scope.form = {};
        $scope.fromAdmin = fromAdmin;

        $scope.isAdd = currentSource===null || currentSource.id === undefined;
        if($scope.isAdd){
            $scope.title = "Add source";
            $scope.source = {
                type: currentSource!==null? currentSource.type:"news",
                keywords: [],
                accessType: "feed",
                id: UUID.generate()
            };
            if(fromAdmin===false){
                $scope.source.trusted = 10;
            }
        }else{
            $scope.title = "Edit source";
            $scope.source = currentSource;
            if(!angular.isArray($scope.source.keywords)){
                $scope.source.keywords = [];
            }
        }

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        
        $scope.save = function () {
            if($scope.form.source.$valid){

                tempSource = angular.copy($scope.source);
                ThriftHelper.sendRequest(new UpdateSource({
                    type: tempSource.type,
                    source: tempSource
                }),
                MsgType.UPDATE_SOURCE, config.calls.WS_EDIT_SOURCE);

            }
        };

        $scope.checkUrl = function(){
            if($scope.form.source.$valid){
                ThriftHelper.sendRequest(new CheckNewsFeedReq({
                        url: $scope.source.url
                    }), MsgType.CHECK_NEWS_FEED_REQ, config.calls.WS_EDIT_SOURCE_CHECK_URL);
            }
        };

        $scope.remove = function () {
            tempSource = currentSource;
            tempOrganization = $rootScope.user.organization;
            _.remove(tempOrganization.customNewsSources, function(id) { return id === tempSource.id;});
            ThriftHelper.sendRequest(new UpdateOrganization({organization: tempOrganization}),
                MsgType.UPDATE_ORGANIZATION, config.calls.WS_REMOVE_SOURCE_FROM_ORG);
        };

        $scope.$on(config.calls.WS_EDIT_SOURCE_CHECK_URL, function (event, args) {
            if(args.data instanceof CheckNewsFeedResp && args.data.feeds.length > 0){
                var feeds = args.data.feeds;
                console.log(feeds);
                var html = '<ul>';
                feeds.forEach(function (feed) {
                    html += '<li style="margin-bottom: 6px;"> '
                            +'<a href="'+feed.link+'" target="_blank">'+feed.title+'</a>'
                            +'<div style="font-size: 14px;">'+feed.content+'</div>'
                            + '</li>';
                });
                html += '</ul>';
                console.log(html);

                var confirm = $mdDialog.confirm()
                    .multiple(true)
                    .title('Are this news feeds correct?')
                    .htmlContent($filter('unsafe')(html))
                    .targetEvent(event)
                    .ok('Confirm')
                    .cancel('Cancel');

                $mdDialog.show(confirm).then(function() {
                    $scope.save();
                }, function() {
                    Toast.info("Please check feed url again!");
                });
            }else{
                Toast.error("Invalid feed url. Please check feed url again!");
            }
        });

        var tempSource = null;
        var tempOrganization = null;
        $scope.$on(config.calls.WS_EDIT_SOURCE, function (event, args) {
            if(args.err===false){
                if($scope.isAdd){
                    if(addToOrganization){
                        var o = angular.copy($rootScope.user.organization);
                        if(!angular.isArray(o.customNewsSources)){
                            o.customNewsSources = [];
                        }
                        o.customNewsSources.push(tempSource.id);
                        ThriftHelper.sendRequest(new UpdateOrganization({organization: o}),
                            MsgType.UPDATE_ORGANIZATION, config.calls.WS_EDIT_ORG_FROM_SOURCE);
                    }else{
                        Toast.success("Source was successfully added.");
                        $scope.$apply();
                        $mdDialog.hide(tempSource);
                    }
                }else{
                    var i = _.findIndex($rootScope.user.customSources,function(i){ return i.id===tempSource.id;});
                    if(i>-1){
                        $rootScope.user.customSources[i] = tempSource;
                    }

                    Toast.success("Source was successfully updated.");
                    $scope.$apply();
                    $mdDialog.hide(tempSource);
                }
            }else{
                Toast.error(args.err);
            }
        });

        $scope.$on(config.calls.WS_EDIT_ORG_FROM_SOURCE, function (event, args) {
            if(args.err===false) {
                if(!angular.isArray($rootScope.user.organization.customNewsSources)){
                    $rootScope.user.organization.customNewsSources = [];
                }
                $rootScope.user.organization.customNewsSources.push(tempSource.id);
                if(!angular.isArray($rootScope.user.customSources)){
                    $rootScope.user.customSources = [];
                }
                $rootScope.user.customSources.push(tempSource);
                Toast.success("Source was successfully added.");
                $scope.$apply();
                $mdDialog.hide(tempSource);
            }else{
                Toast.error(args.err);
            }
        });

        $scope.$on(config.calls.WS_REMOVE_SOURCE_FROM_ORG, function (event, args) {
            if (args.data === null) {
                Toast.success("Source was successfully deleted.");
                _.remove($rootScope.user.customSources, function(item) { return item.id === tempSource.id;});
                Session.set($rootScope.user.simpleUser, tempOrganization, $rootScope.user.cases,
                    $rootScope.user.twitterChannels,$rootScope.user.customSources,$rootScope.user.uiTemplate);
                console.log($rootScope.user);
                $mdDialog.hide("remove");
                $scope.$apply();
            }
        });

    }]);
