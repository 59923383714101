app.controller('documentDetailsOptionsCtrl', [
    '$scope', '$rootScope', 'documentDetailId',
    function ($scope, $rootScope, documentDetailId) {
        // by default "None" at index 0 is selected
        $scope.detailsViewId = 0;

        // listen for all layout changes to see if IDs has changed
        $scope.$on('onLayoutChanged', function (event, layout) {
            onLayoutChanged(layout);
        });

        // check IDs initially when widget is loaded
        onLayoutChanged($scope.$parent.layout);

        function onLayoutChanged(layout) {
            documentDetailId.generateId(layout);

            // notify all listeners that IDs has changed
            onDocumentDetailsIdsChanged();
        }

        function onDocumentDetailsIdsChanged() {
            $rootScope.$broadcast("onDocumentDetailsIdsChanged");
        }
    }
]);