app.directive('aggregations',
    [
        'ThriftHelper', 'config', '$timeout', '$stateParams', 'HitModel','$rootScope','DashboardService',
        'defaultsettings',
        function (ThriftHelper, config, $timeout, $stateParams, HitModel,$rootScope,DashboardService,
                  defaultsettings) {
            return {
                restrict: "E",
                replace: true,
                templateUrl: 'app/directives/aggregations/aggregations.html',
                scope: {
                    options: "="
                },
                link: function (scope, element, attrs) {
                    scope.service = DashboardService;
                    scope.id = "aggregations-" + UUID.generate();
                    scope.data = {
                        items: []
                    };
                    scope.aggrOptions = {};
                    scope.aggrOptions[AggregationType.TWITTER_TAGS] = {
                        template: "app/directives/aggregations/aggregations-twitter-tags.html",
                        name: "Twitter tags",
                        intext: "Twitter tags"
                    };
                    scope.aggrOptions[AggregationType.TWITTER_USER_ID] = {
                        template: "app/directives/aggregations/aggregations-twitter-users.html",
                        name: "Twitter users",
                        intext: "Twitter users"
                    };
                    scope.aggrOptions[AggregationType.KEYWORDS] = {
                        template: "app/directives/aggregations/aggregations-keywords.html",
                        name: "Keywords",
                        intext: "keywords"
                    };
                    scope.aggrOptions[AggregationType.FOS] = {
                        template: "app/directives/aggregations/aggregations-fos.html",
                        name: "FOS",
                        intext: "FOS"
                    };
                    scope.aggrOptions[AggregationType.PUBLISHER] = {
                        template: "app/directives/aggregations/aggregations-publishers.html",
                        name: "Publishers",
                        intext: "publishers"
                    };
                    scope.aggrOptions[AggregationType.YEAR] = {
                        template: "app/directives/aggregations/aggregations-years.html",
                        name: "Years",
                        intext: "years"
                    };
                    var hasChannel = angular.isObject($rootScope.user.currentTwitterChannel);
                    if(hasChannel){
                        scope.sources = {
                            all: defaultsettings.aggDocTypes
                        }
                    }else{
                        scope.sources = {
                            all: _.filter(defaultsettings.aggDocTypes,function (item) {
                                    return item.id < 100;
                                })
                        }
                    }
                    if(angular.isNumber(scope.options.docType)) {
                        scope.sources.selected = _.findWhere(scope.sources.all, {id: scope.options.docType});
                    }else{
                        scope.sources.selected = scope.sources.all[0];
                    }


                    var getCall = function () {
                        return config.calls.WS_AGGREGATION + "-" + scope.id;
                    };

                    scope.$on(getCall(), function (event, args) {
                        if(args.reqId < scope.data.minReqId){
                            return;
                        }
                        var data = args.data;
                        if(data instanceof ErrorMsg || !angular.isArray(data.aggResults)){
                            console.log("ERROR in aggregation");
                            console.log(args);
                            scope.$apply();
                            return;
                        }
                        for(var i in data.aggResults){
                            var agg = data.aggResults[i];
                            scope.data.results[agg.type] = _.filter(agg.keys, function (item) {
                                return item.key !== '';
                            });
                            if(agg.type === AggregationType.TWITTER_USER_ID){
                                loadTwitterUsers(_.pluck(scope.data.results[agg.type],'key'));
                            }else{
                                scope.data.loading[agg.type] = false;
                            }
                        }
                        scope.$apply();
                    });

                    //start
                    var init_main = function () {
                        scope.data = {
                            minReqId: $rootScope.reqId,
                            results: {},
                            loading: {},
                            aggs: []
                        };

                        var dto = {};
                        if(scope.sources.selected.type === "social"){
                            dto = scope.service.keywords.toSocialDto(0, 0);
                            scope.data.aggs = [AggregationType.TWITTER_USER_ID, AggregationType.TWITTER_TAGS];
                        }else{
                            dto = scope.service.keywords.toDto(0, 0,{ type: scope.sources.selected.type });
                            if(scope.sources.selected.type === "article"){
                                scope.data.aggs = [AggregationType.YEAR, AggregationType.PUBLISHER,AggregationType.FOS,AggregationType.KEYWORDS];
                            }else{
                                scope.data.aggs = [AggregationType.YEAR];
                            }
                        }
                        for(var i in scope.data.aggs){
                            var a = scope.data.aggs[i];
                            var d = angular.copy(dto);
                            d.aggregation = [a];
                            scope.data.loading[a] = true;
                            ThriftHelper.sendRequest(new SearchEsReq(d), MsgType.SEARCH_ES_REQ, getCall());
                        }
                    };
                    scope.init = _.debounce(init_main, 100);
                    scope.init();

                    scope.$watch("service.keywords", function (nval, oval) {
                        if (nval && nval.data && oval && oval.data) {
                            if (oval.data.length !== nval.data.length) {
                                scope.init();
                            } else {
                                for (var i = 0; i < nval.data.length; i++) {
                                    if (oval.data[i].toString() !== nval.data[i].toString()) {
                                        scope.init();
                                        return;
                                    }
                                }
                            }
                        }
                    }, true);


                    var wsGetEs = function () {
                        return config.calls.WS_GET_ES + "-" + scope.id;
                    };

                    function loadTwitterUsers(ids) {
                        scope.loadingUsers = true;
                        var dto = {
                            type: "twitter",
                            indexName: 'twitter_users',
                            ids: ids
                        };
                        ThriftHelper.sendRequest(new GetEsReq(dto),MsgType.GET_ES_REQ, wsGetEs());
                    }

                    scope.$on(wsGetEs(), function (event, args) {
                        var data = args.data;
                        if(data instanceof ErrorMsg || !angular.isArray(data.twitterUsers)){
                            console.log("ERROR in aggregation wsGetEs");
                            console.log(args);
                            scope.$apply();
                            return;
                        }

                        var results = scope.data.results[AggregationType.TWITTER_USER_ID];
                        for(var i = 0; i<results.length;i++){
                            var u = _.findWhere(data.twitterUsers,{id: results[i].key});
                            if(u) {
                                results[i].key = u;
                            }
                        }
                        scope.data.loading[AggregationType.TWITTER_USER_ID] = false;
                        scope.$apply();
                    });




                    
                    scope.selectWord = function (keyword) {
                        var word = 'w:' + keyword;
                        scope.service.onWordClick(word);
                    };

                    scope.selectFos = function (keyword) {
                        var fos = 'f:' + keyword;
                        scope.service.onWordClick(fos);
                    };

                    scope.selectYear = function (keyword) {
                        var year = 'y:' + keyword;
                        scope.service.onWordClick(year);
                    };

                }
            };
        }
    ]);