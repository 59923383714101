
app.controller('exportCtrl', ['$scope', 'settings', 'type','Toast', 'Session','TweetModel',
    '$mdDialog', 'ThriftHelper', 'config', 'HitModel','FileSaver','Blob','ExportService',
    function ($scope, settings, type, Toast, Session,TweetModel,
            $mdDialog, ThriftHelper, config, HitModel,FileSaver,Blob,ExportService) {

        $scope.form = {};

        $scope.title = "Export";

        $scope.type = type;
        /*var exportSelected = !(settings.selected === undefined || settings.selected.length === 0);

        $scope.show = {
            types: _.contains(["infinitlist","complexity","word-details","treemap",
                "word-cloud","timeline","social-list","document-details","social-details","news-details"],type),
            start: _.contains(["infinitlist","timeline","social-list"],type) && !exportSelected,
            size: _.contains(["infinitlist","timeline","social-list"],type) && !exportSelected,
            description: _.contains(["infinitlist","timeline","social-list","complexity","word-details","treemap","word-cloud"],type),
            selectedOnly: _.contains(["infinitlist"], type)
        };*/
        $scope.show = {
            types: _.contains(["infinitlist","complexity","word-details","treemap",
                "word-cloud","timeline","social-list","document-details","social-details","news-details"],type),
            start: _.contains(["infinitlist","timeline","social-list"],type),
            size: _.contains(["infinitlist","timeline","social-list"],type),
            description: _.contains(["infinitlist","timeline","social-list","complexity","word-details","treemap","word-cloud"],type),
            selectedOnly: _.contains(["infinitlist"], type)
        };
        $scope.loading = {
            text: "",
            status: false
        };

        var allExportTypes = [
            {id: 0, name: "PDF",ending: ".pdf", show: ["infinitlist","timeline","social-list","document-details",
                    "social-details","news-details"], showDesc: true, selectedOnly: true},
            {id: 1, name: "BibTex",ending: ".bib", show: ["infinitlist","document-details"], showDesc: false, selectedOnly: true},
            {id: 2, name: "RIS",ending: ".ris", show: ["infinitlist","document-details"], showDesc: false, selectedOnly: true},
            {id: 3, name: "CSV",ending: ".csv", show: ["infinitlist","timeline","social-list","document-details",
                    "social-details","news-details"], showDesc: false, selectedOnly: true},
            {id: 4, name: "PNG",ending: ".png", show: ["complexity","word-details","treemap","word-cloud"], showDesc: true, selectedOnly: false},
            {id: 5, name: "SVG",ending: ".svg", show: ["complexity","word-details","treemap","word-cloud"], showDesc: true, selectedOnly: false},
            {id: 99, name: "",ending: ".png", show: ["geo"], showDesc: false, selectedOnly: false}
        ];

        $scope.exportTypes = _.filter(allExportTypes,function (item) {
            return _.contains(item.show,type);
        });

        $scope.delimiters = [
            {key: ";", name: "; (semicolon)"},
            {key: ",", name: ", (comma)"},
            {key: "\t", name: "TAB"},
            {key: ":", name: ": (colon)"}
        ];

        $scope.data = {};
        if(type === "infinitlist"){
            $scope.data = {
                start: settings.dto.start + 1,
                size: 100,
                type: $scope.exportTypes[0],
                name: "documents",
                delimiter: $scope.delimiters[0],
                description: true,
                selectedOnly: !(settings.selected === undefined || settings.selected.length === 0)
            };
        }else if(type === "timeline"){
            $scope.data = {
                start: settings.dto.start + 1,
                size: 100,
                type: $scope.exportTypes[0],
                name: "news",
                delimiter: $scope.delimiters[0],
                description: true,
                selectedOnly: false
            };
        }else  if(type === "social-list"){
            $scope.data = {
                start: settings.dto.start + 1,
                size: 100,
                type: $scope.exportTypes[0],
                name: "tweets",
                delimiter: $scope.delimiters[0],
                description: true,
                selectedOnly: false
            };
        }else if(type === "complexity" || type === "word-details" || type==="treemap" || type === "word-cloud"){
            $scope.data = {
                type: $scope.exportTypes[0],
                name: type,
                description: true,
                selectedOnly: false
            };
        }else if(type === "geo"){
            $scope.data = {
                type: $scope.exportTypes[0],
                name: type
            };
        }else if(type === "document-details" || type==="social-details" || type==="news-details"){
            $scope.data = {
                type: $scope.exportTypes[0],
                name: type+"-"+(type !== "social-details"?settings.item.title:settings.item.tweetText)
                    .substring(0,30).replace(new RegExp(" ", 'g'),"-"),
                delimiter: $scope.delimiters[0],
                description: false,
                selectedOnly: false
            };
        }

        if(type!== "document-details" && type!=="social-details" && type!=="news-details") {
            $scope.data.name += moment().format("-YYYY-MM-DD-HH-mm-ss");
        }

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.export = function () {
            if ($scope.form.export.$valid) {
                $scope.loading.status = true;
                $scope.loading.text = "Loading...";
                if (type === "infinitlist" || type === "timeline" || type === "social-list") {
                    var d = angular.copy(settings.dto);
                    if ($scope.data.selectedOnly) {
                        d.ids = settings.selected;
                        ThriftHelper.sendRequest(new GetEsReq(d), MsgType.GET_ES_REQ, config.calls.WS_EXPORT);
                    } else {
                        d.size = $scope.data.size;
                        d.start = $scope.data.start-1;
                        ThriftHelper.sendRequest(new SearchEsReq(d), MsgType.SEARCH_ES_REQ, config.calls.WS_EXPORT);
                    }
                } else if(type=== "complexity" || type === "word-details" || type==="treemap" || type==="treemap" || type==="word-cloud") {
                    finishExport(settings.svg);
                }else if(type=== "geo"){
                    finishExport(settings.map);
                }else if(type=== "document-details" || type==="social-details" || type==="news-details"){
                    finishExport([settings.item]);
                }
            }
        };

        $scope.$on(config.calls.WS_EXPORT, function (event, args) {
            if(args.data instanceof ErrorMsg){
                $scope.loading = false;
                return;
            }


            $scope.loading.text = "Creating file...";
            var docs = [];
            var Model = HitModel;
            var arr = args.data.documents2;
            if ($scope.data.selectedOnly) {
                arr = args.data.documents;
            } else if (type === "timeline") {
                arr = args.data.documents;
            } else if(type === "social-list"){
                arr = args.data.tweets;
                Model = TweetModel;
            }

            for (var i = 0; i < arr.length; i++) {
               docs.push(new Model(arr[i]));
            }

            finishExport(docs);
            $scope.$apply();
        });

        var finishExport = function (mainData) {
            switch($scope.data.type.id){
                case 0:
                    ExportService.exportPdf(type,mainData,$scope.data.name,
                        $scope.data.description?settings.description:undefined,
                        function () {
                            $scope.loading.status = false;
                            $mdDialog.hide();
                            $scope.$apply();
                        },
                        function () {
                            $scope.loading.status = false;
                            $scope.$apply();
                            Toast.error("Error downloading pdfmake...");
                        });
                    break;
                case 1:
                    ExportService.exportBib(type,mainData,$scope.data.name);
                    $scope.loading.status = false;
                    $mdDialog.hide();
                    break;
                case 2:
                    ExportService.exportRis(type,mainData,$scope.data.name);
                    $scope.loading.status = false;
                    $mdDialog.hide();
                    break;
                case 3:
                    ExportService.exportCsv(type,mainData,$scope.data.name,$scope.data.delimiter.key);
                    $scope.loading.status = false;
                    $mdDialog.hide();
                    break;
                case 4:
                    ExportService.exportPng(type,mainData,$scope.data.name,
                        $scope.data.description?settings.description:undefined);
                    $scope.loading.status = false;
                    $mdDialog.hide();
                    break;
                case 5:
                    ExportService.exportSvg(type,mainData,$scope.data.name,
                        $scope.data.description?settings.description:undefined);
                    $scope.loading.status = false;
                    $mdDialog.hide();
                    break;
                case 99:
                    ExportService.exportMap(type,mainData,$scope.data.name);
                    $scope.loading.status = false;
                    $mdDialog.hide();
                    break;

            }
        }

    }]);
