/* global app, _ */

/**
 * Created by matejk on 17.10.2014.
 * search2Ctr site has directives: headermenu, wordcloud, foot
 *
 */

app.controller('socialCtrl', ['$scope', '$stateParams', 'config', 'mobile', '$state',
    'defaultsettings', 'Session', 'QueryModel','HeaderOptions','$rootScope',
    function ($scope, $stateParams, config, mobile, $state,
              defaultsettings, Session, QueryModel,HeaderOptions,$rootScope) {

        $scope.getChannelsStatus = function(){
            if(Session.isAnonymousOrTrial()){
                return -1;
            }
            var tc = $rootScope.user.twitterChannels;
            var maxtc = $rootScope.user.organization.maxNoOfTwitterChannels;
            if(maxtc === null || maxtc === 0){
                return -1;
            } else if(tc === null || (angular.isArray(tc) && tc.length === 0)){
                return 0;
            }
            return 1;
        };


        $scope.keywords = new QueryModel($stateParams.q,"social");

        $scope.layouts = {
            all: [],
            mobile: undefined,
            selected: undefined
        };

        var setLayout = function(){
            if(mobile()){
                $scope.layouts.all = defaultsettings.layoutSettings.social.mobile;
                $scope.layouts.mobile = true;
            }else{
                $scope.layouts.all = defaultsettings.layoutSettings.social.desktop;
                $scope.layouts.mobile = false;
            }
            var c = $scope.layouts.all[0];
            // var d = _.findWhere($scope.layouts.all, {default: true});
            // if(d){
            //     c = d;
            // }
            // if ($stateParams.layout) {
            //     var s = _.findWhere($scope.layouts.all, {name: $stateParams.layout});
            //     if (s) {
            //         c = s;
            //     }
            // }
            $scope.layouts.selected = c;
        };
        setLayout();

        $scope.redirect = function () {
            var params = {
                q: undefined
                //layout: undefined
            };
            if (!$scope.keywords.isEmpty()) {
                params.q = $scope.keywords.toString();
            }
            //params.layout = $scope.layouts.selected.name;
            
            $state.go("app.social", params, {
                reload: false,
                inherit: true,
                notify: false
            });
        };

        $scope.mainToolsOptions = {
            keywords: $scope.keywords,
            redirect: $scope.redirect,
            layouts: $scope.layouts
        };

        $scope.controls = {
            sociallist: {
                keywords: $scope.keywords,
                onWordClick: function (word) {
                    $scope.keywords.addSingleKeyword(word);
                    $scope.redirect();
                }
            },
            socialdetails: {
                onWordClick: function (word) {
                    $scope.keywords.addSingleKeyword(word);
                    $scope.redirect();
                }
            },
            socialagg: {
                keywords: $scope.keywords,
                onWordClick: function (word) {
                    $scope.keywords.addSingleKeyword(word);
                    $scope.redirect();
                }
            },
            documentgeo: {
                keywords: $scope.keywords,
                page: "social"
            }
        };


        // --------------
        // --- SET HEADER OPTIONS ---
        // --------------
        if($scope.getChannelsStatus() < 0){
            HeaderOptions.set([]);
        }else {
            HeaderOptions.set([
                // HeaderOptions.defaultOptions.layouts($scope.layouts, $scope.redirect, $scope,setLayout),
                HeaderOptions.defaultOptions.switchTwitterChannel($scope.redirect)
            ]);
        }

        // --------------
        // --- RESIZE ---
        // --------------
        var resizeOff = $scope.$on("resize", function (event, args) {
            if(mobile()!== $scope.layouts.mobile){
                setLayout();
            }
        });

        $scope.$on("$destroy", function () {
            resizeOff();
            //element.remove();
        });

    }
]);
