
app.controller('loginDialogCtrl', ['$scope', 'toaster', '$state', 'Session', '$rootScope',
    '$mdDialog', 'ThriftHelper', 'config',
    function ($scope, toaster, $state, Session, $rootScope,
            $mdDialog, ThriftHelper, config) {

        $scope.form = {};
        $scope.title = "Percipio Login";

        $scope.login = {
            email: "",
            passwd: ""
        };

        $scope.loging = false;
        $scope.error = '';

        $scope.loginClick = function () {
            $scope.loging = true;
            $scope.error = '';
            if ($scope.form.login.$valid) {
                Session.login($scope.login);
            }
        };

        $scope.$on("login-response", function (event, args) {
            if (args.data.error === null) {
                // $timeout(function () {
                $mdDialog.cancel();
                var userData = "Logged in as " + $rootScope.user.firstName;
                if ($rootScope.user.lastName) userData += " " + $rootScope.user.lastName;
                toaster.pop("success", "Logged in", userData);
                $state.go('app.dashboard',{}, {location: 'replace'});
                // },0);
            } else {
                if (args.data.error === "WS") {
                    toaster.pop("error", "No connection", "Can't connect to the server...");
                } else {
                    $scope.error = args.data.error;
                }
                $scope.loging = false;

                $scope.$apply();
            }
        });

        $scope.trialClick = function () {
            $mdDialog.hide();
        };

    }]);
