app.directive("documentgeo", ["Session", "$state", 'ThriftHelper','ExportService','TweetModel','DashboardService',
    'config', 'CloudModel', '$window', '$timeout', '$interpolate', '$templateRequest', 'defaultsettings', 'HitModel', '$rootScope',
    function (Session, $state, ThriftHelper,ExportService,TweetModel,DashboardService,
              config, CloudModel, $window, $timeout, $interpolate, $templateRequest, defaultsettings, HitModel, $rootScope) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/directives/document-geo/document-geo.html',
            scope: {
                options: "="
            },
            link: function (scope, element, attrs) {
                scope.service = DashboardService;
                scope.widgetType = scope.options.type;
                scope.showDocTypes = scope.widgetType === WidgetType.DOCUMENT_GEO;
                var METER_TO_DEGREE = 0.00001;
                var BATCH_SIZE = 50;

                var tileServer = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                // var tileServer = 'https://enterprise.percipio-big-data.com/tiles/openstreetmap-carto/tile/{z}/{x}/{y}.png';

                /**
                 * Template should be plain HTML + variables. We only interpolate it (no compile) for performance purpose.
                 */
                var popupTemplateFile = 'app/directives/document-geo/document-geo-popup.html';
                var popupTemplate = '';

                $templateRequest(popupTemplateFile).then(function (template) {
                    popupTemplate = template;
                }, function (error) {
                    console.error('documentgeo - failed to get document-geo-popup: ' + error)
                });

                var map;
                var markers;
                var printPlugin;

                var componentId = UUID.generate();
                scope.noData = true;
                scope.id = "document-geo-" + componentId;
                console.log(componentId, scope.options);
                scope.mapId = "document-geo-map-" + componentId;
                scope.reloadId = "document-geo-map-reload-" + componentId;
                scope.exportId = "document-geo-map-export-" + componentId;
                scope.settingsId = "document-geo-map-settings-" + componentId;

                /**
                 * Called when Refresh button on Map is clicked.
                 */
                scope.doRefresh = function () {
                    reloadDocuments();
                };

                /**
                 * Called when Export button on Map is clicked.
                 */
                scope.doExport = function ($event) {
                    var settings = {
                        map: printPlugin
                    };
                    ExportService.exportDialog("geo",settings,$event);
                };

                /**
                 * Called when Map Settings has changed (document type filter, range, ...)
                 */
                scope.settingsChanged = function () {
                    reloadDocuments();
                };

                scope.loadingProgress = {
                    progress: 0,
                    total: undefined,
                    mode: 'query'
                };
                scope.loading = true;

                scope.type = {
                    selected: defaultsettings.articleTypes[1],
                    all: _.filter(defaultsettings.articleTypes, function (item) {
                        return item.id >= 0 && item.id <= 9;
                    })
                };
                if (scope.options.docType) {
                    var s = _.findWhere(scope.type.all, {id: scope.options.docType});
                    if (s) {
                        scope.type.selected = s;
                    }
                }
                scope.sortBy = {
                    selected: defaultsettings.sortBy[1],
                    all: defaultsettings.sortBy
                };

                if(scope.widgetType === WidgetType.SOCIAL_GEO){
                    scope.type = {
                        selected: {type: "social"},
                        all: [{type: "social"}]
                    };
                }

                // loads Map initially
                $timeout(function () {
                    console.log(scope.mapId);
                    loadMap(scope.mapId);
                });

                scope.$watch("service.keywords", function (nval, oval) {
                    if (nval && nval.data && oval && oval.data) {
                        if (oval.data.length !== nval.data.length) {
                            reloadDocuments();
                        } else {
                            for (var i = 0; i < nval.data.length; i++) {
                                if (oval.data[i].toString() !== nval.data[i].toString()) {
                                    reloadDocuments();
                                    return;
                                }
                            }
                        }
                    }
                }, true);

                /**
                 * Causes load/reload of Documents from server to Map.
                 */
                function reloadDocuments() {
                    markers.clearLayers();

                    var bounds = map.getBounds();
                    var topLeft = Geohash.encode(bounds.getNorth(), bounds.getWest());
                    var bottomRight = Geohash.encode(bounds.getSouth(), bounds.getEast());

                    if (scope.docGeoTask) {
                        // cancels previous loading
                        scope.docGeoTask.cancel();
                    }

                    var documentGeoMaxLoad = scope.options.docGeoMaxLoad || 5000;



                    // starts loading
                    if(!(scope.widgetType === WidgetType.SOCIAL_GEO && !angular.isObject($rootScope.user.currentTwitterChannel))){
                        scope.docGeoTask = new DocumentGeoTask(scope, $timeout, config, ThriftHelper, documentGeoMaxLoad, BATCH_SIZE, topLeft, bottomRight, function (error, finished, documents) {
                            if (documents && documents.length > 0) {
                                loadDocuments(documents);
                            }
                        });
                        scope.docGeoTask.start(topLeft, bottomRight);
                    }else{
                        scope.loading = false;
                    }
                }

                /**
                 * Loads map and afterwards load Documents from the Server.
                 * @param mapId The HTML Map ID
                 */
                function loadMap(mapId) {
                    // used $timeout to ensure that Map (div) was rendered in first cycle
                    $timeout(function () {
                        console.log(mapId);
                        scope.noData = false;
                        map = L.map(mapId).setView([0, 0], 1);

                        L.tileLayer(tileServer, {
                            attribution: 'Map data &copy <a href="http://openstreetmap.orgb">OpenStreetMap</a> contributors',
                            maxZoom: 18
                        }).addTo(map);

                        markers = L.markerClusterGroup({
                            maxClusterRadius: 30
                        });
                        map.addLayer(markers);
                        map.addControl(createMapReloadControl());
                        map.addControl(createMapExportControl());
                        map.addControl(createSettingsControl());

                        printPlugin = L.easyPrint({
                            hidden: true,
                            exportOnly: true
                        }).addTo(map);

                        // give some time to other components to load
                        $timeout(function () {
                            reloadDocuments();
                        }, 500);
                    });
                }

                /**
                 * Creates Reload button Control.
                 *
                 * @returns {*}
                 */
                function createMapReloadControl() {
                    var Control = createMapControl('topleft', scope.reloadId);

                    return new Control();
                }

                /**
                 * Creates Export button Control.
                 *
                 * @returns {*}
                 */
                function createMapExportControl() {
                    var Control = createMapControl('topleft', scope.exportId);

                    return new Control();
                }

                /**
                 * Creates Settings panel.
                 *
                 * @returns {*}
                 */
                function createSettingsControl() {
                    var Control = createMapControl('topright', scope.settingsId);

                    return new Control();
                }

                /**
                 * Creates Control for Map.
                 *
                 * @param position The position value
                 * @param componentId The component ID
                 */
                function createMapControl(position, componentId) {
                    return L.Control.extend({
                        options: {
                            position: position
                        },

                        onAdd: function (map) {
                            return L.DomUtil.get(componentId);
                        }
                    });
                }

                /**
                 * Loads specified documents received from the Server into Map.
                 *
                 * @param documents The documents to load
                 */
                function loadDocuments(documents) {
                    documents.forEach(function (document) {
                        if (document.geohash !== null) {
                            if(angular.isArray(document.geohash)){
                                document.geohash.forEach(function (item) {
                                    loadDocumentsGeohash(item, document);
                                });
                            }else{
                                loadDocumentsGeohash(document.geohash, document);
                            }

                        }
                    });
                }

                /**
                 * Converts Document to specific model.
                 *
                 * @param geohash The GeoHash location from server
                 * @param document The Document to load
                 */
                function loadDocumentsGeohash(geohash,document){
                    var data = {};
                    if(scope.widgetType === WidgetType.SOCIAL_GEO){
                        data = new TweetModel(document);
                    }else{
                        data = new HitModel(document);
                    }
                    loadDocumentAsMarker(data, Geohash.decode(geohash));
                };

                /**
                 * Loads specified Document at specified location.
                 *
                 * @param document The Document to load
                 * @param geohash The GeoHash location
                 */
                function loadDocumentAsMarker(document, geohash) {
                    var position = spreadDensity(geohash.lat, geohash.lon);

                    var title = document.title;
                    if(document.type === "tweet"){
                        title = document.tweetText;
                    }

                    var marker = L.marker(new L.LatLng(position.lat, position.lng), {title: title});

                    var popupAuthors = createPopupAuthors(document);
                    document.popupAuthors = popupAuthors;

                    if (scope.options.detailsId && scope.options.detailsId !== 0) {
                        marker.on('click', function (event) {
                            $timeout(function () {
                                var eventId = 'onItemSelected_' + scope.options.detailsId;
                                $rootScope.$broadcast(eventId, document);
                            });
                        });
                    } else {
                        marker.bindPopup(interpolatePopupHtml(document));
                    }

                    markers.addLayer(marker);
                }

                /**
                 * Spreads Marker locations on map randomly based on spreadDensity value from options.
                 *
                 * @param lat
                 * @param lng
                 * @returns {{lat: *, lng: *}}
                 */
                function spreadDensity(lat, lng) {
                    if (scope.options.spreadDensity === 0) {
                        return {
                            lat: lat,
                            lng: lng
                        }
                    } else {
                        var spread = scope.options.spreadDensity * METER_TO_DEGREE;
                        return {
                            lat: lat + ((Math.random() - 0.5) * spread),
                            lng: lng + ((Math.random() - 0.5) * spread)
                        }
                    }
                }

                /**
                 * Interpolates popup template HTML using specified item.
                 *
                 * @param item The popup
                 * @returns {*}
                 */
                function interpolatePopupHtml(item) {
                    return $interpolate(popupTemplate)({
                        item: item
                    });
                }

                /**
                 * Creates authors string for Map popup.
                 *
                 * Because of large amount of markers we can hit to performance issue. If we would have angular template,
                 * each marker popup would require creating of new Scope and compilation using $compile. Creating scopes
                 * and compilations are slow.
                 *
                 * To solve performance issue we have non-angular template (plain html with parameters {{}}) on which we
                 * are doing just interpolation.
                 *
                 * This function is used to prepare final authors string which is shown in popup. Code is based on infinite
                 * list "infinite-list.html" file.
                 *
                 * @param document The document
                 * @returns {string} The authors string
                 */
                function createPopupAuthors(document) {
                    var result = '';

                    if (document.type === 'article') {
                        if (document.authors && document.authors.length > 0) {
                            result += document.authorsString;
                        }
                        if (document.authors && document.authors.length > 0 && (document.journal || document.year)) {
                            result += ' - ';
                        }
                        if (document.journal) {
                            result += document.journal;
                        }
                        if (document.journal && document.year && document.year.length > 0) {
                            result += ', ';
                        }
                        if (document.year) {
                            result += document.year;
                        }
                    } else if (document.type === 'patent') {
                        if (document.authors && document.authors.length > 0) {
                            result += document.authorsString;
                        }
                        if (document.authors && document.authors.length > 0 && (document.id || document.year)) {
                            result += ' - ';
                        }
                        if (document.id && document.year && document.year.length > 0) {
                            result += ', ';
                        }
                        if (document.year) {
                            result += document.year;
                        }
                    } else if (document.type === 'news') {
                        if (document.journal) {
                            result += document.journal;
                        }
                        if (document.journal && document.date) {
                            result += ', ';
                        }
                        if (document.date) {
                            result += document.date;
                        }
                    } else if (document.type === 'wiki') {
                        if (document.journal) {
                            result += document.journal;
                        }
                    } else if (document.type === 'tweet') {
                        if (document.journal) {
                            result += document.journal;
                        }
                    }

                    return result;
                }
            }
        };
    }]);

/**
 * Used to wrap the code for loading Documents from the Server by chunks.
 *
 * @param $scope The Angular Scope
 * @param $timeout The Angular $timeout function
 * @param $config The Configuration
 * @param ThriftHelper The Thrift Helper
 * @param maxDocumentsCount The maximum number of Documents to load
 * @param batchSize The batch size
 * @param topLeft The top-left geohash coordinate of the current view
 * @param bottomRight The bottom-right geohash coordinate of the current view
 * @param cb The callback (error, finished, documents)
 * @constructor
 */
function DocumentGeoTask($scope, $timeout, $config, ThriftHelper, maxDocumentsCount, batchSize, topLeft, bottomRight, cb) {
    this.taskId = UUID.generate();
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$config = $config;
    this.ThriftHelper = ThriftHelper;
    this.started = false;
    this.callback = cb;
    this.callId = this.$config.calls.WS_INFINITE_LIST + "-" + this.$scope.id + "-geoTask-" + this.taskId;
    this.maxDocumentsCount = maxDocumentsCount;
    this.batchSize = batchSize;
    this.canceled = false;
    this.topLeft = topLeft;
    this.bottomRight = bottomRight;
}

/**
 * Starts the Task if it is not started or yet.
 */
DocumentGeoTask.prototype.start = function () {
    if (this.started === false) {
        var self = this;
        this.$scope.$on(this.callId, function (event, args) {
            self.onDataReceived(args);
        });

        this.started = true;

        // starts loading, but give some time to other components first to load
        this.$timeout(function () {
            self.loadData();
        }, 500);
    }
};

/**
 * Handler function to handle response received from the Server.
 *
 * @param args The arguments
 */
DocumentGeoTask.prototype.onDataReceived = function (args) {
    var self = this;
    // first we need to ensure that current task was not cancelled
    if (self.canceled === true) {
        // do nothing, was already cancelled
        return;
    }

    var data = args.data;
    if (data instanceof ErrorMsg) {
        self.notify(data, true, null);
        return;
    }

    console.log(data);
    self.updateProgress(data);

    self.$scope.loading = self.loadData(data.start, data.total);

    if (self.$scope.loading == false) {
        self.notify(null, true, null);
    }

    self.$scope.$apply();
};

/**
 * Update progress after the Data is received.
 *
 * @param data The received data
 */
DocumentGeoTask.prototype.updateProgress = function (data) {
    var self = this;
    self.$scope.loadingProgress.total = data.total;

    var docs = [];
    if(this.$scope.widgetType === WidgetType.SOCIAL_GEO){
        docs = data.tweets;
    }else {
        docs = data.documents2;
    }

    if (docs && docs.length > 0) {
        self.notify(null, false, docs);
        var limit = Math.min(self.maxDocumentsCount, self.$scope.loadingProgress.total);

        var progress = (data.start / limit) * 100;
        self.$scope.loadingProgress.progress = progress;

        self.$scope.loadingProgress.mode = 'determinate';
    }
};

/**
 * Loads data from the server by chunks.
 *
 * @param previousStart The previous value of the start. If not provided, loading will begin from the start (0).
 *
 * @returns {boolean} true if there is more data to load, otherwise false
 */
DocumentGeoTask.prototype.loadData = function (previousStart, totalFromResponse) {
    if (totalFromResponse <= 0) {
        return false; // no data to load
    }
    var self = this;
    var start = 0;
    var total = this.$scope.loadingProgress.total || this.maxDocumentsCount;
    var limit = Math.min(this.maxDocumentsCount, total);

    if (previousStart !== undefined) {
        start = previousStart + this.batchSize;
    }

    if (previousStart === undefined || (previousStart + this.batchSize) < limit) {
        this.$timeout(function () {
            self.loadDataChunk(start);
        }, 5); // postpone for 5 ms so UI is more responsive
        return true;
    } else {
        // we finished loading
        return false;
    }
};

/**
 * Triggers Data loading from specified element up to BATCH_SIZE.
 *
 * @param from
 */
DocumentGeoTask.prototype.loadDataChunk = function (from) {
    var range = new GeohashRange({
        tl: this.topLeft,
        br: this.bottomRight
    });

    var dto = {};
    if(this.$scope.widgetType === WidgetType.SOCIAL_GEO){
        dto = this.$scope.service.keywords.toSocialDto(from, this.batchSize,
            undefined, {
            sortBy: this.$scope.sortBy.selected.id
        });
    }else {
        dto = this.$scope.service.keywords.toDto(from, this.batchSize, {
            type: this.$scope.type.selected.type,
            sortBy: this.$scope.sortBy.selected.id
        });
    }


    dto.geohashRange = range;
    console.log(dto);

    // first ensure that current task is not cancelled
    if (this.canceled === false) {
        this.ThriftHelper.sendRequest(new SearchEsReq(dto), MsgType.SEARCH_ES_REQ, this.callId);
    }
};

/**
 * Notifies listener about progress.
 *
 * @param error The error or null
 * @param finished The finished flag (true/false)
 * @param documents The loaded documents or null
 */
DocumentGeoTask.prototype.notify = function (error, finished, documents) {
    if (this.callback) {
        this.callback(error, finished, documents);
    }
};

/**
 * Cancels Document Geo Task.
 */
DocumentGeoTask.prototype.cancel = function () {
    this.canceled = true;
};