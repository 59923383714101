
app.controller('adminOrganizationsCtrl', ['$scope', 'config', 'Toast', 'ThriftHelper', '$mdDialog','adminService',
    function ($scope, config, Toast, ThriftHelper, $mdDialog, adminService) {

        //public variables
        $scope.organizations = adminService.data.organizations;
        $scope.cases = adminService.data.cases;
        $scope.twitterChannels = adminService.data.twitterChannels;
        $scope.getter = adminService.getter;
        $scope.init = adminService.init;

        adminService.getOrganizations();
        adminService.getCases();

        $scope.$watch(function(){ return adminService.data.organizations;}, function(newVal){
            $scope.organizations = newVal;
        });
        $scope.$watch(function(){ return adminService.data.cases;}, function(newVal){
            $scope.cases = newVal;
        });
        $scope.$watch(function(){ return adminService.data.twitterChannels;}, function(newVal){
            $scope.twitterChannels = newVal;
        });

        $scope.$on(config.calls.WS_DELETE_ORGANIZATION, function (event, args) {
            Toast.success("Organization was successfully deleted.");
            adminService.getOrganizations();
        });

        $scope.deleteOrganization = function (row, ev) {
            row = _.find($scope.organizations, function(item){
                return item.id === row;
            })
            var confirm = $mdDialog.confirm()
                .title('Would you like to delete organization "' + row.name + '"?')
                .ariaLabel('Delete organization')
                .targetEvent(ev)
                .ok('Delete')
                .clickOutsideToClose(true)
                .cancel('Cancel');
            $mdDialog.show(confirm).then(function () {
                var org = new DeleteOrganization({id: row.id});
                ThriftHelper.sendRequest(org, MsgType.DELETE_ORGANIZATION, config.calls.WS_DELETE_ORGANIZATION);
            });
        };

        $scope.editOrganization = function (row, ev) {
            if(row){
                row = _.find($scope.organizations, function(item){
                    return item.id === row;
                })
            }
            $mdDialog.show({
                controller: 'editOrganizationCtrl',
                templateUrl: 'app/module/admin/organizations/editOrganization.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                locals: {
                    currentOrganization: angular.copy(row),
                    cases: $scope.cases,
                    channels: $scope.twitterChannels
                }
            })
                .then(function (answer) {
                    adminService.getOrganizations();
                });
        };
    }]);
