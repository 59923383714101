
app.controller('AoUitDashboardCtrl', ['$scope', 'currentDashboard', 'Toast','$mdDialog',
    'config','ThriftHelper','mobile','Session','$state','defaultsettings','detailIdGenerator',
    function ($scope, currentDashboard, Toast,$mdDialog,
    config,ThriftHelper,mobile,Session,$state, defaultsettings,detailIdGenerator) {
        
        $scope.form = {};
        $scope.isAdd = currentDashboard === null;

        $scope.title = $scope.isAdd ? "Add Dashboard" : "Edit Dashboard";

        $scope.widgetCategories = defaultsettings.dashboardWidgetCategories;
        $scope.widgetTypes = defaultsettings.dashboardWidgetTypes;
        $scope.widgetTypesByType = _.reduce($scope.widgetTypes , function(obj,wt) { obj[wt.type] = wt; return obj;}, {});
        $scope.simpleWidgetTypes = _.filter($scope.widgetTypes, { simple: true });

        $scope.icons = defaultsettings.dashboardIconList;
        $scope.types = defaultsettings.dashboardTypes;
        $scope.isLayoutDisabled = true;
        $scope.isLockedDisabled = true;
        
        if($scope.isAdd){
            $scope.dashboard = {
                name: "",
                icon: undefined,
                type: undefined,
                locked: false,
                columns: null
            };
        }else{
            $scope.dashboard = angular.copy(currentDashboard);
        }

        $scope.onTypeChange = function(){
            if($scope.dashboard.type === DashboardType.CUSTOM){
                if($scope.dashboard.columns === null){
                    $scope.dashboard.columns = [{widgets: [{}]}];
                }
                $scope.isLayoutDisabled = false;
                $scope.isLockedDisabled = false;
            }else{
                $scope.dashboard.columns = null;
                $scope.isLayoutDisabled = true;
                $scope.isLockedDisabled = true;
                $scope.dashboard.locked = true;
            }
        };
        $scope.onTypeChange();
        
        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.save = function () {
            console.log($scope.dashboard);
            if($scope.form.dashboard.$valid){
                $mdDialog.hide($scope.dashboard);
            }
        };
        $scope.disableSave = function(){
            if($scope.form.dashboard.$valid === false){
                return true;
            }
            if($scope.dashboard.type === DashboardType.CUSTOM && $scope.dashboard.columns.length === 0){
                return true;
            }
            return false;
        };
        
        $scope.addRow = function (arr) {
            arr.push({});
            onLayoutChanged();
        };
        $scope.addCol = function (arr) {
            arr.push({ widgets: [{}]});
            onLayoutChanged();
        };
        $scope.removeWidget = function (parent_idx,idx) {
            if($scope.dashboard.columns[parent_idx].widgets.length>1){
                $scope.dashboard.columns[parent_idx].widgets.splice(idx,1);
            }else{
                $scope.dashboard.columns.splice(parent_idx,1);
            }
            onLayoutChanged();
        };
        $scope.addSubWidget = function (widget) {
            widget.widgets.push({});
            onLayoutChanged();
        };
        $scope.removeSubWidget = function (parent_widget,idx) {
            parent_widget.widgets.splice(idx,1);
            onLayoutChanged();
        };
        
        $scope.widgetChange = function(widget, isSub){
            console.log(widget);
            for(var key in widget){
                if(key !== "type" && key !== "height"){
                    widget[key] = null;
                }
            }
            var defOpt = $scope.widgetTypesByType[widget.type].defaultOptions;
            for(var key in defOpt){
                widget[key] = defOpt[key];
            }
            widget.name = $scope.widgetTypesByType[widget.type].name;

            onLayoutChanged();
        };
        
        $scope.arr = [];
        for(var i = 0;i<=100;i+=5){
            $scope.arr.push(i);
        }
        
        $scope.documentTypes = _.filter(defaultsettings.articleTypes, function (item) {
                        return item.id >= 0 && item.id <= 4;
                    });
        $scope.aggDocTypes = defaultsettings.aggDocTypes;
        $scope.sortBy = defaultsettings.sortBy;
        $scope.socialSortBy = defaultsettings.socialSortBy;
        
        $scope.spreadDensity = defaultsettings.spreadDensity;
        $scope.documentGeoMaxLoad = defaultsettings.documentGeoMaxLoad;

        $scope.lastYear = (new Date).getFullYear() - 5;

        function onLayoutChanged() {
            detailIdGenerator.generateId($scope.dashboard);
            $scope.$broadcast("onLayoutChanged", $scope.dashboard);
            $scope.$broadcast("onDetailsIdsChanged");
        }
        onLayoutChanged();

        $scope.canAddSubWidgets = function (widget) {
            if(widget.type !== WidgetType.MULTI_WIDGET)
                return false;
            return widget.widgets.length < 6;
        }
    }]);
