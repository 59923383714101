app.factory('exportableSocialDetailsHitModelDecorator', ['$q', 'config', 'ThriftHelper', '$rootScope', 'HitModel',
    function ($q, config, ThriftHelper, $rootScope, HitModel) {
        function exportableSocialDetailsHitModelDecorator(hitModel) {
            initExportPdf(hitModel);

            return hitModel;
        }

        function initExportPdf(hitModel) {
            var id = "document-details-" + UUID.generate();
            hitModel._originalExportPDF = hitModel.exportPDF;
            hitModel.exportPDF = export2Pdf;

            hitModel._requestId = config.calls.WS_WEBSHOT + "-" + id;
            hitModel._previewImageRetries = 5;
        }

        function loadDocumentPreviewImage(item) {
            var deferred = $q.defer();

            loadDocumentPreviewUrl(item).then(function (url) {
                getBase64Image(url)
                    .then(function (imageBase64) {
                        deferred.resolve(imageBase64);
                    }, function (error) {
                        deferred.resolve(undefined);
                    });
            }, function (error) {
                // in case of error we generate PDF anyway - no image
                deferred.resolve(undefined);
            });

            return deferred.promise;
        }

        function loadDocumentPreviewUrl(item) {
            var deferred = $q.defer();

            var requestId = item._requestId + UUID.generate();

            $rootScope.$on(requestId, function (event, args) {
                if (args && args.data) {
                    if (args.data.status === WebshotStatus.VALID) {
                        var url = config.previewUrl + args.data.webUrl;

                        deferred.resolve(url);
                    } else if (args.data.status === WebshotStatus.PENDING) {
                        // we do a reload after some time
                        item._previewImageRetries--;
                        if (item._previewImageRetries > 0) {
                            setTimeout(function () {
                                if (item.url != null) {
                                    ThriftHelper.sendRequest(new GetWebshotReq({
                                            id: item.id,
                                            url: item.url
                                        }),
                                        MsgType.GET_WEBSHOT_REQ, requestId);
                                }
                            }, 1000);
                        } else {
                            // Unable to generate preview.
                            deferred.resolve(undefined)
                        }
                    } else {
                        deferred.resolve(undefined)
                    }
                }
            });

            if (item.url != null) {
                ThriftHelper.sendRequest(new GetWebshotReq({
                        id: item.id,
                        url: item.url
                    }),
                    MsgType.GET_WEBSHOT_REQ, requestId);
            }

            return deferred.promise;
        }

        function loadTwitterUser(item) {
            var deferred = $q.defer();

            if (item.userId) {
                var requestId = item._requestId + UUID.generate();
                $rootScope.$on(requestId, function (event, args) {
                    if (args && args.data && args.data.twitterUsers && args.data.twitterUsers != null && args.data.twitterUsers.length > 0) {
                        deferred.resolve(args.data.twitterUsers[0]);
                    } else {
                        deferred.resolve(undefined);
                    }
                });

                ThriftHelper.sendRequest(new GetEsReq({
                        type: 'twitter',
                        indexName: 'twitter_users',
                        ids: [item.userId]
                    }),
                    MsgType.GET_ES_REQ, requestId);
            } else {
                // no user
                deferred.resolve(undefined);
            }

            return deferred.promise;
        }

        function getBase64Image(url, circle) {
            var deferred = $q.defer();
            var img = document.createElement('img');
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = function () {
                // image loaded
                var canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                if (circle === true) {
                    cutCircleShape(canvas, ctx);
                }

                var dataURL = canvas.toDataURL('image/png');
                deferred.resolve(dataURL);
            };

            img.onerror = function (error) {
                deferred.reject('Failed to load image: ' + error);
            };

            img.src = url;

            return deferred.promise;
        }

        function cutCircleShape(canvas, canvasCtx) {
            var maskCanvas = document.createElement('canvas');
            maskCanvas.width = canvas.width;
            maskCanvas.height = canvas.height;

            var ctx = maskCanvas.getContext('2d');
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, maskCanvas.width, maskCanvas.height);
            ctx.globalCompositeOperation = 'xor';

            var radius = maskCanvas.width / 2;
            ctx.arc(radius, radius, radius, 0, 2 * Math.PI);
            ctx.fill();

            canvasCtx.drawImage(maskCanvas, 0, 0);
        }

        function export2Pdf() {
            var self = this;
            var deferred = $q.defer();

            $q.all([loadTwitterUser(self), loadDocumentPreviewImage(self)])
                .then(function (data) {
                    var twitterUser = data[0];
                    var preview = data[1];

                    var defaultImage = 'img/baseline-account_circle.svg';
                    var url = twitterUser.profileUrl;
                    if (url === null || url === undefined) {
                        // in case if there is no image specified, let's use default one
                        url = defaultImage;
                    }

                    getBase64Image(url, true)
                        .then(function (imageBase64) {
                            deferred.resolve(generatePdfData(self, twitterUser, preview, imageBase64));
                        }, function (error) {
                            // in case of error let's retrieve default image
                            getBase64Image(defaultImage)
                                .then(function (imageBase64) {
                                    deferred.resolve(generatePdfData(self, twitterUser, preview, imageBase64));
                                }, function (error) {
                                    // in case of error -> no image
                                    deferred.resolve(generatePdfData(self, twitterUser, preview, undefined));
                                });
                        });
                }, function (reason) {
                    // in case of error we generate PDF anyway - no image, no references, ...
                    deferred.resolve(generatePdfData(self, undefined, undefined, undefined));
                });

            return deferred.promise;
        }

        function generatePdfData(item, twitterUser, preview, userImage) {
            var output = [];

            // Author
            prepareHeader(twitterUser, item, output, userImage);

            output.push({text: item.tweetText, margin: [0, 0, 0, 5], fontSize: 14});

            var tweetDetails = [];
            if (item.retweetCount) {
                tweetDetails.push({text: 'Retweet count: ' + item.retweetCount, margin: [0, 0, 0, 5]});
            }
            if (item.favoriteCount) {
                tweetDetails.push({text: 'Favorite count: ' + item.favoriteCount, margin: [0, 0, 0, 5]});
            }
            if (item.quoteCount) {
                tweetDetails.push({text: 'Quote count: ' + item.quoteCount, margin: [0, 0, 0, 5]});
            }
            if (item.replyCount) {
                tweetDetails.push({text: 'Reply count: ' + item.replyCount, margin: [0, 0, 0, 5]});
            }
            if (item.lang) {
                tweetDetails.push({text: 'Language: ' + item.lang, margin: [0, 0, 0, 5]});
            }
            if (item.location) {
                tweetDetails.push({text: 'Location: ' + item.location, margin: [0, 0, 0, 5]});
            }
            if (item.replyToScreenName) {
                tweetDetails.push({text: 'Reply to: @' + item.replyToScreenName, margin: [0, 0, 0, 5]});
            }

            var userDetails = [];

            var userDetailsTableBody = [];
            if (userImage) {
                userDetailsTableBody.push({image: userImage, width: 36});
            }

            userDetailsTableBody.push([
                {text: twitterUser.name, color: "#d43300", fontSize: 11},
                {text: '@' + twitterUser.screenName}
            ]);

            userDetails.push({
                table: {
                    body: [
                        userDetailsTableBody
                    ]
                },
                layout: 'noBorders'
            });

            if (twitterUser.description) {
                userDetails.push({text: 'Description: ' + twitterUser.description, margin: [0, 0, 0, 5]});
            }
            if (twitterUser.location) {
                userDetails.push({text: 'Location: ' + twitterUser.location, margin: [0, 0, 0, 5]});
            }
            if (twitterUser.url) {
                userDetails.push({text: 'Url: ' + twitterUser.url, margin: [0, 0, 0, 5]});
            }
            if (twitterUser.language) {
                userDetails.push({text: 'Language: ' + twitterUser.language, margin: [0, 0, 0, 5]});
            }
            if (twitterUser.favouritesCount) {
                userDetails.push({text: 'Favourites count: ' + twitterUser.favouritesCount, margin: [0, 0, 0, 5]});
            }
            if (twitterUser.followersCount) {
                userDetails.push({text: 'Followers count: ' + twitterUser.followersCount, margin: [0, 0, 0, 5]});
            }
            if (twitterUser.friendsCount) {
                userDetails.push({text: 'Friends count: ' + twitterUser.friendsCount, margin: [0, 0, 0, 5]});
            }
            if (twitterUser.listedCount) {
                userDetails.push({text: 'Listed count: ' + twitterUser.listedCount, margin: [0, 0, 0, 5]});
            }
            if (twitterUser.statusesCount) {
                userDetails.push({text: 'Statuses count: ' + twitterUser.statusesCount, margin: [0, 0, 0, 5]});
            }


            output.push({
                table: {
                    widths: ['*', '*'],
                    body: [
                        [
                            {
                                text: 'Tweet details',
                                fillColor: '#fff2ee',
                                margin: [5, 5],
                                fontSize: 11,
                                color: '#696969'
                            },
                            {
                                text: 'User details',
                                fillColor: '#fff2ee',
                                margin: [5, 5],
                                fontSize: 11,
                                color: '#696969'
                            }],
                        [
                            tweetDetails,
                            userDetails
                        ]
                    ]
                },
                layout: 'noBorders'
            });

            preparePreview(preview, output);

            return output;
        }

        function prepareHeader(user, item, output, userImage) {
            var tableBody = [];
            if (userImage) {
                tableBody.push({image: userImage, width: 36});
            }

            tableBody.push([
                {text: user.name, color: "#d43300", fontSize: 11},
                {text: '@' + user.screenName},
                {text: item.createdTimeFormatted, color: '#696969'}
            ]);

            output.push({
                table: {
                    body: [
                        tableBody
                    ]
                },
                layout: 'noBorders'
            });

            // empty line
            output.push({text: '', margin: [0, 0, 0, 10]});
        }

        function preparePreview(preview, output) {
            if (preview) {
                output.push({image: preview, width: 450});
            }
        }

        function loadDocumentTitleDetails(item) {
            var titleObjs = [];
            if (item.type === 'project') {
                if (item.source != null && item.source.length > 0) {
                    titleObjs.push(item.source);
                }
                if (item.publisher != null && item.publisher.length > 0) {
                    titleObjs.push(item.publisher);
                }
            } else {
                if (item.source != null && item.source.length > 0) {
                    titleObjs.push(item.source);
                }

                if (item.year != null && item.year.length > 0) {
                    titleObjs.push(item.year);
                }

                if (item.volume != null && item.volume.length > 0) {
                    titleObjs.push(item.volume);
                }

                if (item.issue != null && item.issue.length > 0) {
                    titleObjs.push(item.issue);
                }
            }

            if (titleObjs.length > 0) {
                return titleObjs.join(', ');
            }

            return undefined;
        }

        return exportableSocialDetailsHitModelDecorator;
    }
]);