

app.controller('editScopeCtrl', ['$scope', 'currentScope', 'ThriftHelper', 'config', '$mdDialog', 'Session', 'adminService',
    '$q', 'Toast', '$filter',
    function ($scope, currentScope, ThriftHelper, config, $mdDialog, Session, adminService,
              $q, Toast, $filter) {

        $scope.form = {};
        $scope.isAdd = currentScope === null;
        $scope.title = $scope.isAdd ? "Add scope" : "Edit scope";
        $scope.dateTypes = ["date", "year"];
        $scope.sourceTypes = ["all", "news", "patent", "articles", "wiki"];
        $scope.cashedSources = {};

        if($scope.isAdd){
            $scope.scope = {
                organizationId: Session.get().organizationId,
                types:[],
                sourceIds:[],
                fromRange:{
                    type:"date",
                    value:""
                },
                toRange:{
                    type:"date",
                    value:""
                }
            };
        }else{
            $scope.scope = currentScope;
            if($scope.scope.types===null){
                $scope.scope.types=[];
            }
            if($scope.scope.sourceIds===null){
                $scope.scope.sourceIds=[];
            }
            if($scope.scope.fromRange.type === 'date'){
                $scope.scope.fromRange.valueDate = new Date($scope.scope.fromRange.value);
            }else{
                $scope.scope.fromRange.valueYear = parseInt($scope.scope.fromRange.value);
            }
            if($scope.scope.toRange.type === 'date'){
                $scope.scope.toRange.valueDate = new Date($scope.scope.toRange.value);
            }else{
                $scope.scope.toRange.valueYear = parseInt($scope.scope.toRange.value);
            }
            if($scope.scope.types.length === 1 && $scope.scope.sourceIds.length > 0){
                adminService.getSourcesByIds($scope.scope.types[0], $scope.scope.sourceIds);
            }
        }

        var firstSelectCall = true;
        $scope.$watch('scope.types', function(newVal, oldVal){
            if(!firstSelectCall){
                if(newVal && newVal.length === 1)
                    $scope.getLoadResultsCallbackFirstPage();
                else{
                    $scope.scope.sourceIds = [];
                }
            }
            firstSelectCall = false;
        });

        var firstSelectCall2= true;
        $scope.$watch(function () {
            return $scope.scope.sourceIds.length;
        }, function(newVal, oldVal){
            if(!firstSelectCall2){
                $scope.getLoadResultsCallbackFetchPageAgain();
            }
            firstSelectCall2 = false;
        });

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.save = function () {
            if($scope.form.scope.$valid){
                if($scope.scope.fromRange.type === 'year'){
                    $scope.scope.fromRange.value = $scope.scope.fromRange.valueYear + "";
                }else{
                    $scope.scope.fromRange.value = $filter('date')($scope.scope.fromRange.valueDate, config.format.date);
                }
                if($scope.scope.toRange.type === 'year'){
                    $scope.scope.toRange.value = $scope.scope.toRange.valueYear + "";
                }else{
                    $scope.scope.toRange.value = $filter('date')($scope.scope.toRange.valueDate, config.format.date);
                }
                ThriftHelper.sendRequest(new UpdateScope({scope: new Scope($scope.scope)}),
                    MsgType.UPDATE_SCOPE, config.calls.WS_EDIT_SCOPE);
            }
        };

        $scope.$on(config.calls.WS_EDIT_SCOPE, function (event, args) {
            if(args.data===null){
                if($scope.isAdd){
                    Toast.success("Scope was successfully added.");
                }else{
                    Toast.success("Scope was successfully updated.");
                }
                $scope.$apply();
                $mdDialog.hide();
            }
        });

        $scope.capitalizeFirstLetter = function(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        $scope.getSourceName = function (id) {
            if($scope.cashedSources[id]){
                return $scope.cashedSources[id];
            }
            return id;
        };

        //part for md data table for sources
        $scope.sources = [];
        $scope.sourcesStats = {};
        $scope.total = 0;
        $scope.getter = adminService.getter;
        $scope.tableColumns = ['name', 'desc', 'keywords', 'lang', 'website', 'trusted'];
        $scope.getLoadResultsCallbackFirstPage = undefined; // handled by mdtTriggerRequestFirstPage directive
        $scope.getLoadResultsCallbackFetchPageAgain = undefined;
        $scope.filterText = "";
        //private variables
        var deferred = $q.defer();

        $scope.paginatorCallback = function(page, pageSize, options){
            if(!page)
                page = 1;
            deferred = $q.defer();
            adminService.getSources(page, pageSize, options, $scope.tableColumns, $scope.filterText, $scope.scope.types[0], $scope.scope.sourceIds);
            return deferred.promise;
        };

        var firstFilterCall = true;
        $scope.$watch('filterText', function(newVal, oldVal){
            if(!firstFilterCall){
                $scope.getLoadResultsCallbackFirstPage();
            }
            firstFilterCall = false;
        });

        $scope.$on(config.calls.WS_ADMIN_SOURCES, function (event, args) {
            if (angular.isDefined(args.data.sources)) {
                $scope.sources = args.data.sources;
                $scope.total = args.data.total;
                arrangeData();
            }
            $scope.$apply();
        });

        function arrangeData(){
            deferred.resolve({
                results:  $scope.sources,
                totalResultCount: $scope.total
            });
        }

        $scope.selectedRowCallback = function (rows) {
            rows.forEach(function (id) {
                var row = $scope.sources.filter(function (item) {
                    return item.id ===id;
                });
                $scope.cashedSources[id] = row[0].name;
            });
            $scope.scope.sourceIds = $scope.scope.sourceIds.concat(rows);
            if(rows.length > 1){
                setTimeout(function () {
                    $(".theadTrRow .checkboxCell .md-checked").click();//md-data-table workaround
                },100);
            }
        }

        $scope.$on(config.calls.WS_ADMIN_SOURCES_BY_ID, function (event, args) {
            if (angular.isDefined(args.data.sources)) {
                args.data.sources.forEach(function (source) {
                    $scope.cashedSources[source.id] = source.name;
                });
            }
            $scope.$apply();
        });

    }]);
