/* global d3, app */

/**
 * Created by matejk on 8.10.2014.
 */

app.directive("complexity", ['$window','$timeout', 'ComplexityModel', 'config', 'ThriftHelper', 'Session','$q',
    'ExportService','DashboardService',
    function ($window, $timeout, ComplexityModel, config, ThriftHelper, Session,$q,
              ExportService,DashboardService) {
        return {
            restrict: "E",
            replace: true,
            templateUrl: 'app/directives/complexity/complexity.html',
            scope: {
                options: "="
            },
            link: function (scope, element, attrs) {
                // ------------------
                // --- SCOPE VARS ---
                // ------------------
                scope.service = DashboardService;
                scope.widgetValues = DashboardService.sharedValues.cloud;
                scope.id = "complexity-" + UUID.generate();
                scope.case = Session.get().currentCase.name;
                var headerHeight = 30;
                scope.width = element.width();
                scope.height = element.height()-headerHeight;
                scope.data = [];
                scope.settings = config.complexity;
                scope.keyword = undefined;

                //private vars
                var splines = [];
                var cluster;
                var bundle;
                var line;
                var svg;
                var div = d3.select(element[0]);
                var w = scope.width, // w in h bosta minimum širine in višine elementa, zaradi vrtenja
                    h = scope.height,
                    rx = w / 2,
                    ry = h / 2,
                    m0,
                    rotate = 0;
                //private functions

                function drawComplexity(classes) {
                    var nodes = cluster.nodes(packages.root(classes)),
                        links = packages.imports(nodes),
                        splines = bundle(links);

                    var path = svg.selectAll("path.link")
                        .data(links)
                        .enter().append("svg:path")
                        .attr("class", function(d) {
                            var c = "link source-" + d.source.key + " target-" + d.target.key;
                            if(d.bidirectional){
                                c+= " bidirectional";
                            }else{
                                c+= " unidirectional";
                            }
                            return c;
                        })
                        .attr("d", function(d, i) { return line(splines[i]); })
                        .attr("stroke-width", function(d) { return 3*d.size+1; });

                    svg.selectAll("g.node")
                        .data(nodes.filter(function(n) { return !n.children; }))
                        .enter().append("svg:g")
                        .attr("class", "node")
                        .attr("id", function(d) { return "node-" + d.key; })
                        .attr("transform", function(d) { return "rotate(" + (d.x - 90) + ")translate(" + d.y + ")"; })
                        .append("svg:text")
                        .attr("dx", function(d) { return d.x < 180 ? 8 : -8; })
                        .attr("dy", ".31em")
                        .attr("text-anchor", function(d) { return d.x < 180 ? "start" : "end"; })
                        .attr("transform", function(d) { return d.x < 180 ? null : "rotate(180)"; })
                        .text(function(d) { return d.key; })
                        .on("mouseover", mouseover)
                        .on("mouseout", mouseout);
                }

                function mouse(e) {
                    return [e.pageX - rx, e.pageY - ry];
                }

                function mousedown() {
                    m0 = mouse(d3.event);
                    d3.event.preventDefault();
                }

                function mousemove() {
                    if (m0) {
                        var m1 = mouse(d3.event),
                            dm = Math.atan2(cross(m0, m1), dot(m0, m1)) * 180 / Math.PI;
                        div.style("-webkit-transform", "rotateZ(" + dm + "deg)");
                    }
                }

                function mouseup() {
                    if (m0) {
                        var m1 = mouse(d3.event),
                            dm = Math.atan2(cross(m0, m1), dot(m0, m1)) * 180 / Math.PI;

                        rotate += dm;
                        if (rotate > 360) rotate -= 360;
                        else if (rotate < 0) rotate += 360;
                        m0 = null;

                        div.style("-webkit-transform", null);

                        svg
                            .attr("transform", "translate(" + rx + "," + ry + ")rotate(" + rotate + ")")
                            .selectAll("g.node text")
                            .attr("dx", function(d) { return (d.x + rotate) % 360 < 180 ? 8 : -8; })
                            .attr("text-anchor", function(d) { return (d.x + rotate) % 360 < 180 ? "start" : "end"; })
                            .attr("transform", function(d) { return (d.x + rotate) % 360 < 180 ? null : "rotate(180)"; });
                    }
                }

                function mouseover(d) {

                    var bidirectional = svg.selectAll("path.link.source-" + d.key + ".bidirectional");
                    var source = svg.selectAll("path.link.source-" + d.key + ".unidirectional");
                    var target = svg.selectAll("path.link.target-" + d.key + ".unidirectional");

                    bidirectional.classed("select-bidirectional", true)
                        .each(updateNodes("source", true));
                    source.classed("select-source", true)
                        .each(updateNodes("select-source", true));
                    target.classed("select-target", true)
                        .each(updateNodes("select-target", true));
                }

                function mouseout(d) {

                    var bidirectional = svg.selectAll("path.link.source-" + d.key + ".bidirectional");
                    var source = svg.selectAll("path.link.source-" + d.key + ".unidirectional");
                    var target = svg.selectAll("path.link.target-" + d.key + ".unidirectional");

                    bidirectional.classed("select-bidirectional", false)
                        .each(updateNodes("select-bidirectional", false));
                    source.classed("select-source", false)
                        .each(updateNodes("select-source", false));
                    target.classed("select-target", false)
                        .each(updateNodes("select-target", false));
                }

                function updateNodes(name, value) {
                    return function(d) {
                        if (value) this.parentNode.appendChild(this);
                        //svg.select("#node-" + d[name].key).classed(name, value);
                    };
                }

                function cross(a, b) {
                    return a[0] * b[1] - a[1] * b[0];
                }

                function dot(a, b) {
                    return a[0] * b[0] + a[1] * b[1];
                }

                //public functions
                scope.drawGraph = function (resize) {
                    if (_.isUndefined(scope.data)) {
                        return scope.getKeywords();
                    } else if (scope.data.all.length === 0) {
                        scope.loading = false;
                        return;
                    }
                    d3.select("#" + scope.id + " svg.complexity").remove();
                    scope.loading = true;

                    var data;
                    if(resize){
                        data = scope.dataPom;
                    }else{
                        data = scope.dataPom = scope.data.currentDisplay;
                    }

                    w = scope.width;
                    h = scope.height;
                    rx = w / 2;
                    ry = h / 2;
                    rotate = 0;

                    cluster = d3.layout.cluster()
                        .size([360, ry - 120])
                        .sort(function(a, b) { return d3.ascending(a.key, b.key); });

                    bundle = d3.layout.bundle();

                    line = d3.svg.line.radial()
                        .interpolate("bundle")
                        .tension(.85)
                        .radius(function(d) { return d.y; })
                        .angle(function(d) { return d.x / 180 * Math.PI; });

                    div = d3.select("#" + scope.id + " .svg-container");

                    svg = div.append("svg:svg")
                        .attr("class", "complexity main-svg")
                        .attr("width", w)
                        .attr("height", h)//mogoče napaka
                        .append("svg:g")
                        .attr("transform", "translate(" + rx + "," + ry + ")");

                    svg.append("svg:path")
                        .attr("class", "arc")
                        .attr("d", d3.svg.arc().outerRadius(ry - 120).innerRadius(0).startAngle(0).endAngle(2 * Math.PI))
                        .on("mousedown", mousedown);

                    drawComplexity(data);

                    d3.select(window) //mogoče daj na svg
                        .on("mousemove", mousemove)
                        .on("mouseup", mouseup);

                    scope.loading = false;
                    //scope.$apply();
                };

                scope.update = function () {
                    scope.width = element.width();
                    scope.height = element.height()-headerHeight;
                    scope.drawGraph(true);
                };

                var resizeOff = scope.$on("resize", function(){
                    scope.update();
                    scope.$digest();
                });

                scope.$on("$destroy", function () {
                    resizeOff();
                    element.remove();
                });

                var getCall = function () {
                    return config.calls.WS_WORD_CLOUD + "-" + scope.id;
                };

                scope.getKeywords = function () {
                    var dto = {};
                    dto.organization = Session.get().currentCase.name;
                    dto.size = scope.settings.size;

                    // get only words
                    var words = _.filter(scope.service.keywords.data, function (item) {
                        return item.source.short === 'w';
                    });

                    if (words.length === 0) {
                        dto.value = scope.widgetValues.normal;
                        dto.size = scope.settings.filterSize;
                        scope.keyword = undefined;
                    } else {
                        dto.keyword = words[words.length - 1].word;
                        scope.keyword = dto.keyword;
                        dto.size = scope.settings.searchSize1;
                    }

                    d3.select("#" + scope.id + " svg.complexity").remove();
                    scope.loading = true;

                    //console.log("search1",dto);
                    ThriftHelper.sendRequest(new SearchOrganizationReq(dto),
                        MsgType.SEARCH_ORGANIZATION_REQ, getCall());
                };

                scope.$on(getCall(), function (event, args) {
                    //console.log("search1",args.data.keywords);
                    scope.data = new ComplexityModel(args.data.keywords);
                    scope.width = element.width();
                    scope.height = element.height()-headerHeight;
                    scope.getKeywordsSecondLevel();
                });

                scope.requestsMap = {};
                scope.getKeywordsSecondLevel = function () {
                    var vals = getFreqCorrVals();
                    var newKeywords = scope.data.updateCurrent1(vals.freqVal,vals.corrVal);
                    scope.requestsMap = {};
                    var promises = [];
                    for(var i = 0; i < newKeywords.length;i++){ //go over all treemap elements
                        var deffered  = $q.defer();
                        var dto = {};
                        dto.organization = Session.get().currentCase.name;
                        dto.size = scope.settings.searchSize2;
                        dto.keyword = newKeywords[i].keyword;
                        var reqId = ThriftHelper.sendRequest(new SearchOrganizationReq(dto), MsgType.SEARCH_ORGANIZATION_REQ, getCall()+ "-2level");

                        scope.requestsMap[reqId] = {
                            keyword: newKeywords[i].keyword,
                            deffered: deffered
                        };
                        promises.push(deffered.promise);
                    }

                    $q.all(promises).then(function(results){
                        //console.log("search2",results);
                        scope.data.updateCurrent2(results,vals.freqVal,vals.corrVal);
                        scope.drawGraph(false);
                    },function (reason) {
                        console.log("Error in getKeywordsSecondLevel");
                    });
                };

                scope.$on(getCall()+ "-2level", function (event, args) {
                    var request = scope.requestsMap[args.reqId];
                    if(request){
                        if(args.err === false){
                            request.result = args.data.keywords;
                            request.deffered.resolve({
                                keyword: request.keyword,
                                result: request.result
                            });
                        }else{
                            request.deffered.reject();
                        }
                    }

                    //scope.drawGraph(false);
                });

                var getFreqCorrVals = function () {
                    if (scope.service.keywords.isEmpty()) {
                        freqVal = scope.widgetValues.normal / 100;
                        corrVal = -1;
                        return {
                            freqVal: scope.widgetValues.normal / 100,
                            corrVal: -1
                        };
                    }
                    return {
                        freqVal: scope.widgetValues.rare / 100,
                        corrVal: scope.widgetValues.corr / 100
                    };
                };

                scope.$watch("service.keywords", function (nval, oval) {
                    if (nval && nval.data && oval && oval.data) {
                        var ow = _.filter(oval.data, function (item) {
                            return item.source.short === 'w';
                        });
                        var nw = _.filter(nval.data, function (item) {
                            return item.source.short === 'w';
                        });
                        if((ow.length === 0 && nw.length > 0) || (nw.length === 0 && ow.length > 0)){
                            scope.getKeywords();
                        }else if(ow.length > 0 && nw.length > 0 && ow[ow.length-1].word !== nw[nw.length-1].word){
                            scope.getKeywords();
                        }
                    }
                }, true);

                var sliderTimer;
                scope.$watch("widgetValues", function (nval, oval) {
                    $timeout.cancel(sliderTimer);
                    if (nval !== oval && !_.isUndefined(nval.normal)) {
                        var fun;
                        if (nval.normal !== oval.normal) {
                            fun = function () {
                                scope.getKeywords();
                            };
                        } else {
                            fun = function () {
                                scope.getKeywordsSecondLevel();
                            };
                        }
                        sliderTimer = $timeout(fun, 400);
                    }
                }, true);

                $timeout(function () {
                    scope.getKeywords();
                }, 200);

                scope.export = function ($event) {
                    var settings = {
                        description: "case: "+scope.case+", ",
                        svg: $("#" + scope.id + " svg.complexity")
                    };
                    if(scope.keyword){
                        settings.description+="keyword: "+scope.keyword+", frequent/trendy: "+scope.widgetValues.rare
                            +", correlation: "+scope.widgetValues.corr;
                    }else{
                        settings.description+="frequent/trendy: "+scope.widgetValues.rare;
                    }
                    ExportService.exportDialog("complexity",settings,$event);
                };

            }
        };
    }]);