
app.controller('passwordChangeCtrl', ['$scope', 'currentUser', 'Toast', 'Session',
    '$mdDialog', 'ThriftHelper', 'config', '$rootScope',
    function ($scope, currentUser, Toast, Session,
            $mdDialog, ThriftHelper, config, $rootScope) {

        $scope.form = {};

        $scope.isAdd = currentUser === null;
        $scope.title = "Change password";
        $scope.confirm = { val: undefined};

        $scope.pass = {
            email: currentUser.email,
            oldPasswd: "",
            newPasswd: ""
        };
        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.save = function () {
            if ($scope.form.pass.$valid) {
                ThriftHelper.sendRequest(new UpdatePasswd($scope.pass),
                        MsgType.UPDATE_PASSWD, config.calls.WS_UPDATE_PASSWD);
            }
        };

        $scope.$on(config.calls.WS_UPDATE_PASSWD, function (event, args) {
            if (args.data === null) {
                Toast.success("Password was successfully updated.");
                $mdDialog.hide();
            } else if (args.data instanceof ErrorMsg) {
                Toast.error(args.data.errorDesc);
            }
            $scope.$apply();
        });

    }]);
