
app.controller('editCaseCtrl', ['$scope', 'currentCase', 'Toast','$mdDialog', 'config', 'ThriftHelper',
    function ($scope, currentCase, Toast,$mdDialog, config, ThriftHelper) {
        
        $scope.form = {};
        $scope.isAdd = currentCase === null;
        $scope.title = $scope.isAdd ? "Add case" : "Edit case";
        
        if($scope.isAdd){
            $scope.case = {
                keywords:[]
            };
        }else{
            $scope.case = currentCase;
            if($scope.case.keywords===null){
                $scope.case.keywords=[];
            }
        }
        
        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        
        $scope.save = function () {
            if($scope.form.case.$valid){
                ThriftHelper.sendRequest(new UpdateCase({c: new Case($scope.case)}),
                MsgType.UPDATE_CASE, config.calls.WS_EDIT_CASE);
            }
        };
        
        $scope.$on(config.calls.WS_EDIT_CASE, function (event, args) {
            if(args.data===null){
                if($scope.isAdd){
                    Toast.success("Case was successfully added.");
                }else{
                    Toast.success("Case was successfully updated.");
                }
                $scope.$apply();
                $mdDialog.hide();
            }
        });
        
        
    }]);
