
/* global angular */

/**
 * Created by Matej on 31.1.2014.
 **/

/**
 * angular module initialization
 * @type {module}
 **/

(function(module) {
    try {
        angular.module('partials');
    } catch (e) {
        angular.module('partials', []);
    }
})();

var app = angular.module('percipioApp', ['ui.router',
    'ngSanitize', 'ngAnimate', 'ngMessages', 'ngAria', 'ngCookies',
    'ngMaterial', 'cfp.hotkeys', 'ngIdle',  'mdDataTable','ngFileSaver', 'partials']);


app.config(['$cookiesProvider', '$urlRouterProvider', '$mdThemingProvider', '$mdIconProvider',
    'IdleProvider', 'TitleProvider', '$mdDateLocaleProvider','$mdAriaProvider','$logProvider',
    function ($cookiesProvider, $urlRouterProvider, $mdThemingProvider, $mdIconProvider,
            IdleProvider, TitleProvider, $mdDateLocaleProvider,$mdAriaProvider, $logProvider) {
        $logProvider.debugEnabled(true); //for production should be false, for develop should be true

        $urlRouterProvider.otherwise('/login');

        $mdThemingProvider.theme('default')
                .primaryPalette('deep-orange')
                .accentPalette('grey');

        $mdThemingProvider.theme('altTheme')
                .primaryPalette('grey')
                .accentPalette('deep-orange');

        $mdThemingProvider.enableBrowserColor();

        $mdDateLocaleProvider.formatDate = function (date) {
            return date ? moment(date).format('DD-MM-YYYY') : '';
        };

        $mdDateLocaleProvider.parseDate = function (dateString) {
            var m = moment(dateString, 'DD-MM-YYYY', true);
            return m.isValid() ? m.toDate() : new Date(NaN);
        };

        $mdIconProvider.fontSet('fa', 'fa');
        $mdIconProvider.fontSet('mdi', 'mdi-set');



//        var expireDate = new Date();
//        expireDate.setDate(expireDate.getDate() + 1);
//        $cookiesProvider.defaults.expires = expireDate;

        IdleProvider.idle(10 * 60); // in seconds
        IdleProvider.timeout(30); // in seconds
        IdleProvider.keepalive(false);
        TitleProvider.enabled(false);

        $mdAriaProvider.disableWarnings();
    }]);


